// Angular imports
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

// Dx imports
import { DxBoxModule } from 'devextreme-angular/ui/box';
import { DxButtonModule } from 'devextreme-angular/ui/button';
import { DxDataGridModule } from 'devextreme-angular/ui/data-grid';
import { DxDateBoxModule } from 'devextreme-angular/ui/date-box';
import { DxDropDownButtonModule } from 'devextreme-angular/ui/drop-down-button';
import { DxFormModule } from 'devextreme-angular/ui/form';
import { DxListModule } from 'devextreme-angular/ui/list';
import { DxNumberBoxModule } from 'devextreme-angular/ui/number-box';
import { DxScrollViewModule } from 'devextreme-angular/ui/scroll-view';
import { DxSelectBoxModule } from 'devextreme-angular/ui/select-box';
import { DxTabsModule } from 'devextreme-angular/ui/tabs';
import { DxTagBoxModule } from 'devextreme-angular/ui/tag-box';
import { DxTextAreaModule } from 'devextreme-angular/ui/text-area';
import { DxTextBoxModule } from 'devextreme-angular/ui/text-box';
import { DxToolbarModule } from 'devextreme-angular/ui/toolbar';
import { DxValidatorModule } from 'devextreme-angular/ui/validator';

// 3rd party imports
import { AngularSplitModule } from 'angular-split';

// Module imports
import { WidgetsModule } from '../widgets-module/widgets.module';

// Component imports
import { CitySelectorComponent } from './components/city-selector/city-selector.component';
import { CityTextBoxComponent } from './components/city-text-box/city-text-box.component';
import { CommunitySelectorComponent } from './components/community-selector/community-selector.component';
import { CountryStateSelectorComponent } from './components/country-state-selector/country-state-selector.component';
import { EventMailerPreviewComponent } from './components/event-mailer-preview/event-mailer-preview.component';
import { EventNotesManagerComponent } from './components/event-notes-manager/event-notes-manager.component';
import { EventNoticesStatusComponent } from './components/event-notices-status/event-notices-status.component';
import { EventReportsPackageComponent } from './components/event-reports-package/event-reports-package.component';
import { EventScheduledCommunicationEditorComponent } from './components/event-scheduled-communication-editor/event-scheduled-communication-editor.component';
import { EventScheduledCommunicationsManagerComponent } from './components/event-scheduled-communications-manager/event-scheduled-communications-manager.component';
import { HeaderComponent } from './components/header/header.component';
import { HomeComponent } from './components/home/home.component';
import { InvalidUrlComponent } from './components/invalid-url/invalid-url.component';
import { LogsViewerComponent } from './components/logs-viewer/logs-viewer.component';
import { OrganizationMenuComponent } from './components/organization-menu/organization-menu.component';
import { OrganizationSelectorComponent } from './components/organization-selector/organization-selector.component';
import { PaperCommunicationManagerComponent } from './components/paper-communication-manager/paper-communication-manager.component';
import { PreferencesComponent } from './components/preferences/preferences.component';
import { PropertySelectorComponent } from './components/property-selector/property-selector.component';
import { ReasonEditorComponent } from './components/reason-editor/reason-editor.component';
import { SideNavigationComponent } from './components/side-navigation/side-navigation.component';

@NgModule({
  declarations: [
    CitySelectorComponent,
    CityTextBoxComponent,
    CommunitySelectorComponent,
    CountryStateSelectorComponent,
    EventMailerPreviewComponent,
    EventNotesManagerComponent,
    EventNoticesStatusComponent,
    EventReportsPackageComponent,
    EventScheduledCommunicationEditorComponent,
    EventScheduledCommunicationsManagerComponent,
    HeaderComponent,
    HomeComponent,
    InvalidUrlComponent,
    LogsViewerComponent,
    OrganizationMenuComponent,
    OrganizationSelectorComponent,
    PaperCommunicationManagerComponent,
    PreferencesComponent,
    PropertySelectorComponent,
    ReasonEditorComponent,
    SideNavigationComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    DxBoxModule,
    DxButtonModule,
    DxDataGridModule,
    DxDateBoxModule,
    DxDropDownButtonModule,
    DxFormModule,
    DxListModule,
    DxNumberBoxModule,
    DxScrollViewModule,
    DxSelectBoxModule,
    DxTabsModule,
    DxTagBoxModule,
    DxTextAreaModule,
    DxTextBoxModule,
    DxToolbarModule,
    DxValidatorModule,
    AngularSplitModule,
    WidgetsModule
  ],
  exports: [
    CitySelectorComponent,
    CityTextBoxComponent,
    CommunitySelectorComponent,
    EventMailerPreviewComponent,
    EventNotesManagerComponent,
    EventNoticesStatusComponent,
    EventReportsPackageComponent,
    EventScheduledCommunicationsManagerComponent,
    HeaderComponent,
    HomeComponent,
    InvalidUrlComponent,
    LogsViewerComponent,
    OrganizationSelectorComponent,
    PaperCommunicationManagerComponent,
    PreferencesComponent,
    PropertySelectorComponent,
    ReasonEditorComponent,
    SideNavigationComponent
  ]
})
export class SharedModule {}
