// Angular imports
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-icon-cell-template',
  templateUrl: './icon-cell-template.component.html',
  styleUrls: ['./icon-cell-template.component.scss']
})
export class IconCellTemplateComponent {
  @Input() type: 'default' | 'success' | 'danger' = 'default';
  @Input() icon: string;
  @Input() tooltipText: string;
  @Output() onClick = new EventEmitter<void>();
}
