// Angular imports
import { Component, Injector, Input, OnDestroy, OnInit } from '@angular/core';

// Component imports
import { BaseComponent } from '../../../_base/base.component';

// Service imports
import { OrganizationService } from '../../../_services/organization.service';

// Constant imports
import { IconConstants } from '../../../_constants/icon.constants';
import { StorageKeyConstants } from '../../../_constants/storage-key.constants';

// Util imports
import { Utils } from '../../../_utils/utils';

// Model imports
import { BaseQueryParameterDto } from '../../../_models/_base/base-query-parameter.dto';
import { OrganizationResultDto } from '../../../_models/organization/organization-result.dto';

@Component({
  selector: 'app-organization-menu',
  templateUrl: './organization-menu.component.html',
  styleUrls: ['./organization-menu.component.scss']
})
export class OrganizationMenuComponent
  extends BaseComponent
  implements OnInit, OnDestroy
{
  @Input() isOpened = true;
  showSelector = false;

  iconMyHome = IconConstants.MY_HOME;
  iconArrowDown = IconConstants.ARROW_DOWN;

  organizationId: number;
  organizations: Array<OrganizationResultDto>;

  private readonly organizationService: OrganizationService;

  constructor(protected override readonly injector: Injector) {
    super(injector);
    this.organizationService = injector.get(OrganizationService);
  }

  ngOnInit(): void {
    this.onInit('OrganizationMenuComponent');
    this.populateOrganizations();
  }

  ngOnDestroy(): void {
    this.onDestroy('OrganizationMenuComponent');
  }

  onSelectBoxOpened(): void {
    const items = document.getElementsByClassName('dx-selectbox-popup-wrapper');
    for (let i = 0; i < items.length; i++) {
      items[i]['style']['z-index'] = 3501;
    }
  }

  onValueChanged(e: any): void {
    if (Utils.notEquals(e.value, this.organizationId)) {
      if (Utils.notNullAndDefined(e.value)) {
        this.organizationId = e.value;
      } else {
        this.organizationId = this.organizationInfo.organizationId;
        e.component.option('value', this.organizationId);
      }
    }
  }

  onSelectionChanged(e: any): void {
    const organizationInfo = e.selectedItem as OrganizationResultDto;
    if (Utils.notNullAndDefined(organizationInfo)) {
      this.organizationInfo = organizationInfo;
      this.contextService.setOrganization(this.organizationInfo);
    }
  }

  onSelectOrganization(e: any): void {
    this.organizationInfo =
      (e.itemData as OrganizationResultDto) ||
      this.organizations.find((r) =>
        Utils.equals(r.organizationId, this.userInfo.organizationId)
      );
    if (Utils.notNullAndDefined(this.organizationInfo)) {
      this.organizationId = this.organizationInfo.organizationId;
    }
    this.showSelector = false;
  }

  private populateOrganizations(): void {
    this.organizationService
      .getOrganizations(new BaseQueryParameterDto())
      .subscribe((results) => {
        this.organizations = results;
        const userOrganizationId = localStorage.getItem(
          StorageKeyConstants.USER_ORGANIZATION
        );
        if (Utils.notNullOrEmpty(userOrganizationId)) {
          this.organizationInfo = results.find((r) =>
            Utils.equals(r.organizationId, Number(userOrganizationId))
          );
        }
        if (Utils.isNullOrUndefined(this.organizationInfo)) {
          if (results.length > 1) {
            this.showSelector = true;
          } else {
            this.organizationInfo = results.at(0);
          }
        }
        if (Utils.notNullAndDefined(this.organizationInfo)) {
          this.organizationId = this.organizationInfo.organizationId;
        }
      });
  }
}
