// Angular imports
import { Pipe, PipeTransform } from '@angular/core';

// Util imports
import { Utils } from '../_utils/utils';

// Model imports
import { NavigationEntry } from '../_types/navigation-entry';

@Pipe({ name: 'isSelected' })
export class IsSelectedPipe implements PipeTransform {
  transform(entry: NavigationEntry, selected: NavigationEntry) {
    return (
      Utils.notNullAndDefined(selected) &&
      Utils.isNullOrEmpty(selected.items) &&
      Utils.equalsIgnoreCase(entry.id, selected.id)
    );
  }
}
