// Angular imports
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular-ivy';

// Dx imports
import config from 'devextreme/core/config';

// Env imports
import { environment } from './environments/environment';

// Module imports
import { AppModule } from './app/app.module';

// Constant imports
import { AppDefaultConstants } from './app/_constants/app-default.constants';

// Dx license init
config({
  licenseKey:
    'ewogICJmb3JtYXQiOiAxLAogICJjdXN0b21lcklkIjogImE2MmJmZDA2LTVlM2QtNDk4OS1hNTE0LTY5MzBiY2E2YTM5NiIsCiAgIm1heFZlcnNpb25BbGxvd2VkIjogMjMyCn0=.mvX9d+7a52h2SDpQs45ChDIweba0Ij5qi+z2sGt5E7LOd5dX1oyEfTQDD9Y7wS88xhhvKQDwMuFShJX47t/rRX8bB4kpMPqqOew+3M6IiFFki+rxKUB54Q2TnI/R3gqLgMVSsA'
});

// Initialize Sentry
Sentry.init({
  dsn: environment.sentryDsnUrl,
  sendDefaultPii: true,
  autoSessionTracking: true,
  environment: environment.name,
  release: AppDefaultConstants.VERSION,
  integrations: [
    new Sentry.BrowserTracing({
      tracePropagationTargets: [
        /^https:\/\/qaapi\.ciravote\.com/,
        /^https:\/\/api\.ciravote\.com/
      ],
      routingInstrumentation: Sentry.routingInstrumentation
    }),
    new Sentry.Replay()
  ],
  // Performance Monitoring
  tracesSampleRate: environment.tracesSampleRate,
  // Session Replay
  replaysSessionSampleRate: environment.replaysSessionSampleRate,
  replaysOnErrorSampleRate: environment.replaysOnErrorSampleRate
});

// Bootstrap Application
platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
