export class TimerConstants {
  static inMillis(num: number): number {
    return num;
  }

  static inSeconds(num: number): number {
    return num * 1000;
  }

  static inMinutes(num: number): number {
    return TimerConstants.inSeconds(num) * 60;
  }

  static inHours(num: number): number {
    return TimerConstants.inMinutes(num) * 60;
  }

  static inDays(num: number): number {
    return TimerConstants.inHours(num) * 24;
  }
}
