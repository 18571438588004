<dx-drop-down-box
  #dropDownBox
  fieldTemplate="field"
  [readOnly]="readOnly"
  [deferRendering]="false"
  [openOnFieldClick]="false"
  [showClearButton]="showClearButton"
  [placeholder]="placeholder"
  [valueExpr]="valueExpr"
  [displayExpr]="displayExpr"
  [dataSource]="dataSource"
  [dropDownOptions]="dropDownOptions"
  [(value)]="value"
  (onOptionChanged)="onBoxOptionChanged($event)"
>
  <div *dxTemplate="let data of 'field'">
    <dx-text-box
      #textBox
      [value]="displayText"
      [readOnly]="readOnly"
      [placeholder]="placeholder"
      (onKeyDown)="onFieldKeyDown($event)"
    ></dx-text-box>
  </div>
  <div *dxTemplate="let data of 'content'">
    <dx-data-grid
      #dataGrid
      appDataGridFeatures
      width="100%"
      height="100%"
      [gridFeatures]="{
        showBorders: false,
        columnAutoWidth: false
      }"
      [columns]="columns"
      [keyExpr]="keyExpr"
      [dataSource]="dataSource"
      [focusedRowEnabled]="true"
      [(focusedRowIndex)]="focusIndex"
      [(selectedRowKeys)]="value"
      (onKeyDown)="onGridKeyDown($event)"
      (onRowClick)="onGridRowClick($event)"
      (onRowPrepared)="onGridRowPrepared($event)"
      (onSelectionChanged)="onGridSelectionChanged($event)"
    >
      <dxo-search-panel
        [visible]="false"
        [text]="!value?.length ? displayText : ''"
      ></dxo-search-panel>
      <dxo-selection mode="single"></dxo-selection>
      <div *dxTemplate="let cell of 'checkCellTemplate'">
        <ng-container
          [ngTemplateOutlet]="checkCellTemplate"
          [ngTemplateOutletContext]="{ cell: cell }"
        ></ng-container>
      </div>
      <ng-content #checkCellTemplate></ng-content>
    </dx-data-grid>
  </div>
  <dx-validator *ngIf="isRequired" [validationGroup]="validationGroup">
    <dxi-validation-rule
      type="custom"
      message="{{ name }} is required"
      [validationCallback]="validationCallback"
    ></dxi-validation-rule>
  </dx-validator>
</dx-drop-down-box>
