<app-page-popup
  title="Enter Filename"
  minWidth="50%"
  [visible]="true"
  [buttons]="['Ok']"
  (onHidden)="onClose.emit()"
  (onButtonClick)="onButtonClick($event)"
>
  <dx-text-box [(value)]="filename">
    <dx-validator #validator>
      <dxi-validation-rule
        type="required"
        message="Filename is required"
      ></dxi-validation-rule>
    </dx-validator>
  </dx-text-box>
</app-page-popup>
