// Angular imports
import { Component, EventEmitter, Input, Output } from '@angular/core';

// Constant imports
import { ActionConstants } from '../../../_constants/action.constants';
import { IconConstants } from '../../../_constants/icon.constants';

@Component({
  selector: 'app-button-cell-template',
  templateUrl: './button-cell-template.component.html',
  styleUrls: ['./button-cell-template.component.scss']
})
export class ButtonCellTemplateComponent {
  @Input() text = ActionConstants.SELECT;
  @Input() hint = ActionConstants.SELECT;
  @Input() icon = IconConstants.SELECT;
  @Output() onClick = new EventEmitter<void>();
}
