<ng-container *ngIf="hasNotes; else emptyNotes">
  <dx-button
    type="success"
    stylingMode="text"
    hint="Has Notes"
    [icon]="iconNotes"
    [elementAttr]="{ class: 'cv-cell-template-icon' }"
    (onClick)="onClick.emit()"
  ></dx-button>
</ng-container>
<ng-template #emptyNotes>
  <dx-button
    type="default"
    stylingMode="text"
    hint="No Notes"
    [icon]="iconEmptyNotes"
    [elementAttr]="{ class: 'cv-cell-template-icon' }"
    (onClick)="onClick.emit()"
  ></dx-button>
</ng-template>
