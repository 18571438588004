// Angular imports
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-label-value-template',
  templateUrl: './label-value-template.component.html',
  styleUrls: ['./label-value-template.component.scss']
})
export class LabelValueTemplateComponent {
  @Input() label: string;
  @Input() value: string;
}
