// Angular imports
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-switch-cell-template',
  templateUrl: './switch-cell-template.component.html',
  styleUrls: ['./switch-cell-template.component.scss']
})
export class SwitchCellTemplateComponent {
  @Input() readOnly = false;
  @Input() tooltipText: string;
  @Input() value: boolean;
  @Output() valueChange = new EventEmitter<boolean>();
}
