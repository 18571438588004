// Angular imports
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

// Rxjs imports
import {
  BehaviorSubject,
  delay,
  map,
  mergeMap,
  Observable,
  of,
  Subject,
  Subscription,
  tap
} from 'rxjs';

// 3rd party imports
import { plainToInstance } from 'class-transformer';

// Util imports
import { FileUtils } from '../_utils/file.utils';
import { Utils } from '../_utils/utils';

// Type imports
import { NavigationEvent } from '../_types/navigation-event';

// Model imports
import { FileBytesResultDto } from '../_models/common/file-bytes-result.dto';
import { FileInfoParameterDto } from '../_models/common/file-info-parameter.dto';

@Injectable({ providedIn: 'root' })
export class HelperService {
  private readonly _subjectLoading = new Subject<boolean>();
  private readonly _subjectSidenavItems = new Subject<NavigationEvent>();
  private readonly _subjectSidenavSelection = new Subject<Array<string>>();
  private readonly _subjectRouterLoaded = new Subject<any>();
  private readonly _subjectPreferencesChange = new Subject<boolean>();
  private readonly _subjectToggleSidenav = new BehaviorSubject<boolean>(true);
  private readonly _subjectToggleHeader = new BehaviorSubject<boolean>(true);
  private readonly _subjectLogoClick = new Subject<void>();

  constructor(private readonly httpClient: HttpClient) {}

  onLogoClick(handler: () => void, delayMillis = 0): Subscription {
    return this._subjectLogoClick
      .pipe(delay(delayMillis), tap(handler))
      .subscribe();
  }

  updateLogoClick(): void {
    this._subjectLogoClick.next();
  }

  onLoading(handler: (flag: boolean) => void, delayMillis = 0): Subscription {
    return this._subjectLoading
      .pipe(delay(delayMillis), tap(handler))
      .subscribe();
  }

  showLoading(): void {
    this._subjectLoading.next(true);
  }

  hideLoading(): void {
    this._subjectLoading.next(false);
  }

  onToggleSidenav(
    handler: (flag: boolean) => void,
    delayMillis = 0
  ): Subscription {
    return this._subjectToggleSidenav
      .pipe(delay(delayMillis), tap(handler))
      .subscribe();
  }

  showSidenav(): void {
    this._subjectToggleSidenav.next(true);
  }

  hideSidenav(): void {
    this._subjectToggleSidenav.next(false);
  }

  isSidenavVisible(): boolean {
    return this._subjectToggleSidenav.getValue();
  }

  onToggleHeader(
    handler: (flag: boolean) => void,
    delayMillis = 0
  ): Subscription {
    return this._subjectToggleHeader
      .pipe(delay(delayMillis), tap(handler))
      .subscribe();
  }

  showHeader(): void {
    this._subjectToggleHeader.next(true);
  }

  hideHeader(): void {
    this._subjectToggleHeader.next(false);
  }

  isHeaderVisible(): boolean {
    return this._subjectToggleHeader.getValue();
  }

  onSidenavItemsUpdated(
    handler: (event: NavigationEvent) => void,
    delayMillis = 0
  ): Subscription {
    return this._subjectSidenavItems
      .pipe(delay(delayMillis), tap(handler))
      .subscribe();
  }

  updateSidenavItems(event: NavigationEvent): void {
    this._subjectSidenavItems.next(event);
  }

  onSidenavSelection(
    handler: (routePaths: Array<string>) => void,
    delayMillis = 0
  ): Subscription {
    return this._subjectSidenavSelection
      .pipe(delay(delayMillis), tap(handler))
      .subscribe();
  }

  updateSidenavSelection(routePaths: Array<string>): void {
    this._subjectSidenavSelection.next(routePaths);
  }

  onRouteActivated(
    handler: (component: any) => void,
    delayMillis = 0
  ): Subscription {
    return this._subjectRouterLoaded
      .pipe(delay(delayMillis), tap(handler))
      .subscribe();
  }

  routeActivated(component: any): void {
    this._subjectRouterLoaded.next(component);
  }

  onPreferencesChanged(
    handler: (flag: boolean) => void,
    delayMillis = 0
  ): Subscription {
    return this._subjectPreferencesChange
      .pipe(delay(delayMillis), tap(handler))
      .subscribe();
  }

  doReloadPreferences(): void {
    this._subjectPreferencesChange.next(true);
  }

  openInTab(paths: Array<string>, queryString?: string): void {
    let url = paths.join('/');
    if (Utils.notNullOrEmpty(queryString)) {
      url = `${url}?${queryString}`;
    }

    try {
      const win = window.open(url, '_blank');
      win.focus();
    } catch (e) {
      Utils.showPopupBlockedError();
    }
  }

  openInWindow(name: string, paths: Array<string>, queryString?: string): void {
    let url = paths.join('/');
    if (Utils.notNullOrEmpty(queryString)) {
      url = `${url}?${queryString}`;
    }
    try {
      const win = window.open(
        url,
        name,
        `width=${screen.availWidth},height=${screen.availHeight},toolbar=no,scrollbars=no,location=no,resizable=yes,menubar=no,status=no`
      );
      win.focus();
    } catch (e) {
      Utils.showPopupBlockedError();
    }
  }

  getFileBytesFromUrl(downloadUrl: string): Observable<FileBytesResultDto> {
    return this.httpClient
      .get(downloadUrl)
      .pipe(map((plain: any) => plainToInstance(FileBytesResultDto, plain)));
  }

  getFileBlobFromUrl(downloadUrl: string): Observable<Blob> {
    return this.httpClient.get(downloadUrl).pipe(
      map((plain: any) => {
        const result = plainToInstance(FileBytesResultDto, plain);
        return FileUtils.getBlob(result.fileBytes, result.filename);
      })
    );
  }

  getFileBlobFromApi(
    url: string,
    params: Record<string, any>
  ): Observable<Blob> {
    return this.httpClient.get(url, { params }).pipe(
      map((plain: any) => {
        const result = plainToInstance(FileBytesResultDto, plain);
        return FileUtils.getBlob(result.fileBytes, result.filename);
      })
    );
  }

  getFileDataUrl(fileInfo: FileInfoParameterDto): Observable<string> {
    if (Utils.notNullOrEmpty(fileInfo.fileBase64String)) {
      return of(
        FileUtils.base64StringToDataUrl(
          fileInfo.fileBase64String,
          fileInfo.filename
        )
      );
    }
    if (Utils.notNullOrEmpty(fileInfo.fileDownloadUrl)) {
      return this.getFileBytesFromUrl(fileInfo.fileDownloadUrl).pipe(
        mergeMap((result: FileBytesResultDto) =>
          FileUtils.bytesToDataUrl(result.fileBytes, result.filename)
        )
      );
    }
    return of();
  }

  getFileBlob(fileInfo: FileInfoParameterDto): Observable<Blob> {
    if (Utils.notNullOrEmpty(fileInfo.fileBase64String)) {
      return of(
        FileUtils.getBlob(fileInfo.fileBase64String, fileInfo.filename)
      );
    }
    if (Utils.notNullOrEmpty(fileInfo.fileDownloadUrl)) {
      return this.getFileBlobFromUrl(fileInfo.fileDownloadUrl);
    }
    return of();
  }

  downloadFileFromApi(
    url: string,
    params: Record<string, any>
  ): Observable<void> {
    return this.httpClient.get(url, { params }).pipe(
      map((plain: any) => {
        const result = plainToInstance(FileBytesResultDto, plain);
        const blob = FileUtils.getBlob(result.fileBytes, result.filename);
        FileUtils.downloadFile(blob, result.filename);
      })
    );
  }

  downloadStaticFile(paths: Array<string>, filename: string): Observable<void> {
    return this.httpClient.get(paths.join('/'), { responseType: 'blob' }).pipe(
      map((blob: Blob) => {
        FileUtils.downloadFile(blob, filename);
      })
    );
  }

  openFileInTab(fileInfo: FileInfoParameterDto): void {
    this.getFileBlob(fileInfo).subscribe((blob: Blob) =>
      FileUtils.openFile(blob, fileInfo.filename)
    );
  }
}
