// Angular imports
import { Directive, HostListener, Input } from '@angular/core';

// Dx imports
import { Column } from 'devextreme/ui/data_grid';

// Util imports
import { Utils } from '../../_utils/utils';

// Model imports
import { DataGridFeatures } from '../../_types/data-grid-feature';

@Directive({
  selector: '[appDataGridFeatures]'
})
export class DataGridFeaturesDirective {
  @Input() applyFeatures = true;
  @Input() enablePaging = false;
  @Input() gridFeatures: DataGridFeatures = {};

  defaultOptions: DataGridFeatures = {
    showBorders: true,
    showRowLines: true,
    showColumnLines: true,
    columnAutoWidth: true,
    columnHiding: false,
    columnMinWidth: 50,
    columnReordering: false,
    columnResizing: false,
    renderAsync: false,
    columnResizingMode: 'widget',
    wordWrapEnabled: false,
    showColumnHeaders: true,
    sorting: { mode: 'single' },
    scrolling: {
      mode: 'virtual',
      renderAsync: true,
      rowRenderingMode: 'virtual',
      columnRenderingMode: 'virtual'
    },
    headerFilter: { visible: true },
    filterRow: { visible: true, allowSearch: true },
    filterPanel: { visible: true },
    groupPanel: { visible: false },
    grouping: { autoExpandAll: true },
    loadPanel: { enabled: false },
    paging: { enabled: false, pageSize: 25 },
    pager: {
      visible: false,
      allowedPageSizes: [5, 10, 25, 50, 75, 100, 'all'],
      displayMode: 'compact',
      showInfo: true,
      showPageSizeSelector: true,
      showNavigationButtons: true
    }
  };

  @HostListener('onContentReady', ['$event'])
  onContentReady(e: any): void {
    if (Utils.isTrue(this.applyFeatures)) {
      this.applyFeatures = false;
      if (Utils.isTrue(this.enablePaging)) {
        this.defaultOptions.scrolling.mode = 'standard';
        this.defaultOptions.paging.enabled = true;
        this.defaultOptions.pager.visible = true;
      }
      const instance = e.component;
      const columns = instance.option('columns') as Column[];
      columns.forEach((col: Column) => {
        if (Utils.equalsIgnoreCase(col.type, 'actions')) {
          col.alignment = 'center';
          col.width = col.width || 120;
          col.allowEditing = false;
          col.allowExporting = false;
          col.allowFixing = false;
          col.allowFiltering = false;
          col.allowGrouping = false;
          col.allowHiding = false;
          col.allowResizing = false;
          col.allowReordering = false;
          col.allowSearch = false;
          col.allowSorting = false;
        }
        if (Utils.isNullOrEmpty(col.dataType)) {
          col.allowFiltering = false;
          col.allowHeaderFiltering = false;
        } else if (Utils.equalsIgnoreCase(col.dataType, 'boolean')) {
          col.allowFiltering = true;
          col.allowHeaderFiltering = col.allowHeaderFiltering || false;
          col.trueText = col.trueText || 'Yes';
          col.falseText = col.falseText || 'No';
        } else if (
          Utils.equalsIgnoreCase(col.dataType, 'number') ||
          Utils.equalsIgnoreCase(col.dataType, 'date') ||
          Utils.equalsIgnoreCase(col.dataType, 'datetime')
        ) {
          col.filterOperations = [
            'contains',
            '=',
            '<>',
            '<',
            '>',
            '<=',
            '>=',
            'between'
          ];
        }
        if (Utils.equalsIgnoreCase(col.dataType, 'date')) {
          col.width = col.width || 165;
        } else if (Utils.equalsIgnoreCase(col.dataType, 'datetime')) {
          col.width = col.width || 175;
        }
      });

      // Merge default and input optiions
      const options = Object.assign(
        { columns },
        this.defaultOptions,
        this.gridFeatures
      );

      // Update options
      instance.option(options);
    }
  }
}
