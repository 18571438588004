// Angular imports
import { Injectable } from '@angular/core';

// Dx imports
import notify from 'devextreme/ui/notify';

// Constant imports
import { TimerConstants } from '../_constants/timer.constants';
import { ScreenService } from './screen.service';

@Injectable({ providedIn: 'root' })
export class ToastService {
  constructor(private readonly screenService: ScreenService) {}

  showSuccess(message: string): void {
    this.showMessage(message, 'success', TimerConstants.inSeconds(5));
  }

  showInformation(message: string): void {
    this.showMessage(message, 'info', TimerConstants.inSeconds(5));
  }

  showWarning(message: string): void {
    this.showMessage(message, 'warning', TimerConstants.inSeconds(7));
  }

  showError(message: string): void {
    this.showMessage(message, 'error', TimerConstants.inSeconds(7));
  }

  showMessage(message: string, type: string, displayTime: number) {
    if (this.screenService.isLarge()) {
      const xSize = document.body.clientWidth / 10;
      const ySize = document.body.clientHeight / 10;
      notify(
        {
          message,
          type,
          displayTime
        },
        {
          position: { left: xSize, right: xSize, bottom: ySize },
          direction: 'up-stack'
        }
      );
    } else {
      notify(message, type, displayTime);
    }
  }
}
