// Angular imports
import { Component, Input, OnInit } from '@angular/core';

// Dx imports
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';

// Util imports
import { DateUtils } from '../../../_utils/date.utils';

// Constant imports
import { FormatterConstants } from '../../../_constants/formatter.constants';

// Type imports
import { DataGridExportOptions } from '../../../_types/data-grid-export-option';
import { DataGridExportTitle } from '../../../_types/data-grid-export-title';
import { DxItemOption } from '../../../_types/dx-item-option';

@Component({
  selector: 'app-export-grid-button',
  templateUrl: './export-grid-button.component.html',
  styleUrls: ['./export-grid-button.component.scss']
})
export class ExportGridButtonComponent implements OnInit {
  @Input() dataGrid: DxDataGridComponent;
  @Input() filename: string;
  @Input() titles: Array<DataGridExportTitle>;
  @Input() columnWidths: Array<number>;
  @Input() orientation: 'p' | 'portrait' | 'l' | 'landscape';

  showFilename = false;

  options: DataGridExportOptions;
  itemOption: DxItemOption;
  doExport: (rec: DxItemOption, filename: string) => void;

  constructor() {
    this.onExportFn = this.onExportFn.bind(this);
  }

  ngOnInit(): void {
    this.options = {
      titles: this.titles || [],
      filename:
        this.filename ||
        `exported_${DateUtils.dateToString(
          new Date(),
          FormatterConstants.MOMENT_DATE
        )}`,
      orientation: this.orientation || 'landscape',
      columnWidths: this.columnWidths || []
    };
  }

  onExportFn(
    itemOption: DxItemOption,
    doExport: (rec: DxItemOption, filename: string) => void
  ): void {
    this.itemOption = itemOption;
    this.doExport = doExport;
    this.showFilename = true;
  }

  onOkClick(filename: string): void {
    this.options.filename = filename;
    this.doExport(this.itemOption, filename);
  }

  onPromptClose(): void {
    this.showFilename = false;
    this.itemOption = null;
  }
}
