export const FaSolidIcon = (name: string) => `fa-solid fa-${name}`;

export const FaRegularIcon = (name: string) => `fa-regular fa-${name}`;

export const FaRotateIcon = (icon: string, angle: number) =>
  `${icon} fa-rotate-${angle}`;

export class IconConstants {
  static readonly NONE = null;
  static readonly EMPTY = '';
  // Regular Icons
  static readonly USER_CIRCLE = FaRegularIcon('user-circle');
  static readonly TRASH = FaRegularIcon('trash-can');
  static readonly DELETE = FaRegularIcon('trash-can');
  static readonly CHECK = FaRegularIcon('circle-check');
  static readonly CROSS = FaRegularIcon('circle-xmark');
  static readonly CLEAR = FaRegularIcon('circle-xmark');
  static readonly SUCCESS = FaRegularIcon('thumbs-up');
  static readonly SAVE = FaRegularIcon('floppy-disk');
  static readonly NOTES_EMPTY = FaRegularIcon('clipboard');
  static readonly SEND = FaRegularIcon('paper-plane');
  static readonly PUBLISH = FaRegularIcon('share-from-square');
  static readonly EXPAND = FaRegularIcon('square-plus');
  static readonly COLLAPSE = FaRegularIcon('square-minus');
  static readonly SEE = FaRegularIcon('eye');
  static readonly HIDE = FaRegularIcon('eye-slash');
  static readonly BLANK_BOX = FaRegularIcon('square');
  static readonly STAR_EMPTY = FaRegularIcon('star');
  static readonly CIRCLE_EMPTY = FaRegularIcon('circle');
  // Solid Icons
  static readonly COPY = FaSolidIcon('copy');
  static readonly PRINT = FaSolidIcon('print');
  static readonly ACTION = FaSolidIcon('person-running');
  static readonly HOME = FaSolidIcon('home');
  static readonly ADMINISTRATION = FaSolidIcon('gear');
  static readonly MY_HOME = FaSolidIcon('star');
  static readonly LOGIN = FaSolidIcon('right-to-bracket');
  static readonly ENTER = FaSolidIcon('arrow-right-to-bracket');
  static readonly LOGOUT = FaSolidIcon('right-from-bracket');
  static readonly EXIT = FaSolidIcon('arrow-right-from-bracket');
  static readonly USER = FaSolidIcon('user');
  static readonly USER_PREFERENCES = FaSolidIcon('user-gear');
  static readonly SETTINGS = FaSolidIcon('gear');
  static readonly BARS = FaSolidIcon('bars');
  static readonly BACK = FaSolidIcon('arrow-left');
  static readonly KEY = FaSolidIcon('key');
  static readonly HELP = FaSolidIcon('circle-question');
  static readonly EDIT = FaSolidIcon('pen-to-square');
  static readonly LEARN = FaSolidIcon('circle-info');
  static readonly PLUS = FaSolidIcon('plus');
  static readonly SELECT = FaSolidIcon('check');
  static readonly SUBMIT = FaSolidIcon('check');
  static readonly ABSTAIN = FaSolidIcon('check');
  static readonly APPROVE = FaSolidIcon('check');
  static readonly VERIFY = FaSolidIcon('check');
  static readonly CLOSE = FaSolidIcon('xmark');
  static readonly REMOVE = FaSolidIcon('xmark');
  static readonly REJECT = FaSolidIcon('xmark');
  static readonly NO = FaSolidIcon('xmark');
  static readonly YES = FaSolidIcon('check');
  static readonly OK = FaSolidIcon('check');
  static readonly INFO = FaSolidIcon('circle-info');
  static readonly WARN = FaSolidIcon('circle-exclamation');
  static readonly ERROR = FaSolidIcon('triangle-exclamation');
  static readonly UP = FaSolidIcon('up-long');
  static readonly DOWN = FaSolidIcon('down-long');
  static readonly ARROW_DOWN = FaSolidIcon('chevron-down');
  static readonly ARROW_RIGHT = FaSolidIcon('chevron-right');
  static readonly ARROW_LEFT = FaSolidIcon('chevron-left');
  static readonly ANGLE_ARROW_RIGHT = FaSolidIcon('angle-right');
  static readonly ANGLE_ARROW_DOWN = FaSolidIcon('angle-down');
  static readonly FINISH = FaSolidIcon('circle-check');
  static readonly COMPLETED = FaSolidIcon('circle-check');
  static readonly PIN = FaSolidIcon('thumbtack fa-rotate-45');
  static readonly UNPIN = FaSolidIcon('thumbtack');
  static readonly RESET = FaSolidIcon('xmark');
  static readonly CANCEL = FaSolidIcon('ban');
  static readonly NA = FaSolidIcon('minus');
  static readonly MINUS = FaSolidIcon('minus');
  static readonly VIEW = FaSolidIcon('eye');
  static readonly BROWSE = FaSolidIcon('file-import');
  static readonly ATTACHMENT = FaSolidIcon('paperclip');
  static readonly REFRESH = FaSolidIcon('rotate');
  static readonly NOTES = FaSolidIcon('clipboard-check');
  static readonly MARK_ALL = FaSolidIcon('check-double');
  static readonly MARK_FILTER = FaSolidIcon('filter');
  static readonly MARK_VISIBLE = FaSolidIcon('glasses');
  static readonly VIDEO = FaSolidIcon('video');
  static readonly HISTORY = FaSolidIcon('clock-rotate-left');
  static readonly SEARCH = FaSolidIcon('magnifying-glass');
  static readonly GUARD = FaSolidIcon('user-shield');
  static readonly INPROGRESS = FaSolidIcon('hourglass-start');
  static readonly CIRCLE_FILLED = FaSolidIcon('circle');
  static readonly ELECTION_DASHBOARD = FaSolidIcon('gauge');
  static readonly ELECTION_CHARTER = FaSolidIcon('check-to-slot');
  static readonly READINESS = FaSolidIcon('list-check');
  static readonly NOTICES = FaSolidIcon('bullhorn');
  static readonly CANDIDATES = FaSolidIcon('user-tie');
  static readonly BALLOT_SETUP = FaSolidIcon('hospital-user');
  static readonly ELECTION_REVIEW = FaSolidIcon('share-from-square');
  static readonly VOTER = FaSolidIcon('users-between-lines');
  static readonly DOCUMENTS = FaSolidIcon('file-signature');
  static readonly MAILER = FaSolidIcon('envelope-open-text');
  static readonly SURVEY_DASHBOARD = FaSolidIcon('gauge');
  static readonly SURVEY_SETUP = FaSolidIcon('sliders');
  static readonly SURVEY_QUESTIONS = FaSolidIcon('clipboard-question');
  static readonly SURVEY_PARTICIPANTS = FaSolidIcon('users-between-lines');
  static readonly SURVEY_NOTICES = FaSolidIcon('bullhorn');
  static readonly SURVEY_REVIEW = FaSolidIcon('share-from-square');
  static readonly PARTICIPANT = FaSolidIcon('users-between-lines');
  static readonly QUESTION = FaSolidIcon('sliders');
  static readonly SKIP = FaSolidIcon('forward');
  static readonly STAR = FaSolidIcon('star');
  static readonly TILE_VIEW = FaSolidIcon('grip');
  static readonly LIST_VIEW = FaSolidIcon('list');
  static readonly VOTES_MANAGEMENT = FaSolidIcon('person-booth');
  static readonly ELECTION_REPORTS = FaSolidIcon('table-list');
  static readonly SURVEY_REPORTS = FaSolidIcon('table-list');
  static readonly EXPORT = FaSolidIcon('file-export');
  static readonly EXPORT_PDF = FaSolidIcon('file-pdf');
  static readonly EXPORT_CSV = FaSolidIcon('file-csv');
  static readonly EXPORT_XLS = FaSolidIcon('file-excel');
  static readonly DOWNLOAD = FaSolidIcon('download');
  static readonly UPLOAD = FaSolidIcon('upload');
  static readonly USER_ACTIVATE = FaSolidIcon('user-check');
  static readonly USER_DEACTIVATE = FaSolidIcon('user-slash');
  static readonly USER_LOCK = FaSolidIcon('user-lock');
  static readonly LINK = FaSolidIcon('link');
  static readonly UNLINK = FaSolidIcon('link-slash');
  static readonly SYNC = FaSolidIcon('arrows-rotate');
  static readonly LOGS = FaSolidIcon('file-waveform');
  static readonly SCHEDULE = FaSolidIcon('stopwatch');
  static readonly SECURITY = FaSolidIcon('shield-halved');
  static readonly SEQUENCE = FaRotateIcon(
    FaSolidIcon('arrow-right-arrow-left'),
    90
  );
  static readonly TROUBLESHOOT = FaSolidIcon('wrench');
  static readonly FEEDBACK = FaSolidIcon('comment-dots');
  static readonly MAXIMIZE = FaSolidIcon('arrow-up-right-from-square');
  static readonly MINIMIZE = FaRotateIcon(
    FaSolidIcon('arrow-up-right-from-square'),
    180
  );
  static readonly AMENDMENT_DASHBOARD = FaSolidIcon('gauge');
  static readonly AMENDMENT_SETUP = FaSolidIcon('check-to-slot');
  static readonly AMENDMENT_PREFERENCES = FaSolidIcon('gear');
  static readonly AMENDMENT_BALLOT_SETUP = FaSolidIcon('hospital-user');
  static readonly AMENDMENT_DOCUMENT_SETUP = FaSolidIcon('file-signature');
  static readonly AMENDMENT_VOTER_SETUP = FaSolidIcon('users-between-lines');
  static readonly AMENDMENT_NOTICES = FaSolidIcon('bullhorn');
  static readonly AMENDMENT_REVIEW = FaSolidIcon('share-from-square');
  static readonly AMENDMENT_REPORTS = FaSolidIcon('table-list');
  static readonly AMENDMENT_VOTES_MANAGEMENT = FaSolidIcon('person-booth');
}
