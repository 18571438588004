<dx-toolbar
  [disabled]="readOnly"
  [elementAttr]="{ class: 'cv-star-rating-content' }"
>
  <dxi-item location="center" *ngFor="let rating of ratings">
    <div *dxTemplate>
      <dx-button
        stylingMode="text"
        template="star-template"
        [type]="ratingType"
        [text]="rating + ' Star Rating'"
        (onClick)="onRatingChange(rating)"
      >
        <div
          *dxTemplate="let t of 'star-template'"
          class="cv-zerobox-content"
          [title]="t.text"
        >
          <i
            aria-hidden="true"
            class="cv-hyperlink"
            [ngStyle]="iconStyle"
            [ngClass]="rating <= value ? iconStar : iconStarEmpty"
          ></i>
        </div>
      </dx-button>
    </div>
  </dxi-item>
</dx-toolbar>
