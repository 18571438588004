// Angular imports
import {
  Component,
  ContentChild,
  Injector,
  OnInit,
  TemplateRef
} from '@angular/core';

// Component imports
import { BaseComponent } from '../../../_base/base.component';

// Util imports
import { Utils } from '../../../_utils/utils';

// Type imports
import { NavigationEntry } from '../../../_types/navigation-entry';

@Component({
  selector: 'app-navigation-pane',
  templateUrl: './navigation-pane.component.html',
  styleUrls: ['./navigation-pane.component.scss']
})
export class NavigationPaneComponent extends BaseComponent implements OnInit {
  @ContentChild('navigation', { static: false }) navigation: TemplateRef<any>;
  @ContentChild('content', { static: false }) content: TemplateRef<any>;

  isOpened = true;
  isOpenTemporary = false;
  openedStateMode: 'shrink' | 'overlap' = 'shrink';
  revealMode: 'slide' | 'expand' = 'expand';
  selectedItem: NavigationEntry;
  expandedItem: NavigationEntry;
  contentHeight: string;

  constructor(protected override readonly injector: Injector) {
    super(injector);
    this.onItemClick = this.onItemClick.bind(this);
    this.onItemExpand = this.onItemExpand.bind(this);
  }

  ngOnInit(): void {
    this.helperService.onToggleSidenav((flag: boolean) => {
      this.isOpenTemporary = Utils.equalsIgnoreCase(this.revealMode, 'slide');
      this.isOpened = flag;
    });
  }

  onItemClick(entry: NavigationEntry): void {
    this.selectedItem = entry;
    if (Utils.isFalse(this.isOpened)) {
      this.isOpenTemporary = true;
      this.isOpened = true;
    }
  }

  onItemExpand(entry: NavigationEntry): void {
    this.expandedItem = entry;
  }

  onOpenedChange(flag: boolean): void {
    if (Utils.isTrue(this.isSmall) && Utils.isFalse(flag)) {
      this.helperService.hideSidenav();
    }
  }

  protected override updateScreenSize(): void {
    super.updateScreenSize();
    this.contentHeight = `${
      document.body.clientHeight - (this.isSmall ? 50 : 82)
    }px`;
    if (Utils.isTrue(this.isSmall)) {
      this.isOpened = false;
      this.openedStateMode = 'overlap';
      this.revealMode = 'slide';
    } else {
      this.isOpened = true;
      this.openedStateMode = 'shrink';
      this.revealMode = 'expand';
    }
  }
}
