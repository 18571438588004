// Dx imports
import { DxFormComponent } from 'devextreme-angular/ui/form';
import { DxValidatorComponent } from 'devextreme-angular/ui/validator';

// Util imports
import { Utils } from './utils';

export class DxWidgetUtils {
  static hasInstance(widget: any): boolean {
    return (
      Utils.notNullOrEmpty(widget) && Utils.notNullOrEmpty(widget.instance)
    );
  }

  static instantRepaint(widget: any): void {
    if (DxWidgetUtils.hasInstance(widget)) {
      widget.instance.repaint();
    }
  }

  static deferredRepaint(widget: any, ms: number) {
    return setTimeout(DxWidgetUtils.instantRepaint, ms, [widget]);
  }

  static isFormValid(widget: DxFormComponent | DxValidatorComponent): boolean {
    if (DxWidgetUtils.hasInstance(widget)) {
      const validate = widget.instance.validate();
      return validate.isValid;
    }
    return false;
  }
}
