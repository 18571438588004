// Angular imports
import { HttpHeaders, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

// Util imports
import { Utils } from '../_utils/utils';

// Type imports
import { CacheEntry } from '../_types/cache-entry';

// Model imports
import { BaseQueryParameterDto } from '../_models/_base/base-query-parameter.dto';

@Injectable({ providedIn: 'root' })
export class CachingService {
  private readonly _cacheMap = new Map<string, CacheEntry>();

  /* Methods Used In Interceptor Begin */
  set(cacheUrl: string, entry: CacheEntry): void {
    this._cacheMap.set(cacheUrl, entry);
  }

  get(cacheUrl: string): CacheEntry | undefined {
    return this._cacheMap.get(cacheUrl);
  }

  delete(cacheUrl: string): void {
    this._cacheMap.delete(cacheUrl);
  }

  reset(cacheUrl: string): void {
    if (this.registered(cacheUrl)) {
      const entry = this.get(cacheUrl);
      entry.loadedAt = -1;
      entry.loading = false;
      entry.response = null;
      this.set(cacheUrl, entry!);
    }
  }

  registered(cacheUrl: string): boolean {
    return this._cacheMap.has(cacheUrl);
  }

  expired(cacheUrl: string): boolean {
    let flag = false;
    if (this.registered(cacheUrl)) {
      const entry = this.get(cacheUrl);
      flag = entry.loadedAt === -1 || Date.now() > entry.loadedAt + entry.ttl;
    }
    return flag;
  }

  update(req: HttpRequest<any>, res: HttpResponse<any>): void {
    const cacheUrl = this.getCacheUrl(req);
    if (this.registered(cacheUrl)) {
      const cacheEntry = this._cacheMap.get(cacheUrl);
      cacheEntry.response = res;
      cacheEntry.loadedAt = Date.now();
      cacheEntry.loading = false;
    }
  }
  /* Methods Used In Interceptor End */

  /* Methods Used In Services Begin */
  register(
    url: string,
    ttl: number,
    dto?: BaseQueryParameterDto
  ): { headers: HttpHeaders; params?: any } {
    let headers = new HttpHeaders();
    const cacheUrl = this.cachedUrl(url, dto);
    if (
      Utils.isFalse(this.registered(cacheUrl)) ||
      (Utils.notNullOrEmpty(dto) && Utils.isTrue(dto.clearCache))
    ) {
      headers = headers.set('reset-cache', 'true');
      this.set(cacheUrl, {
        loadedAt: -1,
        ttl,
        loading: false,
        response: null
      });
    }
    return Utils.notNullOrEmpty(dto)
      ? { headers, params: dto.toQueryParams() }
      : { headers };
  }

  clearCache(url: string, dto?: BaseQueryParameterDto): void {
    const cacheUrl = this.cachedUrl(url, dto);
    this.delete(cacheUrl);
  }

  getCacheUrl(req: HttpRequest<any>): string {
    const url = req.url;
    const tokens = [];
    if (Utils.notNullAndDefined(req.params)) {
      req.params
        .keys()
        .sort()
        .forEach((key: string) => {
          if (
            Utils.notNullAndDefined(req.params.get(key)) &&
            Utils.notEqualsIgnoreCase(key, 'clearCache')
          ) {
            tokens.push(`${key}=${req.params.get(key)}`);
          }
        });
    }
    return this.normalizedUrl(
      Utils.notNullOrEmpty(tokens) ? `${url}?${tokens.join('&')}` : `${url}`
    );
  }
  /* Methods Used In Services End */

  /* Private Methods */
  private cachedUrl(url: string, dto?: BaseQueryParameterDto): string {
    return this.normalizedUrl(
      Utils.notNullAndDefined(dto) ? `${url}?${dto.toQueryString()}` : `${url}`
    );
  }

  private normalizedUrl(url: string): string {
    return url
      .replace('clearCache=false&', '')
      .replace('clearCache=true&', '')
      .replace('clearCache=false', '')
      .replace('clearCache=true', '');
  }
}
