// Angular imports
import { Injectable } from '@angular/core';

// 3rd party imports
import { plainToInstance } from 'class-transformer';
import { setUser as setSentryUser } from '@sentry/angular-ivy';

// Service imports
import { AuthService } from './auth.service';

// Util imports
import { Utils } from '../_utils/utils';

// Constant imports
import { StorageKeyConstants } from '../_constants/storage-key.constants';

// Model imports
import { UserInfoResultDto } from '../_models/user/user-info-result.dto';
import { ParticipantInfoResultDto } from '../_models/participant/participant-info-result.dto';

@Injectable({ providedIn: 'root' })
export class UserService {
  private _loggedInUser: UserInfoResultDto;
  private _loggedInParticipant: ParticipantInfoResultDto;

  constructor(private readonly authService: AuthService) {
    this.authService.onUserLogin((flag: boolean) => {
      if (Utils.isTrue(flag)) {
        const userInfo = localStorage.getItem(StorageKeyConstants.USER_INFO);
        this._loggedInUser = plainToInstance(
          UserInfoResultDto,
          JSON.parse(userInfo)
        );
        // Set user context in Sentry or use it for additional logging
        setSentryUser({
          id: this._loggedInUser.userId,
          username: this._loggedInUser.displayName
        });
      } else {
        this._loggedInUser = null;
        setSentryUser(null);
      }
    });
    this.authService.onParticipantLogin((flag: boolean) => {
      if (Utils.isTrue(flag)) {
        const userInfo = localStorage.getItem(
          StorageKeyConstants.PARTCIPANT_INFO
        );
        this._loggedInParticipant = plainToInstance(
          ParticipantInfoResultDto,
          JSON.parse(userInfo)
        );
        // Set user context in Sentry or use it for additional logging
        setSentryUser({
          id: this._loggedInParticipant.email,
          username: this._loggedInParticipant.displayName
        });
      } else {
        this._loggedInParticipant = null;
        setSentryUser(null);
      }
    });
  }

  getLoggedInUser(): UserInfoResultDto {
    return this._loggedInUser;
  }

  getLoggedInParticipant(): ParticipantInfoResultDto {
    return this._loggedInParticipant;
  }
}
