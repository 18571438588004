<dx-button
  type="default"
  stylingMode="text"
  hint="History"
  [icon]="iconHistory"
  [elementAttr]="{ class: 'cv-cell-template-icon' }"
  (onClick)="onHistoryClick()"
></dx-button>

<app-history-summary-viewer
  *ngIf="showHistory"
  [record]="record"
  [historyOf]="historyOf"
  [historySummary]="historySummary"
  [historyDetailsFn]="historyDetailsFn"
  (onClose)="showHistory = false"
></app-history-summary-viewer>
