// Angular imports
import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appSwitchYesNo]'
})
export class SwitchYesNoDirective {
  @HostListener('onInitialized', ['$event'])
  onInitialized(e: any): void {
    e.component.option({
      switchedOnText: 'Yes',
      switchedOffText: 'No'
    });
  }
}
