// Angular imports
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Platform } from '@angular/cdk/platform';
import { Injectable } from '@angular/core';

// Dx imports
import devices from 'devextreme/core/devices';

// Rxjs imports
import {
  delay,
  forkJoin,
  fromEvent,
  Subject,
  Subscription,
  tap,
  throttleTime
} from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ScreenService {
  private readonly _subjectChanged = new Subject<void>();
  private readonly _subjectResized = new Subject<void>();

  constructor(
    private readonly platform: Platform,
    private readonly breakpointObserver: BreakpointObserver
  ) {
    this.handleScreenChange();
    this.handleResizeEvent();
  }

  onScreenChanged(handler: () => void, delayMillis = 0): Subscription {
    return this._subjectChanged
      .pipe(delay(delayMillis), tap(handler))
      .subscribe();
  }

  onSizeChanged(handler: () => void, delayMillis = 0): Subscription {
    return this._subjectResized
      .pipe(delay(delayMillis), tap(handler))
      .subscribe();
  }

  onChanged(handler: () => void, delayMillis = 0): Subscription {
    return forkJoin([
      this._subjectChanged.pipe(delay(delayMillis), tap(handler)),
      this._subjectResized.pipe(delay(delayMillis), tap(handler))
    ]).subscribe();
  }

  isDevice(): boolean {
    const device = devices.current();
    return (
      device.phone ||
      device.tablet ||
      device.android ||
      device.ios ||
      this.platform.ANDROID ||
      this.platform.IOS
    );
  }

  isXLarge(): boolean {
    return this.breakpointObserver.isMatched(Breakpoints.XLarge);
  }

  isLarge(): boolean {
    return (
      this.breakpointObserver.isMatched(Breakpoints.Large) ||
      this.breakpointObserver.isMatched(Breakpoints.XLarge)
    );
  }

  isMedium(): boolean {
    return this.breakpointObserver.isMatched(Breakpoints.Medium);
  }

  isSmall(): boolean {
    return (
      this.breakpointObserver.isMatched(Breakpoints.Small) ||
      this.breakpointObserver.isMatched(Breakpoints.XSmall)
    );
  }

  isXSmall(): boolean {
    return (
      this.breakpointObserver.isMatched(Breakpoints.XSmall) ||
      this.breakpointObserver.isMatched(Breakpoints.HandsetLandscape)
    );
  }

  isMobile(): boolean {
    return (
      this.isDevice() &&
      (this.breakpointObserver.isMatched(Breakpoints.Handset) ||
        this.breakpointObserver.isMatched(Breakpoints.HandsetLandscape) ||
        this.breakpointObserver.isMatched(Breakpoints.HandsetPortrait))
    );
  }

  isTablet(): boolean {
    return (
      this.isDevice() &&
      (this.breakpointObserver.isMatched(Breakpoints.Tablet) ||
        this.breakpointObserver.isMatched(Breakpoints.TabletLandscape) ||
        this.breakpointObserver.isMatched(Breakpoints.TabletPortrait))
    );
  }

  isWeb(): boolean {
    return (
      this.breakpointObserver.isMatched(Breakpoints.Web) ||
      this.breakpointObserver.isMatched(Breakpoints.WebLandscape) ||
      this.breakpointObserver.isMatched(Breakpoints.WebPortrait)
    );
  }

  isLandscape(): boolean {
    return (
      this.breakpointObserver.isMatched(Breakpoints.WebLandscape) ||
      this.breakpointObserver.isMatched(Breakpoints.TabletLandscape) ||
      this.breakpointObserver.isMatched(Breakpoints.HandsetLandscape)
    );
  }

  isPortrait(): boolean {
    return (
      this.breakpointObserver.isMatched(Breakpoints.WebPortrait) ||
      this.breakpointObserver.isMatched(Breakpoints.HandsetPortrait) ||
      this.breakpointObserver.isMatched(Breakpoints.TabletPortrait)
    );
  }

  private handleScreenChange(delayMillis = 500): void {
    this.breakpointObserver
      .observe([
        Breakpoints.XSmall,
        Breakpoints.Small,
        Breakpoints.Medium,
        Breakpoints.Large,
        Breakpoints.XLarge,
        Breakpoints.Handset,
        Breakpoints.HandsetPortrait,
        Breakpoints.HandsetLandscape,
        Breakpoints.Tablet,
        Breakpoints.TabletPortrait,
        Breakpoints.TabletLandscape,
        Breakpoints.Web,
        Breakpoints.WebPortrait,
        Breakpoints.WebLandscape
      ])
      .pipe(
        delay(delayMillis),
        throttleTime(delayMillis),
        tap(() => this._subjectChanged.next())
      )
      .subscribe();
  }

  private handleResizeEvent(delayMillis = 500): void {
    fromEvent(window, 'resize')
      .pipe(
        delay(delayMillis),
        throttleTime(delayMillis),
        tap(() => this._subjectResized.next())
      )
      .subscribe();
  }
}
