// Angular imports
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

// 3rd party imports
import { plainToInstance } from 'class-transformer';

// Rxjs imports
import { map, Observable } from 'rxjs';

// Service imports
import { CachingService } from './caching.service';

// Constant imports
import { TimerConstants } from '../_constants/timer.constants';
import { UrlConstants } from '../_constants/url.constants';

// Model imports
import { BaseQueryParameterDto } from '../_models/_base/base-query-parameter.dto';
import { CityParameterDto } from '../_models/geolocation/city-parameter.dto';
import { CityResultDto } from '../_models/geolocation/city-result.dto';
import { CitySearchParameterDto } from '../_models/geolocation/city-search-parameter.dto';
import { CitySelectorResultDto } from '../_models/geolocation/city-selector-result.dto';
import { CountryResultDto } from '../_models/geolocation/country-result.dto';
import { StateParameterDto } from '../_models/geolocation/state-parameter.dto';
import { StateResultDto } from '../_models/geolocation/state-result.dto';

@Injectable({ providedIn: 'root' })
export class GeoLocationService {
  constructor(
    protected readonly httpClient: HttpClient,
    protected readonly cachingService: CachingService
  ) {}

  getCountries(
    params: BaseQueryParameterDto
  ): Observable<Array<CountryResultDto>> {
    const url = `${UrlConstants.GEO_LOCATION_BASE_URL}/countries`;
    const options = this.cachingService.register(
      url,
      TimerConstants.inHours(1),
      params
    );
    return this.httpClient
      .get<Array<any>>(url, options)
      .pipe(
        map((plain: Array<any>) => plainToInstance(CountryResultDto, plain))
      );
  }

  getStatesOfCountry(
    params: StateParameterDto
  ): Observable<Array<StateResultDto>> {
    const url = `${UrlConstants.GEO_LOCATION_BASE_URL}/states`;
    const options = this.cachingService.register(
      url,
      TimerConstants.inMinutes(30),
      params
    );
    return this.httpClient
      .get<Array<any>>(url, options)
      .pipe(map((plain: Array<any>) => plainToInstance(StateResultDto, plain)));
  }

  getCitiesOfState(params: CityParameterDto): Observable<Array<CityResultDto>> {
    const url = `${UrlConstants.GEO_LOCATION_BASE_URL}/cities`;
    const options = this.cachingService.register(
      url,
      TimerConstants.inMinutes(30),
      params
    );
    return this.httpClient
      .get<Array<any>>(url, options)
      .pipe(map((plain: Array<any>) => plainToInstance(CityResultDto, plain)));
  }

  searchCities(
    params: CitySearchParameterDto
  ): Observable<Array<CitySelectorResultDto>> {
    const url = `${UrlConstants.GEO_LOCATION_BASE_URL}/cities/search`;
    return this.httpClient
      .get<Array<any>>(url, { params: params.toQueryParams() })
      .pipe(
        map((plain: Array<any>) =>
          plainToInstance(CitySelectorResultDto, plain)
        )
      );
  }
}
