// Angular imports
import { Pipe, PipeTransform } from '@angular/core';

// Util imports
import { Utils } from '../_utils/utils';

@Pipe({
  name: 'sortBy'
})
export class SortByPipe implements PipeTransform {
  transform(values: Array<any>, field: string, asc: 1 | -1 = 1): any[] {
    return Utils.sortByField(values, field, asc);
  }
}
