<dx-popup
  [minWidth]="minWidth"
  [minHeight]="minHeight"
  [maxWidth]="maxWidth"
  [maxHeight]="maxHeight"
  [width]="width"
  [height]="height"
  [title]="title"
  [showTitle]="!!title"
  [showCloseButton]="!!title"
  [dragEnabled]="!!title"
  [resizeEnabled]="!!title"
  [fullScreen]="fullScreen"
  [container]="container"
  [position]="{ my: 'center', at: 'center', of: container }"
  [visible]="visible"
  [shading]="true"
  (onShown)="onShown($event)"
  (onHidden)="onPopupHidden()"
  (onResizeEnd)="onResize.emit($event)"
>
  <div *dxTemplate="let t of 'content'">
    <ng-content></ng-content>
  </div>
  <dxi-toolbar-item
    *ngIf="isButtonVisible('close')"
    toolbar="bottom"
    [location]="isXSmall ? 'center' : 'before'"
  >
    <div *dxTemplate>
      <dx-button appButtonClose (onClick)="onHidden.emit()"></dx-button>
    </div>
  </dxi-toolbar-item>
  <dxi-toolbar-item
    *ngIf="isButtonVisible('cancel')"
    toolbar="bottom"
    location="before"
  >
    <div *dxTemplate>
      <dx-button appButtonCancel (onClick)="onHidden.emit()"></dx-button>
    </div>
  </dxi-toolbar-item>
  <dxi-toolbar-item
    *ngIf="isButtonVisible('save')"
    toolbar="bottom"
    location="after"
  >
    <div *dxTemplate>
      <dx-button
        appButtonSave
        (onClick)="onButtonClick.emit('save')"
      ></dx-button>
    </div>
  </dxi-toolbar-item>
  <dxi-toolbar-item
    *ngIf="isButtonVisible('submit')"
    toolbar="bottom"
    location="after"
  >
    <div *dxTemplate>
      <dx-button
        appButtonSubmit
        (onClick)="onButtonClick.emit('submit')"
      ></dx-button>
    </div>
  </dxi-toolbar-item>
  <dxi-toolbar-item
    *ngIf="isButtonVisible('ok')"
    toolbar="bottom"
    location="after"
  >
    <div *dxTemplate>
      <dx-button appButtonOk (onClick)="onButtonClick.emit('ok')"></dx-button>
    </div>
  </dxi-toolbar-item>
  <dxi-toolbar-item
    *ngIf="isButtonVisible('send')"
    toolbar="bottom"
    location="after"
  >
    <div *dxTemplate>
      <dx-button
        appButtonSend
        (onClick)="onButtonClick.emit('send')"
      ></dx-button>
    </div>
  </dxi-toolbar-item>
  <dxi-toolbar-item
    *ngIf="isButtonVisible('generate')"
    toolbar="bottom"
    location="after"
  >
    <div *dxTemplate>
      <dx-button
        appButtonGenerate
        (onClick)="onButtonClick.emit('generate')"
      ></dx-button>
    </div>
  </dxi-toolbar-item>
  <ng-container *ngFor="let cb of customButtons; let idx = index">
    <dxi-toolbar-item
      *ngIf="isButtonVisible('custom')"
      toolbar="bottom"
      [location]="cb.location"
    >
      <div *dxTemplate>
        <dx-button
          [type]="cb.options.type"
          [stylingMode]="cb.options.stylingMode"
          [text]="cb.options.text"
          [hint]="cb.options.hint"
          [icon]="cb.options.icon"
          (onClick)="onButtonClick.emit(cb.name)"
        ></dx-button>
      </div>
    </dxi-toolbar-item>
  </ng-container>
</dx-popup>
