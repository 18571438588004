<div class="cv-link-cell-template" [title]="tooltipText || displayText">
  <ng-container *ngIf="linkEnabled">
    <a
      class="cv-hyperlink cv-hyperlink-hover dx-theme-accent-as-text-color"
      href="javascript:void(0)"
      (click)="onClick.emit()"
    >
      {{ displayText }}
    </a>
  </ng-container>
  <ng-container *ngIf="!linkEnabled">
    {{ displayText }}
  </ng-container>
</div>
