// Angular imports
import { AsyncPipe, DatePipe } from '@angular/common';
import { Injector } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

// Util imports
import { RxjsUtils } from '../_utils/rxjs.utils';
import { Utils } from '../_utils/utils';

// Service imports
import { AuthService } from '../_services/auth.service';
import { ContextService } from '../_services/context.service';
import { ErrorsService } from '../_services/errors.service';
import { GeoLocationService } from '../_services/geolocation.service';
import { HelperService } from '../_services/helper.service';
import { LogsService } from '../_services/logs.service';
import { MessageService } from '../_services/message.service';
import { ScreenService } from '../_services/screen.service';
import { ToastService } from '../_services/toast.service';
import { UserSettingService } from '../_services/user-setting.service';
import { UserService } from '../_services/user.service';

// Constant imports
import { FormatterConstants } from '../_constants/formatter.constants';

// Model imports
import { BaseQueryParameterDto } from '../_models/_base/base-query-parameter.dto';
import { OrganizationResultDto } from '../_models/organization/organization-result.dto';
import { ParticipantInfoResultDto } from '../_models/participant/participant-info-result.dto';
import { UserInfoResultDto } from '../_models/user/user-info-result.dto';

export abstract class BaseComponent {
  currentDate = new Date();
  environment: string;

  dateFormat = FormatterConstants.DATE;
  dateTimeFormat = FormatterConstants.DATETIME;
  numberFormat = FormatterConstants.NUMBER;
  percentFormat = FormatterConstants.PERCENT;

  isLarge = true;
  isMedium = false;
  isSmall = false;
  isXSmall = false;
  isLandscape = false;

  userInfo: UserInfoResultDto;
  participantInfo: ParticipantInfoResultDto;
  organizationInfo: OrganizationResultDto;

  protected readonly async: AsyncPipe;
  protected readonly date: DatePipe;
  protected readonly router: Router;
  protected readonly activatedRoute: ActivatedRoute;
  protected readonly contextService: ContextService;
  protected readonly authService: AuthService;
  protected readonly userService: UserService;
  protected readonly screenService: ScreenService;
  protected readonly helperService: HelperService;
  protected readonly errorService: ErrorsService;
  protected readonly toastService: ToastService;
  protected readonly messageService: MessageService;
  protected readonly userSettingService: UserSettingService;
  protected readonly geoLocationService: GeoLocationService;
  protected readonly logsService: LogsService;

  constructor(protected readonly injector: Injector) {
    this.async = injector.get(AsyncPipe);
    this.date = injector.get(DatePipe);
    this.router = injector.get(Router);
    this.activatedRoute = injector.get(ActivatedRoute);
    this.contextService = injector.get(ContextService);
    this.authService = injector.get(AuthService);
    this.userService = injector.get(UserService);
    this.screenService = injector.get(ScreenService);
    this.helperService = injector.get(HelperService);
    this.errorService = injector.get(ErrorsService);
    this.toastService = injector.get(ToastService);
    this.messageService = injector.get(MessageService);
    this.userSettingService = injector.get(UserSettingService);
    this.geoLocationService = injector.get(GeoLocationService);
    this.logsService = injector.get(LogsService);
    this.screenService.onChanged(this.updateScreenSize.bind(this));
    this.authService.onUserLogin(this.updateUserInfo.bind(this));
    this.authService.onParticipantLogin(this.updateUserInfo.bind(this));

    this.updateScreenSize();
    this.updateUserInfo();
  }

  protected onInit(key: string): void {
    RxjsUtils.subscribes(key, [
      this.contextService.onOrganizationChanged(
        (rec: OrganizationResultDto) => {
          if (
            (Utils.notNullAndDefined(this.userInfo) ||
              Utils.notNullAndDefined(this.participantInfo)) &&
            (Utils.isNullOrUndefined(this.organizationInfo) ||
              (Utils.notNullAndDefined(rec) &&
                Utils.notEquals(
                  this.organizationInfo.organizationId,
                  rec.organizationId
                )))
          ) {
            this.updateOrganizationInfo();
          }
        }
      )
    ]);
  }

  protected onDestroy(key: string): void {
    RxjsUtils.unsubscribe(key);
  }

  protected showSuccess(message: string): void {
    this.toastService.showSuccess(message);
  }

  protected showError(message: string): void {
    this.toastService.showError(message);
  }

  protected showWarning(message: string): void {
    this.messageService.showWarningMessage(`Notification`, message);
  }

  protected showInformation(message: string): Promise<void> {
    return this.messageService.showInformationMessage(
      `Information`,
      message,
      'success'
    );
  }

  protected showConfirmation(message: string): Promise<boolean> {
    return this.messageService.showConfirmMessage(
      `Confirmation`,
      message,
      'success'
    );
  }

  protected updateScreenSize(): void {
    this.isLarge = this.screenService.isLarge();
    this.isMedium = this.screenService.isMedium();
    this.isSmall = this.screenService.isSmall();
    this.isXSmall = this.screenService.isXSmall();
    this.isLandscape = this.screenService.isLandscape();
  }

  protected updateUserInfo(): void {
    this.userInfo = this.userService.getLoggedInUser();
    this.participantInfo = this.userService.getLoggedInParticipant();
    this.environment = null;
    if (
      Utils.notNullOrEmpty(this.userInfo) &&
      Utils.notNullOrEmpty(this.userInfo.environment)
    ) {
      this.environment = this.userInfo.environment.toUpperCase();
    } else if (
      Utils.notNullOrEmpty(this.participantInfo) &&
      Utils.notNullOrEmpty(this.participantInfo.environment)
    ) {
      this.environment = this.participantInfo.environment.toUpperCase();
    }
  }

  protected updateOrganizationInfo(): void {
    this.organizationInfo = this.contextService.getOrganization();
  }

  protected getBaseQueryParameter(clearCache = false): BaseQueryParameterDto {
    const params = new BaseQueryParameterDto();
    params.clearCache = clearCache;
    return params;
  }
}
