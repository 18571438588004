// Angular imports
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';

// Dx imports
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { Column } from 'devextreme/ui/data_grid';

// Util imports
import { DxWidgetUtils } from '../../../_utils/dx-widget.utils';

// Type imports
import { SequenceItemInfo } from '../../../_types/sequence-item-info';

@Component({
  selector: 'app-sequence-editor-grid',
  templateUrl: './sequence-editor-grid.component.html',
  styleUrls: ['./sequence-editor-grid.component.scss']
})
export class SequenceEditorGridComponent<T extends SequenceItemInfo>
  implements OnInit
{
  @ViewChild('dataGrid', { static: false }) dataGrid: DxDataGridComponent;

  @Input() dataSource: Array<T>;
  @Input() columns: Array<Column> = [];
  @Output() onUpdate = new EventEmitter<Array<T>>();
  @Output() onCancel = new EventEmitter<Array<T>>();

  ngOnInit(): void {
    this.columns = [
      {
        dataField: 'sequence',
        dataType: 'number',
        sortIndex: 0,
        sortOrder: 'asc',
        cellTemplate: 'sequenceCellTemplate',
        allowSorting: false,
        allowSearch: false,
        allowFiltering: false
      },
      ...this.columns
    ];
  }

  onSequenceUpdated(dataSource: Array<SequenceItemInfo>): void {
    this.dataGrid.instance.option('dataSource', dataSource);
    DxWidgetUtils.instantRepaint(this.dataGrid);
    this.onUpdate.emit(dataSource as Array<T>);
  }
}
