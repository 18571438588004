// Angular imports
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

// Constant imports
import { IconConstants } from '../../../_constants/icon.constants';

@Component({
  selector: 'app-star-rating-input',
  templateUrl: './star-rating-input.component.html',
  styleUrls: ['./star-rating-input.component.scss']
})
export class StarRatingInputComponent implements OnInit {
  @Input() readOnly = false;
  @Input() stars = 5;
  @Input() size = 1;
  @Input() value: number;
  @Input() calculateRatingType: (
    value: number
  ) => 'normal' | 'default' | 'success' | 'danger' = this.computeRatingType;
  @Output() valueChange = new EventEmitter<number>();

  iconStar = IconConstants.STAR;
  iconStarEmpty = IconConstants.STAR_EMPTY;

  iconStyle = {};
  ratingType: 'normal' | 'default' | 'success' | 'danger' = 'normal';
  ratings: Array<number> = [];

  ngOnInit(): void {
    this.iconStyle = {
      fontSize: `${this.size}rem`,
      padding: `0.5rem`
    };
    let val = 1;
    while (val <= this.stars) {
      this.ratings.push(val++);
    }
    this.ratingType = this.computeRatingType(this.value);
  }

  onRatingChange(rating: number): void {
    this.value = rating;
    this.valueChange.emit(rating);
    this.ratingType = this.calculateRatingType(rating);
  }

  private computeRatingType(
    value: number
  ): 'normal' | 'default' | 'success' | 'danger' {
    let ratingType: 'normal' | 'default' | 'success' | 'danger' = 'normal';
    if (value > 0) {
      ratingType = 'danger';
    }
    if (value > 2) {
      ratingType = 'default';
    }
    if (value > 4) {
      ratingType = 'success';
    }
    return ratingType;
  }
}
