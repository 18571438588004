<dx-box
  width="100%"
  height="100%"
  direction="col"
  [ngClass]="{ 'cv-portrait-content': isDevice }"
>
  <dxi-item
    baseSize="auto"
    [visible]="
      showHeader && (isLoggedIn || isParticipantLoggedIn || isParticipantHelp)
    "
  >
    <div *dxTemplate>
      <app-header
        [isUserLoggedIn]="isLoggedIn"
        [isParticipantLoggedIn]="isParticipantLoggedIn"
      ></app-header>
    </div>
  </dxi-item>
  <dxi-item [ratio]="1">
    <div
      *dxTemplate
      class="cc-theme-background-color"
      [ngClass]="{
        'cv-round-border dx-theme-border-color':
          !isLoggedIn && !isParticipantLoggedIn
      }"
    >
      <ng-container *ngIf="!isLoggedIn && !isParticipantLoggedIn">
        <router-outlet></router-outlet>
      </ng-container>
      <ng-container *ngIf="isLoggedIn">
        <app-home></app-home>
      </ng-container>
      <ng-container *ngIf="isParticipantLoggedIn">
        <router-outlet></router-outlet>
      </ng-container>
    </div>
  </dxi-item>
</dx-box>

<dx-load-panel
  *ngIf="showLoading"
  [visible]="showLoading"
  [indicatorSrc]="imgLoader"
  [shading]="true"
  [width]="128"
  [height]="96"
></dx-load-panel>

<app-message-panel
  *ngIf="showMessage"
  [visible]="showMessage"
  [type]="messageEntry.type"
  [title]="messageEntry.title"
  [message]="messageEntry.message"
  [details]="messageEntry.details"
  (onHidden)="showMessage = false"
></app-message-panel>
