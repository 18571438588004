// Angular imports
import { Directive, HostListener, Input } from '@angular/core';

// Constant imports
import { ActionConstants } from '../../_constants/action.constants';
import { IconConstants } from '../../_constants/icon.constants';

@Directive({
  selector: '[appButtonSend]'
})
export class ButtonSendDirective {
  @Input() text = ActionConstants.SEND;
  @Input() hint = 'Save & Send Email';

  @HostListener('onInitialized', ['$event'])
  onInitialized(e: any): void {
    e.component.option({
      type: 'success',
      text: this.text,
      hint: this.hint,
      icon: IconConstants.SEND
    });
  }
}
