<div class="cv-page-title">
  {{ title }}
  <sup
    *ngIf="helpKey"
    class="dx-theme-accent-as-text-color"
    (click)="onHelpClick.emit(helpKey)"
  >
    <i aria-hidden="true" class="cv-hyperlink" [ngClass]="iconHelp"></i>
  </sup>
</div>
