// Angular imports
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

// 3rd party imports
import { plainToInstance } from 'class-transformer';

// Util imports
import { Utils } from '../_utils/utils';

// Service imports
import { MessageService } from './message.service';

// Model imports
import { ErrorStatusResultDto } from '../_models/common/error-status-result.dto';

@Injectable({ providedIn: 'root' })
export class ErrorsService {
  constructor(private readonly messageService: MessageService) {}

  showApiError(error: HttpErrorResponse): void {
    const apiError = plainToInstance(ErrorStatusResultDto, error.error);
    if (Utils.notNullAndDefined(apiError)) {
      this.messageService.showErrorMessage(
        this.getTitleText(apiError),
        this.getErrorMessage(apiError),
        apiError.details
      );
    } else {
      this.messageService.showErrorMessage(error.name, error.message);
    }
  }

  showUiError(error: Error): void {
    this.messageService.showErrorMessage(
      error.name,
      error.message,
      error.stack
    );
  }

  getTitleText(apiError: ErrorStatusResultDto): string {
    if (
      Utils.isNullOrEmpty(apiError.message) &&
      Utils.notNullAndDefined(apiError.errors)
    ) {
      return `Validation Error`;
    }
    return apiError.title || `Server Error`;
  }

  getErrorMessage(apiError: ErrorStatusResultDto): string {
    let message = apiError.message;
    if (
      Utils.isNullOrEmpty(message) &&
      Utils.notNullAndDefined(apiError.errors)
    ) {
      message = Object.keys(apiError.errors)
        .map((k) => apiError.errors[k].join(','))
        .join('<br />');
    }
    return message;
  }
}
