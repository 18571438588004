// Angular imports
import { Routes } from '@angular/router';

// Constant imports
import { RoutePathConstants } from './_constants/route-path.constants';

export const APP_ROUTES: Routes = [
  {
    path: RoutePathConstants.AUTH,
    loadChildren: () =>
      import('./auth-module/auth.module').then((m) => m.AuthModule)
  },
  {
    path: RoutePathConstants.PARTICIPANT,
    loadChildren: () =>
      import('./participant-module/participant.module').then(
        (m) => m.ParticipantModule
      )
  },
  {
    path: RoutePathConstants.PREVIEW,
    loadChildren: () =>
      import('./preview-module/preview.module').then((m) => m.PreviewModule)
  },
  {
    path: RoutePathConstants.SUMMARY,
    loadChildren: () =>
      import('./user-event-module/user-event.module').then(
        (m) => m.UserEventModule
      )
  },
  {
    path: RoutePathConstants.ADMINISTRATION,
    loadChildren: () =>
      import('./admin-module/admin.module').then((m) => m.AdminModule)
  },
  {
    path: RoutePathConstants.ELECTION,
    loadChildren: () =>
      import('./election-module/election.module').then((m) => m.ElectionModule)
  },
  {
    path: RoutePathConstants.SURVEY,
    loadChildren: () =>
      import('./survey-module/survey.module').then((m) => m.SurveyModule)
  },
  {
    path: RoutePathConstants.AMENDMENT,
    loadChildren: () =>
      import('./amendment-module/amendment.module').then(
        (m) => m.AmendmentModule
      )
  },
  {
    path: RoutePathConstants.UNKNOWN,
    pathMatch: 'full',
    redirectTo: `/${RoutePathConstants.SUMMARY}`
  }
];
