// Angular imports
import { LayoutModule } from '@angular/cdk/layout';
import { PlatformModule } from '@angular/cdk/platform';
import { AsyncPipe, DatePipe } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import {
  APP_INITIALIZER,
  ErrorHandler,
  NgModule,
  SecurityContext
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { Router, RouterModule } from '@angular/router';

// 3rd party imports
import { JwtHelperService, JwtModule } from '@auth0/angular-jwt';
import * as Sentry from '@sentry/angular-ivy';

// Dx imports
import { DxBoxModule } from 'devextreme-angular/ui/box';
import { DxLoadPanelModule } from 'devextreme-angular/ui/load-panel';
import dxDateBox from 'devextreme/ui/date_box';
import dxDropDownButton from 'devextreme/ui/drop_down_button';
import dxForm from 'devextreme/ui/form';
import dxScrollView from 'devextreme/ui/scroll_view';
import dxScrollable from 'devextreme/ui/scroll_view/ui.scrollable';
import dxSelectBox from 'devextreme/ui/select_box';
import dxSwitch from 'devextreme/ui/switch';
import dxTextArea from 'devextreme/ui/text_area';
import dxTextBox from 'devextreme/ui/text_box';

// Route imports
import { APP_ROUTES } from './app.routing';

// Interceptor imports
import { CachingInterceptor } from './_interceptors/caching.interceptor';
import { CredentialsInterceptor } from './_interceptors/credentials.interceptor';
import { ErrorsHandler } from './_interceptors/errors.interceptor';
import { LoaderInterceptor } from './_interceptors/loader.interceptor';

// Service imports
import { ScreenService } from './_services/screen.service';

// Module imports
import { SharedModule } from './shared-module/shared.module';
import { WidgetsModule } from './widgets-module/widgets.module';

// Constant imports
import { FormatterConstants } from './_constants/formatter.constants';
import { StorageKeyConstants } from './_constants/storage-key.constants';
import { TimerConstants } from './_constants/timer.constants';

// Component imports
import { AppComponent } from './app.component';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    LayoutModule,
    PlatformModule,
    RouterModule.forRoot(APP_ROUTES),
    JwtModule.forRoot({
      config: {
        tokenGetter: () =>
          localStorage.getItem(StorageKeyConstants.USER_TOKEN) ||
          localStorage.getItem(StorageKeyConstants.PARTCIPANT_TOKEN),
        allowedDomains: [
          'localhost:5001',
          'localhost:44361',
          'localapi.ciravote.com',
          'devapi.ciravote.com',
          'sandboxapi.ciravote.com',
          'qaapi.ciravote.com',
          'api.ciravote.com'
        ]
      }
    }),
    DxBoxModule,
    DxLoadPanelModule,
    SharedModule,
    WidgetsModule
  ],
  bootstrap: [AppComponent],
  providers: [
    JwtHelperService,
    AsyncPipe,
    DatePipe,
    { provide: 'CC_TOKEN_RENEWAL_API', useValue: '/api/auth/renew' },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CredentialsInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CachingInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true
    },
    {
      provide: Sentry.TraceService,
      deps: [Router]
    },
    {
      provide: ErrorHandler,
      useClass: ErrorsHandler
    }
  ]
})
export class AppModule {
  constructor(
    private readonly sanitizer: DomSanitizer,
    private readonly screenService: ScreenService
  ) {
    dxDateBox.defaultOptions({
      options: {
        min: new Date('2000-01-01'),
        placeholder: FormatterConstants.MOMENT_DATE,
        useMaskBehavior: true
      }
    });
    dxDropDownButton.defaultOptions({
      options: {
        dropDownOptions: { width: 'auto' }
      }
    });
    dxForm.defaultOptions({
      options: {
        showColonAfterLabel: true,
        labelLocation: 'top'
      }
    });
    dxSelectBox.defaultOptions({
      options: {
        searchEnabled: true
      }
    });
    dxSwitch.defaultOptions({
      options: {
        switchedOnText: 'Yes',
        switchedOffText: 'No'
      }
    });
    dxTextArea.defaultOptions({
      options: {
        onFocusOut: this.textValueChanged.bind(this),
        onPaste: this.textValueChanged.bind(this),
        inputAttr: { autocomplete: 'off' }
      }
    });
    dxTextBox.defaultOptions({
      options: {
        onFocusOut: this.textValueChanged.bind(this),
        onPaste: this.textValueChanged.bind(this),
        inputAttr: { autocomplete: 'off' }
      }
    });
    this.screenService.onChanged(() => {
      const isLarge = this.screenService.isLarge();
      dxScrollView.defaultOptions({
        options: {
          useNative: false,
          bounceEnabled: false,
          showScrollbar: isLarge ? 'onHover' : 'always'
        }
      });
      dxScrollable.defaultOptions({
        options: {
          useNative: false,
          bounceEnabled: false,
          showScrollbar: isLarge ? 'onHover' : 'always'
        }
      });
    }, TimerConstants.inSeconds(1));
  }

  textValueChanged(event): void {
    const opt = event.component.option('elementAttr') || { sanitize: false };
    const value = event.component.option('value');
    if (typeof value === 'string') {
      let plainText = value;
      if (!!opt && !!opt.sanitize) {
        plainText = this.sanitizer.sanitize(SecurityContext.HTML, value);
        plainText = this.sanitizer.sanitize(SecurityContext.URL, plainText);
        plainText = this.getSafeText(plainText);
      }
      if (value.trim() !== value || plainText !== value) {
        event.component.option('value', plainText.trim());
      }
    }
  }

  getSafeText(plain: string): string {
    return plain
      .replace(/&#10;/gi, '\r')
      .replace(/&#34;/gi, '\n')
      .replace('unsafe:', '');
  }
}
