// Angular imports
import {
  AfterViewInit,
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';

// Dx imports
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { DxDropDownBoxComponent } from 'devextreme-angular/ui/drop-down-box';
import { DxTextBoxComponent } from 'devextreme-angular/ui/text-box';
import { Column } from 'devextreme/ui/data_grid';

// Util imports
import { Utils } from '../../../_utils/utils';

// Type imports
import { SelectionGridEvent } from '../../../_types/selection-grid-event';

@Component({
  selector: 'app-selection-grid',
  templateUrl: './selection-grid.component.html',
  styleUrls: ['./selection-grid.component.scss']
})
export class SelectionGridComponent<T, D> implements OnInit, AfterViewInit {
  @ContentChild('checkCellTemplate', { static: false })
  checkCellTemplate: TemplateRef<any>;

  @ViewChild('dropDownBox', { static: false })
  dropDownBox: DxDropDownBoxComponent;
  @ViewChild('textBox', { static: false })
  textBox: DxTextBoxComponent;
  @ViewChild('dataGrid', { static: false })
  dataGrid: DxDataGridComponent;

  @Input() name: string;
  @Input() isRequired = false;
  @Input() validationGroup: string;
  @Input() dropDownOptions: { width?: string; minWidth?: string } = {
    width: 'auto',
    minWidth: '150px'
  };
  @Input() showClearButton = true;
  @Input() readOnly = false;
  @Input() placeholder = `Select a value...`;
  @Input() keyExpr = `key`;
  @Input() valueExpr = `key`;
  @Input() displayExpr = `displayText`;
  @Input() dataSource: Array<T>;
  @Input() columns: Array<Column>;
  @Input() value: Array<D>;
  @Input() isSelectable: (record: T) => boolean;
  @Output() onValueChanged = new EventEmitter<SelectionGridEvent<T, D>>();
  @Output() onContentReady = new EventEmitter<void>();

  isOpened: boolean;
  focusIndex: number;
  displayText: string;

  constructor() {
    this.validationCallback = this.validationCallback.bind(this);
  }

  ngOnInit(): void {
    this.focusIndex = -1;
    this.isOpened = false;
  }

  ngAfterViewInit(): void {
    this.onContentReady.emit();
  }

  validationCallback(e: any): boolean {
    const isValid =
      Utils.isFalse(this.isRequired) ||
      Utils.isTrue(this.readOnly) ||
      (Utils.isTrue(this.isRequired) &&
        Array.isArray(e.value) &&
        Utils.notNullOrEmpty(e.value) &&
        Utils.notNullOrEmpty(e.value.filter((v) => Utils.notNullOrEmpty(v))));
    return isValid;
  }

  onBoxOptionChanged(e: any): void {
    if (Utils.equalsIgnoreCase(e.name, 'value')) {
      if (Array.isArray(e.value)) {
        this.fireValueChanged({
          selectedKeys: e.value,
          selectedRows: this.dataSource.filter((r) =>
            Utils.isInList(r[this.valueExpr], e.value)
          )
        });
        if (Utils.notNullOrEmpty(e.value)) {
          this.closeDropDownContent();
        }
      } else {
        this.fireValueChanged({
          selectedKeys: [],
          selectedRows: []
        });
        this.openDropDownContent();
        this.displayText = e.value;
      }
    } else if (
      Utils.equalsIgnoreCase(e.name, 'text') &&
      Utils.notNullOrEmpty(e.value)
    ) {
      this.displayText = e.value;
    } else if (Utils.equalsIgnoreCase(e.name, 'opened')) {
      this.focusIndex = -1;
      this.isOpened = e.value;
      setTimeout(() => this.textBox.instance.focus(), 150);
    }
  }

  onFieldKeyDown(e: any): void {
    if (Utils.isFalse(this.readOnly)) {
      const eventKey = String(e.event.key).toLowerCase();
      if (Utils.isInList(eventKey, ['arrowdown', 'arrowup'])) {
        this.openDropDownContent();
        setTimeout(() => this.dataGrid.instance.focus(), 100);
      } else if (Utils.equalsIgnoreCase(eventKey, 'escape')) {
        this.closeDropDownContent();
      } else if (
        Utils.equalsIgnoreCase(eventKey, 'backspace') ||
        Utils.equals(eventKey.length, 1)
      ) {
        this.openDropDownContent();
      }
    }
  }

  onGridRowPrepared(e: any): void {
    if (
      Utils.notNullAndDefined(this.isSelectable) &&
      Utils.notNullAndDefined(e.data) &&
      Utils.isFalse(this.isSelectable(e.data))
    ) {
      e.rowElement.classList.add('cc-theme-row-disabled');
    }
  }

  onGridKeyDown(e: any): void {
    const eventKey = String(e.event.key).toLowerCase();
    if (Utils.equalsIgnoreCase(eventKey, 'enter')) {
      this.dataGrid.instance.selectRowsByIndexes([this.focusIndex]);
    }
  }

  onGridRowClick(e: any): void {
    if (Utils.isTrue(e.isSelected)) {
      this.closeDropDownContent();
    }
  }

  onGridSelectionChanged(e: any): void {
    if (Utils.notNullAndDefined(this.isSelectable)) {
      const deselectRowKeys: number[] = [];
      const dataGrid = e.component;
      e.selectedRowsData.forEach((record: T) => {
        if (Utils.isFalse(this.isSelectable(record)))
          deselectRowKeys.push(dataGrid.keyOf(record));
      });
      if (deselectRowKeys.length) {
        dataGrid.deselectRows(deselectRowKeys);
      }
    }
  }

  private openDropDownContent(): boolean {
    if (Utils.isFalse(this.isOpened)) {
      this.dropDownBox.instance.open();
      this.isOpened = true;
    }
    return Utils.isTrue(this.isOpened);
  }

  private closeDropDownContent(): boolean {
    if (Utils.isTrue(this.isOpened)) {
      this.dropDownBox.instance.close();
      this.isOpened = false;
    }
    return Utils.isFalse(this.isOpened);
  }

  private fireValueChanged(value: SelectionGridEvent<T, D>): void {
    setTimeout(() => this.onValueChanged.emit(value), 100);
  }
}
