// Angular imports
import { Injectable } from '@angular/core';

// Rxjs imports
import { BehaviorSubject, delay, Subscription, tap } from 'rxjs';

// Dx imports
import themes from 'devextreme/ui/themes';

// Util imports
import { Utils } from '../_utils/utils';

// Service imports
import { HelperService } from './helper.service';
import { UserSettingService } from './user-setting.service';

// Constant imports
import { AppDefaultConstants } from '../_constants/app-default.constants';
import { StorageKeyConstants } from '../_constants/storage-key.constants';
import { ThemeConstants } from '../_constants/theme.constants';

// Enum imports
import { UserSettingCategory } from '../_enums/user-setting-category.enum';

// Type imports
import { ThemeColor } from '../_types/theme-color';

@Injectable({ providedIn: 'root' })
export class ThemeService {
  private readonly _subjectThemeChanged = new BehaviorSubject<ThemeColor>(null);
  private _themeName = '';

  constructor(
    private readonly helperService: HelperService,
    private readonly userSettingService: UserSettingService
  ) {
    this.helperService.onPreferencesChanged(
      this.setApplicationTheme.bind(this),
      500
    );
  }

  isDarkTheme(): boolean {
    return Utils.containsIgnoreCase(this._themeName, 'dark');
  }

  onApplicationThemeChanged(
    handler: (themeColor: ThemeColor) => void,
    delayMillis = 0
  ): Subscription {
    return this._subjectThemeChanged
      .pipe(delay(delayMillis), tap(handler))
      .subscribe();
  }

  getApplicationTheme(): ThemeColor {
    return this._subjectThemeChanged.getValue();
  }

  setApplicationTheme(): void {
    // Get user preferred theme color from local storage.
    // If not specified use default theme color for the application
    let themeName = this.userSettingService.getItem(
      StorageKeyConstants.USER_THEME,
      UserSettingCategory.PREF
    );
    if (!themeName) {
      this.userSettingService.setItem(
        StorageKeyConstants.USER_THEME,
        AppDefaultConstants.THEME_NAME,
        UserSettingCategory.PREF
      );
      themeName = AppDefaultConstants.THEME_NAME;
    }

    const themeColor = ThemeConstants.ThemeColors.find(
      (t) => t.name === themeName
    );
    if (Utils.notNullAndDefined(themeColor)) {
      this._themeName = themeName;
      this.setCustomStyleSheet(themeColor.customName);
      themes.current(themeColor.themeName);
      this._subjectThemeChanged.next(themeColor);
    }
  }

  private setCustomStyleSheet(styleName: string) {
    const themeLinks: HTMLCollectionOf<HTMLLinkElement> =
      document.getElementsByTagName('link');
    const customThemes: HTMLLinkElement[] = [].slice.call(themeLinks);
    customThemes.forEach((t: HTMLLinkElement) => {
      // Remove all existing custom style sheet links
      const dataRel = t.attributes.getNamedItem('data-rel');
      if (dataRel && dataRel.value === 'cc-theme' && t.rel === 'stylesheet') {
        t.remove();
      }

      // Add the new custom style sheet based on input style name
      if (t.rel === 'cc-theme') {
        const dataTheme = t.attributes.getNamedItem('data-theme');
        if (dataTheme?.value === styleName) {
          /* Create link document */
          const link = document.createElement('link');
          link.type = 'text/css';
          link.rel = 'stylesheet';
          link.setAttribute('data-theme', styleName);
          link.setAttribute('data-rel', 'cc-theme');
          link.href = t.href;
          /* Append link to the tag name */
          document.getElementsByTagName('head')[0].appendChild(link);
        }
      }
    });
  }
}
