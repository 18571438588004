import { Transform } from 'class-transformer';
import { GeoLocationUtils } from '../../_utils/geo-location.utils';
import { Utils } from '../../_utils/utils';

export class OrganizationRawDto {
  organizationId: number;
  organizationTypeId: number;
  @Transform(GeoLocationUtils.toJsonCityId, { toPlainOnly: true })
  cityId: number;
  stateId: number;

  legalName: string;
  referredName: string;
  contactFirstName: string;
  contactLastName: string;
  contactEmail: string;
  contactPhone: string;
  cityName: string;
  postalCode: string;

  get name(): string {
    return this.referredName || this.legalName;
  }

  get contactText(): string {
    return Utils.notNullOrEmpty(this.contactFirstName)
      ? `${this.contactFirstName} ${this.contactLastName || ''}`
      : '';
  }
}
