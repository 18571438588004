// Angular imports
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Injector,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';

// Dx imports
import { DxFileUploaderComponent } from 'devextreme-angular/ui/file-uploader';
import { DxTextBoxComponent } from 'devextreme-angular/ui/text-box';

// Util imports
import { FileUtils } from '../../../_utils/file.utils';
import { Utils } from '../../../_utils/utils';

// Component imports
import { BaseComponent } from '../../../_base/base.component';

// Constant imports
import { ActionConstants } from '../../../_constants/action.constants';

// Model imports
import { FileInfoParameterDto } from '../../../_models/common/file-info-parameter.dto';

@Component({
  selector: 'app-file-input',
  templateUrl: './file-input.component.html',
  styleUrls: ['./file-input.component.scss']
})
export class FileInputComponent
  extends BaseComponent
  implements OnInit, AfterViewInit
{
  @ViewChild('textbox', { static: false }) textbox: DxTextBoxComponent;
  @ViewChild('uploader', { static: false }) uploader: DxFileUploaderComponent;

  @Input() validationGroup: string;
  @Input() fixedFilename: string;
  @Input() placeholder: string;
  @Input() maxFileSizeInMB = 10;
  @Input() isRequired = false;
  @Input() readOnly = false;
  @Input() deleteEnabled = true;
  @Input() downloadEnabled = true;
  @Input() allowedExtensions: Array<string>;
  @Input() browseCallback: () => Promise<boolean> = () => Promise.resolve(true);
  @Input() fileInfo: FileInfoParameterDto;
  @Output() fileInfoChange = new EventEmitter<FileInfoParameterDto>();
  @Output() onView = new EventEmitter<FileInfoParameterDto>();
  @Output() onClear = new EventEmitter<FileInfoParameterDto>();

  maxFileSize: number;
  acceptedMineTypes: string;

  constructor(protected override readonly injector: Injector) {
    super(injector);
  }

  ngOnInit(): void {
    this.fileInfo = this.fileInfo || new FileInfoParameterDto();
    this.placeholder = this.placeholder || 'Select File';
    this.allowedExtensions = this.allowedExtensions || ['.pdf'];
    this.acceptedMineTypes = this.allowedExtensions
      .map((r) => FileUtils.getMineType(r))
      .join(',');
    this.maxFileSize = FileUtils.getSizeInMB(this.maxFileSizeInMB);
  }

  ngAfterViewInit(): void {
    if (Utils.notNullOrEmpty(this.fileInfo.filename)) {
      this.toggleButtonsVisible(true);
    }
  }

  onDownloadClick(): void {
    if (Utils.isTrue(this.downloadEnabled)) {
      if (Utils.notNullOrEmpty(this.fileInfo.fileDownloadUrl)) {
        this.helperService
          .getFileBlobFromUrl(this.fileInfo.fileDownloadUrl)
          .subscribe((blob: Blob) =>
            FileUtils.downloadFile(blob, this.fileInfo.filename)
          );
      } else if (Utils.notNullOrEmpty(this.fileInfo.fileBase64String)) {
        FileUtils.downloadFile(
          FileUtils.getBlob(
            this.fileInfo.fileBase64String,
            this.fileInfo.filename
          ),
          this.fileInfo.filename
        );
      }
    }
  }

  onClearClick(): void {
    this.onClear.emit(this.fileInfo);
    if (Utils.notNullAndDefined(this.fileInfo.fileId)) {
      this.fileInfo.filename = null;
      this.fileInfo.crudAction = ActionConstants.DELETE;
    } else {
      this.fileInfo = new FileInfoParameterDto();
    }
    this.fileInfoChange.emit(this.fileInfo);
    this.toggleButtonsVisible(false);
  }

  onBrowseClick(): void {
    this.browseCallback().then((flag: boolean) => {
      if (Utils.isTrue(flag) && Utils.notNullAndDefined(this.fileInfo)) {
        const uploader: any = this.uploader.instance;
        uploader._isCustomClickEvent = true;
        uploader._$fileInput[0].click();
      }
    });
  }

  onFileChanged(e: any): void {
    const selectedFile = e.value[0] as File;
    if (selectedFile) {
      const validSize = selectedFile.size <= this.maxFileSize;
      const validExt = Utils.isInList(
        `.${FileUtils.getFileExt(selectedFile.name)}`,
        this.allowedExtensions
      );
      if (validSize && validExt) {
        FileUtils.getBase64(selectedFile).subscribe((base64String: string) => {
          const uploader: any = this.uploader.instance;
          if (Utils.notNullAndDefined(this.fileInfo)) {
            this.fileInfo.filename = selectedFile.name;
            this.fileInfo.fileBase64String = base64String;
            this.fileInfo.crudAction = Utils.isNullOrEmpty(this.fileInfo.fileId)
              ? ActionConstants.ADD
              : ActionConstants.EDIT;
            this.fileInfoChange.emit(this.fileInfo);
            this.toggleButtonsVisible(true);
          }
          uploader.reset();
        });
      } else {
        this.uploader.instance.reset();
        if (Utils.isFalse(validSize)) {
          this.messageService.showErrorMessage(
            `Invalid File Size`,
            `File size must be less than ${this.maxFileSizeInMB} MB`
          );
        }
        if (Utils.isFalse(validExt)) {
          this.messageService.showErrorMessage(
            `Invalid File Type`,
            `File must be of type ${this.allowedExtensions.join(', ')}`
          );
        }
      }
    }
  }

  private toggleButtonsVisible(flag: boolean): void {
    const actionsButtons = Utils.isFalse(this.readOnly)
      ? [ActionConstants.VIEW, ActionConstants.DOWNLOAD, ActionConstants.DELETE]
      : [ActionConstants.VIEW, ActionConstants.DOWNLOAD];
    actionsButtons.forEach((name: string) => {
      const button = this.textbox.instance.getButton(name);
      switch (name) {
        case ActionConstants.VIEW:
          button.option({
            visible:
              flag &&
              (Utils.isTrue(this.readOnly) ||
                Utils.isFalse(this.downloadEnabled))
          });
          break;
        case ActionConstants.DOWNLOAD:
          button.option({
            visible: flag && Utils.isTrue(this.downloadEnabled)
          });
          break;
        case ActionConstants.DELETE:
          button.option({ visible: flag && Utils.isTrue(this.deleteEnabled) });
          break;
      }
    });
  }
}
