// Angular imports
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';

// Dx imports
import { DxTreeViewComponent } from 'devextreme-angular/ui/tree-view';

// Util imports
import { DxWidgetUtils } from '../../../_utils/dx-widget.utils';
import { Utils } from '../../../_utils/utils';

// Constant imports
import { IconConstants } from '../../../_constants/icon.constants';

// Type imports
import { NavigationEntry } from '../../../_types/navigation-entry';

@Component({
  selector: 'app-navigation-tree',
  templateUrl: './navigation-tree.component.html',
  styleUrls: ['./navigation-tree.component.scss']
})
export class NavigationTreeComponent implements OnChanges {
  @ViewChild(DxTreeViewComponent, { static: false })
  treeView: DxTreeViewComponent;

  @Input() dataSource: Array<NavigationEntry>;
  @Input() selectedItem: NavigationEntry;
  @Input() isOpened: boolean;
  @Output() onItemExpand = new EventEmitter<NavigationEntry>();
  @Output() onItemClick = new EventEmitter<NavigationEntry>();

  iconArrowRight = IconConstants.ANGLE_ARROW_RIGHT;
  iconArrowDown = IconConstants.ANGLE_ARROW_DOWN;

  ngOnChanges(changes: SimpleChanges): void {
    if (Utils.notNullAndDefined(changes['isOpened'])) {
      if (Utils.isTrue(this.isOpened)) {
        this.onItemExpand.emit(this.selectedItem);
        if (DxWidgetUtils.hasInstance(this.treeView)) {
          this.treeView.instance.expandItem(this.selectedItem.id);
        }
      } else if (DxWidgetUtils.hasInstance(this.treeView)) {
        this.treeView.instance.collapseAll();
      }
    }
    if (
      Utils.notNullAndDefined(changes['selectedItem']) &&
      Utils.notNullAndDefined(this.selectedItem) &&
      DxWidgetUtils.hasInstance(this.treeView)
    ) {
      const node = this.treeView.instance.getSelectedNodes()[0];
      if (
        Utils.notNullAndDefined(node) &&
        Utils.notEqualsIgnoreCase(node.key, this.selectedItem.id)
      ) {
        this.onItemExpand.emit(this.selectedItem);
        this.treeView.instance.expandItem(this.selectedItem.parentId);
        // Collpase the currently selected node of different parent
        if (
          Utils.notNullAndDefined(node.parent) &&
          Utils.notEqualsIgnoreCase(node.parent.key, this.selectedItem.parentId)
        ) {
          this.treeView.instance.collapseItem(node.parent.key);
        }
        this.treeView.instance.selectItem(this.selectedItem.id);
      }
    }
  }

  isSelected(entry: NavigationEntry): boolean {
    return (
      Utils.notNullAndDefined(this.selectedItem) &&
      Utils.isNullOrEmpty(this.selectedItem.items) &&
      Utils.equalsIgnoreCase(entry.id, this.selectedItem.id)
    );
  }

  isTopLevel(entry: NavigationEntry): boolean {
    return Utils.isInList(
      entry.id,
      this.dataSource.map((r) => r.id)
    );
  }

  onNavItemClick(entry: NavigationEntry): void {
    if (Utils.isNullOrEmpty(entry.items)) {
      if (
        Utils.notNullAndDefined(this.selectedItem) &&
        Utils.notEqualsIgnoreCase(entry.parentId, this.selectedItem.parentId)
      ) {
        this.treeView.instance.collapseItem(this.selectedItem.parentId);
      }
      this.selectedItem = entry;
      this.onItemClick.emit(entry);
    } else {
      this.onItemExpand.emit(entry);
    }
  }
}
