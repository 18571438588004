// Angular imports
import { Directive, HostListener, Input } from '@angular/core';

// Dx imports
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { exportDataGrid as excelExporter } from 'devextreme/excel_exporter';
import { exportDataGrid as pdfExporter } from 'devextreme/pdf_exporter';

// 3rd party imports
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
import { jsPDF } from 'jspdf';

// Util imports
import { DateUtils } from '../../_utils/date.utils';
import { Utils } from '../../_utils/utils';

// Service imports
import { HelperService } from '../../_services/helper.service';

// Type imports
import { DataGridExportOptions } from '../../_types/data-grid-export-option';
import { DataGridExportTitle } from '../../_types/data-grid-export-title';
import { DxItemOption } from '../../_types/dx-item-option';

// Constant imports
import { ActionConstants } from '../../_constants/action.constants';
import { FormatterConstants } from '../../_constants/formatter.constants';
import { IconConstants } from '../../_constants/icon.constants';

@Directive({
  selector: '[appDataGridExport]'
})
export class DataGridExportDirective {
  @Input() text = ActionConstants.EXPORT;
  @Input() hint = 'Export Data To';
  @Input() dataGrid: DxDataGridComponent;
  @Input() options: DataGridExportOptions = {};
  @Input() onExportFn: (
    itemData: DxItemOption,
    doExport: (rec: DxItemOption, filename: string) => void
  ) => void;

  currentDate = new Date();
  pageMargin = 32;

  constructor(private readonly helperService: HelperService) {}

  @HostListener('onInitialized', ['$event'])
  onInitialized(e: any): void {
    setTimeout(() => {
      e.component.option({
        text: this.text,
        hint: this.hint,
        icon: IconConstants.EXPORT,
        items: [
          { text: ActionConstants.EXPORT_PDF, icon: IconConstants.EXPORT_PDF },
          { text: ActionConstants.EXPORT_XLS, icon: IconConstants.EXPORT_XLS },
          { text: ActionConstants.EXPORT_CSV, icon: IconConstants.EXPORT_CSV }
        ],
        dropDownOptions: { width: 'auto' },
        onItemClick: (e: any) =>
          this.onExportFn(e.itemData, this.doExport.bind(this))
      });
    }, 10);
  }

  private doExport(rec: DxItemOption, filename: string): void {
    this.options.filename = filename;
    if (Utils.equalsIgnoreCase(rec.text, ActionConstants.EXPORT_PDF)) {
      this.exportToPdf();
    } else if (Utils.equalsIgnoreCase(rec.text, ActionConstants.EXPORT_XLS)) {
      this.exportToExcel();
    } else if (Utils.equalsIgnoreCase(rec.text, ActionConstants.EXPORT_CSV)) {
      this.exportToCsv();
    }
  }

  private exportToPdf(): void {
    this.helperService.showLoading();
    this.currentDate = new Date();
    const pdfDoc = new jsPDF({
      orientation: this.options.orientation,
      unit: 'px',
      compress: true
    });
    const topY = Utils.notNullOrEmpty(this.options.titles)
      ? this.options.titles
          .map((r) => r.size + r.lineSpace * 2) // Line Space * 2 to add space both on top & bottom
          .reduce((pv, cv) => pv + cv)
      : 0;
    // Add Grid Data
    pdfExporter({
      jsPDFDocument: pdfDoc,
      component: this.dataGrid.instance,
      margin: {
        top: Math.max(topY, this.pageMargin),
        left: this.pageMargin,
        right: this.pageMargin,
        bottom: this.pageMargin
      },
      loadPanel: { enabled: false },
      columnWidths: this.options.columnWidths,
      customDrawCell: ({ gridCell, pdfCell }) =>
        this.customPdfCell(gridCell, pdfCell),
      customizeCell: ({ gridCell, pdfCell }) =>
        this.customizeCell(gridCell, pdfCell, true)
    })
      .then(() => {
        this.addPdfHeaderFooter(pdfDoc);
        pdfDoc.save(`${this.options.filename}.pdf`);
      })
      .finally(() => this.helperService.hideLoading());
  }

  private exportToExcel(): void {
    this.exportToTabular(true);
  }

  private exportToCsv(): void {
    this.exportToTabular(false);
  }

  private exportToTabular(isExcel: boolean): void {
    this.helperService.showLoading();
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('ExportedSheet');
    excelExporter({
      component: this.dataGrid.instance,
      worksheet,
      loadPanel: { enabled: false },
      customizeCell: ({ gridCell, excelCell }) =>
        this.customizeCell(gridCell, excelCell, false)
    }).then(() => {
      let writer = workbook.csv.writeBuffer();
      let filename = `${this.options.filename}.csv`;
      if (Utils.isTrue(isExcel)) {
        writer = workbook.xlsx.writeBuffer();
        filename = `${this.options.filename}.xlsx`;
      }
      writer
        .then((buffer) => {
          saveAs(
            new Blob([buffer], { type: 'application/octet-stream' }),
            filename
          );
        })
        .finally(() => this.helperService.hideLoading());
    });
  }

  private customPdfCell(gridCell: any, outCell: any) {
    if (Utils.equals(gridCell.column.visibleIndex, 0)) {
      if (Utils.equalsIgnoreCase(gridCell.rowType, 'header')) {
        outCell.font.size = 10;
        outCell.font.style = 'bold';
      } else if (Utils.equalsIgnoreCase(gridCell.rowType, 'data')) {
        outCell.font.style = 'normal';
      }
    }
  }

  private customizeCell(gridCell: any, outCell: any, isPdfCell: boolean): void {
    if (isPdfCell) {
      // Color related styles only works here
      outCell.textColor = '#000';
      outCell.wordWrapEnabled = true;
      if (Utils.equalsIgnoreCase(gridCell.rowType, 'header')) {
        outCell.backgroundColor = '#deebf7';
      } else if (Utils.equalsIgnoreCase(gridCell.rowType, 'data')) {
        outCell.backgroundColor = '#fff';
      }
    }
    if (Utils.equalsIgnoreCase(gridCell.rowType, 'data')) {
      if (Utils.equalsIgnoreCase(gridCell.column.dataType, 'boolean')) {
        const outValue = Utils.isTrue(gridCell.value) ? 'Yes' : 'No';
        if (isPdfCell) {
          outCell.text = outValue;
        } else {
          outCell.value = outValue;
        }
      }
    }
  }

  private addPdfHeaderFooter(pdfDoc: jsPDF): void {
    // Add Header & Footer
    const noOfPages = pdfDoc.getNumberOfPages();
    const pageWidth = pdfDoc.internal.pageSize.getWidth();
    const pageHeight = pdfDoc.internal.pageSize.getHeight();
    const headerX = pageWidth / 2;
    const footerLeftX = 32;
    const footerRightX = pageWidth - 32;
    const footerY = pageHeight - 24;
    const dateTimeText = DateUtils.dateToString(
      this.currentDate,
      FormatterConstants.MOMENT_DATETIME
    );
    for (let pageNo = 1; pageNo <= noOfPages; pageNo++) {
      pdfDoc.setPage(pageNo);
      // Add Title
      let headerY = 0;
      this.options.titles.forEach((title: DataGridExportTitle) => {
        headerY += title.size + title.lineSpace;
        pdfDoc.setFontSize(title.size);
        pdfDoc.setTextColor('#000');
        pdfDoc.setFont(pdfDoc.getFont().fontName, title.style);
        pdfDoc.text(title.text, headerX, headerY, {
          align: 'center',
          baseline: 'middle'
        });
      });
      // Add Footer
      pdfDoc.setFontSize(9);
      pdfDoc.setTextColor('#666');
      pdfDoc.setFont(pdfDoc.getFont().fontName, 'italic', 'normal');
      pdfDoc.text(dateTimeText, footerLeftX, footerY, {
        align: 'left',
        baseline: 'middle'
      });
      pdfDoc.text(`Page ${pageNo} of ${noOfPages}`, footerRightX, footerY, {
        align: 'right',
        baseline: 'middle'
      });
    }
  }
}
