import { Type } from 'class-transformer';

export class LogsContentResultDto {
  level: string;
  message: string;
  source: string;
  details: string;

  @Type(() => Date)
  timestamp: Date;
}
