// Angular imports
import {
  Directive,
  EventEmitter,
  HostListener,
  Input,
  Output
} from '@angular/core';

// Util imports
import { Utils } from '../../_utils/utils';

// Constant imports
import { IconConstants } from '../../_constants/icon.constants';
import { TimerConstants } from '../../_constants/timer.constants';
import { ActionConstants } from '../../_constants/action.constants';

// Model imports
import { FileInfoParameterDto } from '../../_models/common/file-info-parameter.dto';

@Directive({
  selector: '[appTextBoxFileInput]'
})
export class TextBoxFileInputDirective {
  @Input() isReadOnly = false;
  @Input() deleteEnabled = false;
  @Input() downloadEnabled = true;
  @Input() fileInfo: FileInfoParameterDto;
  @Output() onViewClick = new EventEmitter<any>();
  @Output() onDownloadClick = new EventEmitter<any>();
  @Output() onClearClick = new EventEmitter<any>();
  @Output() onBrowseClick = new EventEmitter<any>();

  @HostListener('onInitialized', ['$event'])
  onInitialized(e: any): void {
    e.component.option({
      inputAttr: { class: 'cv-file-readonly-input' },
      buttons: [
        {
          name: ActionConstants.VIEW,
          location: 'after',
          options: {
            type: 'default',
            stylingMode: 'text',
            hint: ActionConstants.VIEW,
            icon: IconConstants.VIEW,
            elementAttr: { fileInfo: this.fileInfo },
            visible:
              (Utils.isTrue(this.isReadOnly) ||
                Utils.isFalse(this.downloadEnabled)) &&
              Utils.notNullOrEmpty(this.fileInfo) &&
              Utils.notNullOrEmpty(this.fileInfo.filename),
            onClick: (e: any) => this.onViewClick.emit(e)
          }
        },
        {
          name: ActionConstants.DOWNLOAD,
          location: 'after',
          options: {
            type: 'default',
            stylingMode: 'text',
            hint: ActionConstants.DOWNLOAD,
            icon: IconConstants.DOWNLOAD,
            elementAttr: { fileInfo: this.fileInfo },
            visible:
              Utils.isTrue(this.downloadEnabled) &&
              Utils.notNullOrEmpty(this.fileInfo) &&
              Utils.notNullOrEmpty(this.fileInfo.filename),
            onClick: (e: any) => this.onDownloadClick.emit(e)
          }
        },
        {
          name: ActionConstants.DELETE,
          location: 'after',
          options: {
            type: 'danger',
            stylingMode: 'text',
            hint: ActionConstants.DELETE,
            icon: IconConstants.DELETE,
            elementAttr: { fileInfo: this.fileInfo },
            visible:
              Utils.isFalse(this.isReadOnly) &&
              Utils.isTrue(this.deleteEnabled) &&
              Utils.notNullOrEmpty(this.fileInfo) &&
              Utils.notNullOrEmpty(this.fileInfo.filename),
            onClick: (e: any) => this.onClearClick.emit(e)
          }
        },
        {
          name: ActionConstants.BROWSE,
          location: 'after',
          options: {
            type: 'default',
            stylingMode: 'text',
            hint: ActionConstants.BROWSE,
            icon: IconConstants.BROWSE,
            elementAttr: { fileInfo: this.fileInfo },
            visible: Utils.isFalse(this.isReadOnly),
            onClick: (e: any) => this.onBrowseClick.emit(e)
          }
        }
      ]
    });
  }

  @HostListener('onContentReady', ['$event'])
  onContentReady(): void {
    setTimeout(() => {
      const elems = document.getElementsByClassName(`cv-file-readonly-input`);
      Array.from(elems).forEach((elm) => {
        elm.setAttribute('readonly', 'true');
      });
    }, TimerConstants.inSeconds(1));
  }
}
