// Angular imports
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

// Util imports
import { FileUtils } from '../../../_utils/file.utils';

// Service imports
import { HelperService } from '../../../_services/helper.service';

// Model imports
import { FileInfoParameterDto } from '../../../_models/common/file-info-parameter.dto';

@Component({
  selector: 'app-video-viewer',
  templateUrl: './video-viewer.component.html',
  styleUrls: ['./video-viewer.component.scss']
})
export class VideoViewerComponent implements OnInit {
  @Input() title: string;
  @Input() fileInfo: FileInfoParameterDto;
  @Output() onClose = new EventEmitter<void>();

  fileDataUrl: string;
  fileType: string;

  constructor(private readonly helperService: HelperService) {}

  ngOnInit(): void {
    this.fileType = FileUtils.getMineType(this.fileInfo.filename);
    this.helperService.getFileDataUrl(this.fileInfo).subscribe({
      next: (fileDataUrl: string) => (this.fileDataUrl = fileDataUrl),
      error: () => this.onClose.emit()
    });
  }
}
