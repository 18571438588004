// Angular imports
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

// 3rd party imports
import { plainToInstance } from 'class-transformer';

// Rxjs imports
import { map, Observable } from 'rxjs';

// Constant imports
import { UrlConstants } from '../_constants/url.constants';

// Model imports
import { LogsContentParameterDto } from '../_models/common/logs-content-parameter.dto';
import { LogsContentResultDto } from '../_models/common/logs-content-result.dto';

@Injectable({ providedIn: 'root' })
export class LogsService {
  constructor(protected readonly httpClient: HttpClient) {}

  getLogFiles(): Observable<Array<string>> {
    const url = `${UrlConstants.LOGS_BASE_URL}/files`;
    return this.httpClient.get<Array<string>>(url);
  }

  getLogs(
    params: LogsContentParameterDto
  ): Observable<Array<LogsContentResultDto>> {
    const url = `${UrlConstants.LOGS_BASE_URL}/content`;
    return this.httpClient
      .get<Array<any>>(url, { params: params.toQueryParams() })
      .pipe(
        map((plain: Array<any>) => plainToInstance(LogsContentResultDto, plain))
      );
  }
}
