// Angular imports
import { Injectable } from '@angular/core';

// Rxjs imports
import { Observable } from 'rxjs';

// Enum imports
import { UserSettingCategory } from '../_enums/user-setting-category.enum';

// Model imports
import { StatusResultDto } from '../_models/common/status-result.dto';
import { UserSettingParameterDto } from '../_models/user/user-setting-parameter.dto';
import { UserSettingRawDto } from '../_models/user/user-setting-raw.dto';

@Injectable({ providedIn: 'root' })
export class UserSettingService {
  getItemApi?: (
    params: UserSettingParameterDto
  ) => Observable<UserSettingRawDto[]>;
  setItemApi?: (params: UserSettingRawDto) => Observable<StatusResultDto>;
  removeItemApi?: (
    params: UserSettingParameterDto
  ) => Observable<StatusResultDto>;

  portalType = 'CV';
  userId?: string;

  getItem(key: string, category?: UserSettingCategory): string | null {
    const storageKey: string = this.getStorageKey(key, category);
    return localStorage.getItem(storageKey);
  }

  setItem(key: string, value: string, category?: UserSettingCategory): void {
    this.saveSetting(key, value, category).subscribe();
    const storageKey: string = this.getStorageKey(key, category);
    localStorage.setItem(storageKey, value);
  }

  removeItem(key: string, category?: UserSettingCategory): void {
    this.removeSetting(key, category).subscribe();
    const storageKey: string = this.getStorageKey(key, category);
    localStorage.removeItem(storageKey);
  }

  // This will clear all the user settings from both client and server.
  clear(
    category?: UserSettingCategory,
    clearOnServer = false
  ): Observable<boolean> {
    return new Observable((observer) => {
      const prefix = this.getStorageKeyPrefix(category);
      const storageKeys = [];
      for (let i = 0; i < localStorage.length; i++) {
        const storageKey = localStorage.key(i);
        if (storageKey.startsWith(prefix)) {
          storageKeys.push(storageKey);
        }
      }
      storageKeys.forEach((storageKey) => {
        if (clearOnServer === true) {
          const key = storageKey.replace(`${prefix}_`, ``).trim();
          this.removeItem(key, category);
        } else {
          localStorage.removeItem(storageKey);
        }
      });
      observer.next(true);
      observer.complete();
    });
  }

  loadItems(
    key?: string,
    category?: UserSettingCategory
  ): Observable<UserSettingRawDto[]> {
    return new Observable((observer) => {
      if (!!this.userId && !!this.portalType && !!this.getItemApi) {
        const params: UserSettingParameterDto = new UserSettingParameterDto();
        params.userId = this.userId;
        params.portalType = this.portalType;
        params.key = (key || '').toUpperCase();
        params.category = category;
        params.clearCache = true;
        this.getItemApi(params).subscribe((results) => {
          results.forEach((result) => {
            const storageKey: string = this.getStorageKey(
              result.key,
              result.category
            );
            localStorage.setItem(storageKey, result.value);
          });
          observer.next(results);
          observer.complete();
        });
      } else {
        observer.next([]);
        observer.complete();
      }
    });
  }

  private getStorageKey(key: string, category?: UserSettingCategory): string {
    const prefix = this.getStorageKeyPrefix(category);
    return `${prefix}_${key}`.toUpperCase();
  }

  private getStorageKeyPrefix(category?: UserSettingCategory): string {
    const tokens = [];
    if (this.portalType) {
      tokens.push(`${this.portalType}`);
    }
    if (this.userId) {
      tokens.push(`${this.userId.replace(/ /gi, '_')}`);
    }
    if (category) {
      tokens.push(`${category}`);
    }
    return `${tokens.join('_').toUpperCase()}`;
  }

  saveSetting(
    key: string,
    value: string,
    category?: UserSettingCategory
  ): Observable<boolean> {
    return new Observable((observer) => {
      if (!!this.userId && !!this.portalType) {
        const isUpdated = value !== this.getItem(key, category);
        if (!!this.setItemApi && isUpdated) {
          const payload: UserSettingRawDto = new UserSettingRawDto();
          payload.userId = this.userId;
          payload.portalType = this.portalType;
          payload.key = key.toUpperCase();
          payload.value = value;
          payload.category = category;
          this.setItemApi(payload).subscribe((result) => {
            observer.next(result.isSuccess);
            observer.complete();
          });
        } else {
          observer.next(false);
          observer.complete();
        }
      } else {
        observer.next(false);
        observer.complete();
      }
    });
  }

  removeSetting(
    key: string,
    category?: UserSettingCategory
  ): Observable<boolean> {
    return new Observable((observer) => {
      if (!!this.userId && !!this.portalType && !!this.removeItemApi) {
        const params: UserSettingParameterDto = new UserSettingParameterDto();
        params.userId = this.userId;
        params.portalType = this.portalType;
        params.key = key.toUpperCase();
        params.category = category;
        params.clearCache = true;
        this.removeItemApi(params).subscribe((result) => {
          observer.next(result.isSuccess);
          observer.complete();
        });
      } else {
        observer.next(false);
        observer.complete();
      }
    });
  }
}
