<ng-container *ngIf="!!dataSource">
  <dx-menu
    displayExpr="name"
    orientation="horizontal"
    [dataSource]="dataSource"
    [hideSubmenuOnMouseLeave]="true"
    [showFirstSubmenuMode]="{
      name: 'onHover',
      delay: { show: 250, hide: 250 }
    }"
    [elementAttr]="{ class: 'cv-actions-cell-template-menu' }"
    (onItemClick)="onItemClick($event, record, rowIndex)"
  ></dx-menu>
</ng-container>
