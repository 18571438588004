// Angular imports
import {
  Component,
  EventEmitter,
  Injector,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';

// Dx imports
import { DxFormComponent } from 'devextreme-angular/ui/form';

// Util imports
import { DxWidgetUtils } from '../../../_utils/dx-widget.utils';
import { Utils } from '../../../_utils/utils';

// Component imports
import { BaseComponent } from '../../../_base/base.component';

// Constant imports
import { ActionConstants } from '../../../_constants/action.constants';
import { StorageKeyConstants } from '../../../_constants/storage-key.constants';
import { ThemeConstants } from '../../../_constants/theme.constants';

// Enum imports
import { UserSettingCategory } from '../../../_enums/user-setting-category.enum';

// Type imports
import { UserPreferences } from '../../../_types/user-preferences';

// Model imports
import { KeyValueResultDto } from '../../../_models/common/key-value-result.dto';

@Component({
  selector: 'app-preferences',
  templateUrl: './preferences.component.html',
  styleUrls: ['./preferences.component.scss']
})
export class PreferencesComponent extends BaseComponent implements OnInit {
  @ViewChild('editorForm', { static: false }) editorForm: DxFormComponent;
  @Output() onClose = new EventEmitter<void>();

  themeColors: Array<KeyValueResultDto<string>>;

  userPreferences: UserPreferences;

  constructor(protected override readonly injector: Injector) {
    super(injector);
  }

  ngOnInit(): void {
    this.themeColors = ThemeConstants.ThemeColors.map((r) => {
      return {
        key: r.name,
        displayText: r.displayText
      };
    });
    this.populateUserPreferences();
  }

  onButtonClick(name: string): void {
    if (Utils.equalsIgnoreCase(name, ActionConstants.SAVE)) {
      if (DxWidgetUtils.isFormValid(this.editorForm)) {
        this.userSettingService.setItem(
          StorageKeyConstants.USER_THEME,
          this.userPreferences.themeColor,
          UserSettingCategory.PREF
        );
        this.showSuccess('Preferences Saved Successfully');
        this.helperService.doReloadPreferences();
        this.onClose.emit();
      }
    }
  }

  private populateUserPreferences(): void {
    const userPreferences = {
      themeColor: this.userSettingService.getItem(
        StorageKeyConstants.USER_THEME,
        UserSettingCategory.PREF
      )
    };
    this.userPreferences = userPreferences;
  }
}
