// Rxjs imports
import { map, Observable } from 'rxjs';

// Util imports
import { Utils } from './utils';

// Constant imports
import { TimerConstants } from '../_constants/timer.constants';

export class FileUtils {
  static getSizeInKB(num: number): number {
    return 1024 * num;
  }

  static getSizeInMB(num: number): number {
    return 1024 * FileUtils.getSizeInKB(num);
  }

  static base64StringToDataUrl(base64: string, filename: string): string {
    return `data:${FileUtils.getMineType(filename)};base64,${base64}`;
  }

  static blobToDataUrl(blob: Blob, filename: string): Observable<string> {
    return FileUtils.getBase64(blob).pipe(
      map((base64: string) => FileUtils.base64StringToDataUrl(base64, filename))
    );
  }

  static bytesToDataUrl(
    fileBytes: string,
    filename: string
  ): Observable<string> {
    return FileUtils.getBase64(FileUtils.getBlob(fileBytes, filename)).pipe(
      map((base64: string) => FileUtils.base64StringToDataUrl(base64, filename))
    );
  }

  static getDataUrl(file: File | Blob): Observable<string> {
    return new Observable((observer) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = (event: ProgressEvent<FileReader>) => {
        const dataUrl = event.target.result as string; // data url string
        observer.next(dataUrl);
        observer.complete();
      };
      fileReader.onerror = (error) => {
        observer.error(error);
        observer.complete();
      };
    });
  }

  static getBase64(file: File | Blob): Observable<string> {
    return FileUtils.getDataUrl(file).pipe(
      map((dataUrl: string) => dataUrl.split('base64,').reverse().at(0))
    );
  }

  static getBlob(base64: string, filename: string, chunkSize = 512): Blob {
    const mimeType = FileUtils.getMineType(filename);
    const byteChars = atob(base64);
    const byteArrays = [];
    for (let offset = 0; offset < byteChars.length; offset += chunkSize) {
      const chunk = byteChars.slice(offset, offset + chunkSize);
      const byteNumbers = new Array(chunk.length);
      for (let i = 0; i < chunk.length; i++) {
        byteNumbers[i] = chunk.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    return new Blob(byteArrays, { type: mimeType });
  }

  static getFileExt(fileName: string): string {
    return Utils.notNullOrEmpty(fileName)
      ? fileName
          .trim()
          .toLowerCase()
          .substring(fileName.lastIndexOf('.') + 1)
      : null;
  }

  static getMineType(fileName: string): string {
    let mimeType = 'application/octet-stream';
    const fileExt = FileUtils.getFileExt(fileName);
    if (Utils.notNullOrEmpty(fileExt)) {
      switch (fileExt) {
        case 'pdf':
          mimeType = 'application/pdf';
          break;
        case 'doc':
          mimeType = 'application/msword';
          break;
        case 'docx':
          mimeType =
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
          break;
        case 'xls':
          mimeType = 'application/vnd.ms-excel';
          break;
        case 'xlsx':
          mimeType =
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
          break;
        case 'ppt':
          mimeType = 'application/vnd.ms-powerpoint';
          break;
        case 'pptx':
          mimeType =
            'application/vnd.openxmlformats-officedocument.presentationml.presentation';
          break;
        case 'jpg':
        case 'jpeg':
          mimeType = 'image/jpeg';
          break;
        case 'png':
        case 'gif':
        case 'bmp':
          mimeType = `image/${fileExt}`;
          break;
        case 'csv':
          mimeType = 'text/csv';
          break;
        case 'txt':
          mimeType = 'text/plain';
          break;
        case 'zip':
          mimeType = 'application/zip';
          break;
        case '7z':
          mimeType = 'application/x-7z-compressed';
          break;
        case 'mp4':
          mimeType = 'video/mp4';
          break;
        case 'ogg':
          mimeType = 'video/ogg';
          break;
        default:
          mimeType = 'application/octet-stream';
          break;
      }
    }
    return mimeType;
  }

  static getFileIcon(fileName: string): string {
    let icon = 'fa-solid fa-file-alt dx-theme-accent-as-text-color';
    const fileExt = FileUtils.getFileExt(fileName);
    if (Utils.notNullOrEmpty(fileExt)) {
      if (Utils.equalsIgnoreCase(fileExt, 'pdf')) {
        icon = 'fa-solid fa-file-pdf cv-icon-pdf';
      } else if (Utils.isInList(fileExt, ['doc', 'docx'])) {
        icon = 'fa-solid fa-file-word cv-icon-docx';
      } else if (Utils.isInList(fileExt, ['xls', 'xlsx'])) {
        icon = 'fa-solid fa-file-excel cv-icon-xlsx';
      } else if (Utils.isInList(fileExt, ['ppt', 'pptx'])) {
        icon = 'fa-solid fa-file-powerpoint cv-icon-pptx';
      } else if (Utils.isInList(fileExt, ['jpg', 'jpeg'])) {
        icon = 'fa-solid fa-image cv-icon-jpg';
      } else if (Utils.equalsIgnoreCase(fileExt, 'png')) {
        icon = 'fa-solid fa-image cv-icon-png';
      } else if (Utils.equalsIgnoreCase(fileExt, 'gif')) {
        icon = 'fa-solid fa-image cv-icon-gif';
      } else if (Utils.equalsIgnoreCase(fileExt, 'bmp')) {
        icon = 'fa-solid fa-image cv-icon-bmp';
      } else if (Utils.equalsIgnoreCase(fileExt, 'zip')) {
        icon = 'fa-solid fa-file-archive cv-icon-zip';
      } else if (Utils.equalsIgnoreCase(fileExt, 'rar')) {
        icon = 'fa-solid fa-file-archive cv-icon-rar';
      } else if (Utils.isInList(fileExt, ['mp4', 'ogg'])) {
        icon = 'fa-solid fa-file-video dx-theme-accent-as-text-color';
      }
    }
    return icon;
  }

  static openFile(blob: Blob, fileName: string): void {
    const blobUrl = window.URL.createObjectURL(blob);
    const fileTab = window.open(blobUrl, '_blank');
    setTimeout(() => {
      if (!!fileTab && !!fileTab.document) {
        fileTab.focus();
        fileTab.document.title = fileName;
      } else {
        Utils.showPopupBlockedError();
      }
      window.URL.revokeObjectURL(blobUrl);
    }, TimerConstants.inSeconds(2));
  }

  static downloadFile(blob: Blob, fileName: string): void {
    const blobUrl = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.style.display = 'none';
    link.target = '_blank';
    link.href = blobUrl;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    // Remove link & Revoke object URL
    setTimeout(
      () => document.body.removeChild(link),
      TimerConstants.inSeconds(1)
    );
  }

  static getFileNameWithoutExt(fileName: string): string {
    return Utils.notNullOrEmpty(fileName)
      ? fileName.substring(0, fileName.lastIndexOf('.'))
      : null;
  }
}
