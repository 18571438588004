// Rxjs imports
import { Subscription } from 'rxjs';

// Util imports
import { Utils } from './utils';

export class RxjsUtils {
  private static readonly subscriptions = new Map<
    string,
    Array<Subscription>
  >();

  static subscribe(key: string, sub: Subscription): void {
    if (Utils.isFalse(RxjsUtils.subscriptions.has(key))) {
      RxjsUtils.subscriptions.set(key, []);
    }
    RxjsUtils.subscriptions.get(key).push(sub);
  }

  static subscribes(key: string, subs: Array<Subscription>): void {
    if (Utils.isFalse(RxjsUtils.subscriptions.has(key))) {
      RxjsUtils.subscriptions.set(key, []);
    }
    RxjsUtils.subscriptions.get(key).push(...subs);
  }

  static unsubscribe(key: string): void {
    if (RxjsUtils.subscriptions.has(key)) {
      RxjsUtils.subscriptions.get(key).forEach((sub) => sub.unsubscribe());
    }
  }
}
