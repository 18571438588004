<div class="cv-powered-logo-container dx-theme-border-color">
  <div class="cv-powered-logo-content">
    <img class="img-cloud" [src]="imgCiraCloud" alt="CiraConnect" />
    <img
      *ngIf="isOpened"
      class="img-powered"
      [src]="imgPoweredBy"
      alt="Powered By CiraConnect"
    />
  </div>
  <div
    *ngIf="isOpened"
    class="cv-version-text-content cc-theme-sidenav-selected"
  >
    <span class="cv-version-text">{{ appVersion }}</span>
    <span class="cv-copyright-text">
      &copy; 2004 - {{ currentYear }} CiraConnect, LLC.
    </span>
    <span class="cv-copyright-text">All rights reserved.</span>
  </div>
</div>
