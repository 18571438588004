import { instanceToPlain } from 'class-transformer';
import { Utils } from '../../_utils/utils';

export class BaseQueryParameterDto {
  clearCache = false;

  toQueryParams() {
    const plain = instanceToPlain(this);
    Object.keys(this)
      .sort()
      .forEach((key) => {
        if (Utils.isNullOrUndefined(plain[key])) {
          delete plain[key];
        }
      });
    return plain;
  }

  toQueryString() {
    const tokens = [];
    const plain = instanceToPlain(this);
    Object.keys(this)
      .sort()
      .forEach((key) => {
        if (Utils.notNullOrEmpty(plain[key])) {
          tokens.push(`${key}=${plain[key]}`);
        }
      });
    return tokens.join('&');
  }
}
