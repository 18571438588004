// Angular imports
import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appNumberPercentage]'
})
export class NumberPercentageDirective {
  @HostListener('onInitialized', ['$event'])
  onInitialized(e: any): void {
    e.component.option({
      max: 1,
      min: 0.01,
      step: 0.01,
      format: '#.##%',
      showSpinButtons: true
    });
  }
}
