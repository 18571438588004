// Angular imports
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

// Service imports
import { AuthService } from './_services/auth.service';
import { HelperService } from './_services/helper.service';
import { MessageService } from './_services/message.service';
import { ScreenService } from './_services/screen.service';
import { ThemeService } from './_services/theme.service';

// Util imports
import { RxjsUtils } from './_utils/rxjs.utils';
import { Utils } from './_utils/utils';

// Type imports
import { MessageEntry } from './_types/message-entry';

// Constant imports
import { LoaderImageSvg } from './_constants/image.constants';
import { RoutePathConstants } from './_constants/route-path.constants';
import { TimerConstants } from './_constants/timer.constants';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  isSmall: boolean;
  isXSmall: boolean;
  isDevice: boolean;
  isLoggedIn: boolean;
  isParticipantLoggedIn: boolean;
  isParticipantHelp: boolean;
  showLoading: boolean;
  showMessage: boolean;
  showHeader: boolean;
  showInfo: boolean;
  messageEntry: MessageEntry;
  imgLoader: string;
  timerId: any;

  constructor(
    private readonly router: Router,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly authService: AuthService,
    private readonly messageService: MessageService,
    private readonly helperService: HelperService,
    private readonly screenService: ScreenService,
    private readonly themeService: ThemeService
  ) {
    this.router.events.subscribe((e: any) => {
      if (e instanceof NavigationEnd) {
        this.updateIsParticipantHelp(e.urlAfterRedirects);
      }
    });
  }

  ngOnInit(): void {
    RxjsUtils.subscribes('AppComponent', [
      this.authService.onUserLogin((flag: boolean) => {
        this.isLoggedIn = flag;
        if (Utils.isTrue(flag)) {
          this.authService.doParticipantLogout();
        }
      }),
      this.authService.onParticipantLogin((flag: boolean) => {
        this.isParticipantLoggedIn = flag;
        if (Utils.isTrue(flag)) {
          this.authService.doUserLogout();
        }
      }),
      this.helperService.onLoading((flag: boolean) => {
        this.showLoading = flag;
      }),
      this.messageService.onMessage((me: MessageEntry) => {
        this.messageEntry = me;
        this.showMessage = true;
        this.changeDetector.detectChanges();
      }),
      this.screenService.onChanged(this.updateScreenSize.bind(this)),
      this.themeService.onApplicationThemeChanged(
        this.updateThemeLoader.bind(this)
      ),
      this.helperService.onToggleHeader((flag: boolean) => {
        this.showHeader = flag;
      })
    ]);
    // First Time Calls
    this.themeService.setApplicationTheme();
    this.authService.hasUserLoggedIn();
    this.authService.hasParticipantLoggedIn();
    this.updateThemeLoader();
    this.updateScreenSize();
    this.showHeader = Utils.isFalse(this.isXSmall);
    this.showInfo = false;
  }

  ngOnDestroy(): void {
    RxjsUtils.unsubscribe('AppComponent');
  }

  private updateScreenSize(): void {
    this.isSmall = this.screenService.isSmall();
    this.isXSmall = this.screenService.isXSmall();
    this.isDevice = this.screenService.isDevice();
    clearTimeout(this.timerId);
    this.timerId = setTimeout(() => {
      if (
        Utils.isFalse(this.showInfo) &&
        Utils.isTrue(this.isXSmall) &&
        Utils.isTrue(this.isDevice) &&
        this.screenService.isLandscape()
      ) {
        this.showInfo = true;
        this.messageService
          .showInformationMessage(
            'Information',
            'Contents of this application can be viewed best in Portrait mode',
            'default'
          )
          .then(() => {
            clearTimeout(this.timerId);
            this.showInfo = false;
          });
      }
    }, TimerConstants.inSeconds(1));
  }

  private updateThemeLoader(): void {
    const themeColor = this.themeService.getApplicationTheme();
    if (Utils.notNullAndDefined(themeColor)) {
      this.imgLoader = LoaderImageSvg(themeColor.themeName);
    }
  }

  private updateIsParticipantHelp(currentPath: string): void {
    this.isParticipantHelp = Utils.equalsIgnoreCase(
      currentPath,
      `/${RoutePathConstants.PARTICIPANT}/${RoutePathConstants.HELP}`
    );
  }
}
