export class CitySelectorResultDto {
  cityId: number;
  stateId: number;
  countryId: number;

  cityName: string;
  stateName: string;
  stateCode: string;
  countryName: string;
  countryCode: string;
}
