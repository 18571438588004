// Angular imports
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';

// Rxjs imports
import { delay, finalize, Observable } from 'rxjs';

// Service imports
import { HelperService } from '../_services/helper.service';

// Util imports
import { Utils } from '../_utils/utils';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  private readonly _requests: Array<HttpRequest<any>> = [];

  constructor(private readonly helperService: HelperService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (Utils.hasSkipLoader(req.headers)) {
      return next.handle(req);
    }
    this.queue(req);
    return next
      .handle(req)
      .pipe(delay(100))
      .pipe(finalize(() => this.remove(req)));
  }

  private queue(req: HttpRequest<any>): void {
    this._requests.push(req);
    this.helperService.showLoading();
  }

  private remove(req: HttpRequest<any>) {
    const idx = this._requests.indexOf(req);
    if (Utils.notEquals(idx, -1)) {
      this._requests.splice(idx, 1);
    }
    if (Utils.isNullOrEmpty(this._requests)) {
      this.helperService.hideLoading();
    }
  }
}
