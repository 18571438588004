// Angular imports
import {
  Component,
  EventEmitter,
  Injector,
  Input,
  Output
} from '@angular/core';

// Rxjs imports
import { Observable } from 'rxjs';

// Component imports
import { BaseComponent } from '../../../_base/base.component';

// Constant imports
import { IconConstants } from '../../../_constants/icon.constants';

// Model imports
import { HistoryContentResultDto } from '../../../_models/common/history-content-result.dto';
import { HistoryDetailsResultDto } from '../../../_models/common/history-details-result.dto';

@Component({
  selector: 'app-history-summary-viewer',
  templateUrl: './history-summary-viewer.component.html',
  styleUrls: ['./history-summary-viewer.component.scss']
})
export class HistorySummaryViewerComponent<T> extends BaseComponent {
  @Input() record: T;
  @Input() historyOf: string;
  @Input() historySummary: Array<HistoryContentResultDto>;
  @Input() historyDetailsFn: (
    record: T,
    summary: HistoryContentResultDto
  ) => Observable<Array<HistoryDetailsResultDto>>;
  @Output() onClose = new EventEmitter<void>();

  iconInfo = IconConstants.INFO;
  showDetails = false;

  historyDetails: Array<HistoryDetailsResultDto>;
  selectedSummary: HistoryContentResultDto;

  constructor(protected override readonly injector: Injector) {
    super(injector);
  }

  onIconCellClick(summary: HistoryContentResultDto): void {
    this.selectedSummary = summary;
    this.historyDetailsFn(this.record, summary).subscribe(
      (results: Array<HistoryDetailsResultDto>) => {
        this.historyDetails = results;
        this.showDetails = true;
      }
    );
  }
}
