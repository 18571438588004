// Angular imports
import { Component, Injector, OnInit } from '@angular/core';

// Util imports
import { Utils } from '../../../_utils/utils';

// Component imports
import { BaseComponent } from '../../../_base/base.component';

// Constant imports
import { DisplayTextConstants } from '../../../_constants/display-text.constants';
import { IconConstants } from '../../../_constants/icon.constants';

// Type imports
import { DxItemOption } from '../../../_types/dx-item-option';

// Model imports
import { LogsContentParameterDto } from '../../../_models/common/logs-content-parameter.dto';
import { LogsContentResultDto } from '../../../_models/common/logs-content-result.dto';

@Component({
  selector: 'app-logs-viewer',
  templateUrl: './logs-viewer.component.html',
  styleUrls: ['./logs-viewer.component.scss']
})
export class LogsViewerComponent extends BaseComponent implements OnInit {
  iconInfo = IconConstants.INFO;
  showInfo = false;

  tabIndex: number;
  tabItems: Array<DxItemOption>;

  appLogFiles: Array<string>;
  jobLogFiles: Array<string>;

  appLogResults: Array<LogsContentResultDto>;
  jobLogResults: Array<LogsContentResultDto>;

  appLogFile: string;
  jobLogFile: string;

  selectedRecord: LogsContentResultDto;

  constructor(protected override readonly injector: Injector) {
    super(injector);
  }

  ngOnInit(): void {
    this.tabIndex = 0;
    this.tabItems = [
      { text: DisplayTextConstants.APP_LOGS },
      { text: DisplayTextConstants.JOB_LOGS }
    ];
    this.populateLogFiles();
  }

  onTabChanged(): void {
    if (
      Utils.isNullOrEmpty(this.appLogResults) ||
      Utils.isNullOrEmpty(this.jobLogResults)
    ) {
      this.populateLogs();
    }
  }

  onLogFileChanged(): void {
    this.populateLogs();
  }

  onRefreshClick(): void {
    this.populateLogFiles();
    this.populateLogs();
  }

  onIconCellClick(record: LogsContentResultDto): void {
    this.selectedRecord = record;
    this.showInfo = true;
  }

  private populateLogFiles(): void {
    this.logsService.getLogFiles().subscribe((results: Array<string>) => {
      this.appLogFiles = results.filter((file) =>
        Utils.startsWithIgnoreCase(file, 'logs')
      );
      this.jobLogFiles = results.filter((file) =>
        Utils.startsWithIgnoreCase(file, 'joblogs')
      );
      if (
        Utils.isNullOrEmpty(this.appLogFile) &&
        Utils.notNullOrEmpty(this.appLogFiles)
      ) {
        this.appLogFile = this.appLogFiles.at(0);
      }
      if (
        Utils.isNullOrEmpty(this.jobLogFile) &&
        Utils.notNullOrEmpty(this.jobLogFiles)
      ) {
        this.jobLogFile = this.jobLogFiles.at(0);
      }
    });
  }

  private populateLogs(): void {
    const params = new LogsContentParameterDto();
    params.fileName = Utils.equals(this.tabIndex, 0)
      ? this.appLogFile
      : this.jobLogFile;
    this.logsService
      .getLogs(params)
      .subscribe((results: Array<LogsContentResultDto>) => {
        if (Utils.equals(this.tabIndex, 0)) {
          this.appLogResults = results;
        } else {
          this.jobLogResults = results;
        }
      });
  }
}
