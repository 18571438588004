<app-navigation-pane *ngIf="isUserLoggedIn">
  <ng-template
    #navigation
    let-isOpened="isOpened"
    let-onItemClick="onItemClick"
    let-onItemExpand="onItemExpand"
  >
    <app-side-navigation
      [isOpened]="isOpened"
      [(dataSource)]="dataSource"
      [(selectedItem)]="selectedItem"
      [(showOrgSelector)]="showOrgSelector"
      (onItemExpand)="onItemExpand($event)"
      (onItemClick)="onItemClick($event)"
    ></app-side-navigation>
  </ng-template>
  <ng-template
    #content
    let-isOpened="isOpened"
    let-selectedItem="selectedItem"
    let-expandedItem="expandedItem"
  >
    <router-outlet (activate)="onRouterActivate($event)"></router-outlet>
  </ng-template>
</app-navigation-pane>
