<dx-box width="100%" height="100%" direction="col">
  <dxi-item baseSize="auto" *ngIf="showOrgSelector">
    <div *dxTemplate>
      <app-organization-menu
        *ngIf="showOrgSelector"
        [isOpened]="showOrgSelector && isOpened"
      ></app-organization-menu>
    </div>
  </dxi-item>
  <dxi-item [ratio]="1">
    <div *dxTemplate>
      <app-navigation-tree
        [isOpened]="isOpened"
        [dataSource]="dataSource"
        [selectedItem]="selectedItem"
        (onItemExpand)="onItemExpand($event)"
        (onItemClick)="onNavItemClick($event)"
      ></app-navigation-tree>
    </div>
  </dxi-item>
  <dxi-item baseSize="auto">
    <div *dxTemplate>
      <app-powered-logo-pane [isOpened]="isOpened"></app-powered-logo-pane>
    </div>
  </dxi-item>
</dx-box>
