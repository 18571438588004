// Angular imports
import {
  Component,
  EventEmitter,
  Injector,
  Input,
  Output
} from '@angular/core';

// Component imports
import { BaseComponent } from '../../../_base/base.component';

// Model imports
import { HistoryContentResultDto } from '../../../_models/common/history-content-result.dto';
import { HistoryDetailsResultDto } from '../../../_models/common/history-details-result.dto';

@Component({
  selector: 'app-history-details-viewer',
  templateUrl: './history-details-viewer.component.html',
  styleUrls: ['./history-details-viewer.component.scss']
})
export class HistoryDetailsViewerComponent extends BaseComponent {
  @Input() historyOf: string;
  @Input() historySummary: HistoryContentResultDto;
  @Input() historyDetails: Array<HistoryDetailsResultDto>;
  @Output() onClose = new EventEmitter<void>();

  constructor(protected override readonly injector: Injector) {
    super(injector);
  }
}
