<dx-data-grid
  #dataGrid
  appDataGridFeatures
  width="100%"
  height="100%"
  [gridFeatures]="{
    columnAutoWidth: false
  }"
  [columns]="columns"
  [dataSource]="dataSource"
>
  <div *dxTemplate="let cell of 'wraptextCellTemplate'">
    <app-wraptext-cell-template
      [text]="cell.value"
    ></app-wraptext-cell-template>
  </div>
  <div *dxTemplate="let cell of 'checkCellTemplate'">
    <app-check-cell-template
      [value]="cell.value"
      [tooltipText]="cell.column.caption"
    ></app-check-cell-template>
  </div>
  <div *dxTemplate="let cell of 'sequenceCellTemplate'">
    <app-sequence-cell-template
      [record]="cell.data"
      [rowIndex]="cell.rowIndex"
      [dataSource]="dataSource"
      (onUpdated)="onSequenceUpdated($event)"
    ></app-sequence-cell-template>
  </div>
</dx-data-grid>
