<dx-toolbar
  width="100%"
  height="100%"
  [elementAttr]="{
    class:
      (isXSmall ? 'cv-header-toolbar-sm' : 'cv-header-toolbar') +
      ' dx-theme-border-color'
  }"
>
  <dxi-item location="before" [visible]="isUserLoggedIn">
    <div *dxTemplate class="toolbar-item">
      <dx-button
        type="default"
        stylingMode="text"
        template="toggle-template"
        hint="Collapse / Expand Side Navigation"
        (onClick)="onToggleCollapseClick()"
      >
        <div *dxTemplate="let tpl of 'toggle-template'">
          <i aria-hidden="true" class="fa-2x" [ngClass]="iconBars"></i>
        </div>
      </dx-button>
    </div>
  </dxi-item>
  <dxi-item location="before" [visible]="isParticipantLoggedIn">
    <div *dxTemplate class="toolbar-item">
      <dx-button
        type="default"
        stylingMode="text"
        template="toggle-template"
        hint="Back To Event Summary Page"
        (onClick)="onLogoClick()"
      >
        <div *dxTemplate="let tpl of 'toggle-template'">
          <i aria-hidden="true" class="fa-2x" [ngClass]="iconBack"></i>
        </div>
      </dx-button>
    </div>
  </dxi-item>
  <dxi-item [location]="isXSmall ? 'center' : 'before'">
    <div *dxTemplate class="toolbar-item">
      <dx-button
        type="default"
        stylingMode="text"
        template="logo-template"
        hint="CiraConnect CiraVote"
        (onClick)="onLogoClick()"
      >
        <div *dxTemplate="let tpl of 'logo-template'">
          <img class="img-logo" [src]="imgLogo" alt="CiraConnect CiraVote" />
        </div>
      </dx-button>
    </div>
  </dxi-item>
  <dxi-item location="center">
    <div *dxTemplate class="cv-center-content">
      <div
        *ngIf="!!environment && !isXSmall"
        class="cv-environment-content dx-theme-accent-as-text-color"
      >
        <div class="cv-marquee">
          <span *ngFor="let i of [].constructor(20)" class="cv-marquee-content">
            {{ environment }}
          </span>
        </div>
      </div>
      <div
        *ngIf="eventInfo && !isSmall"
        class="cv-center-content dx-theme-accent-as-text-color"
      >
        <strong class="cv-1-5rx">{{ eventInfo?.entityName }}</strong>
        <span class="cv-1-25rx">{{ eventInfo?.eventTitle }}</span>
      </div>
    </div>
  </dxi-item>
  <dxi-item location="after" [visible]="isUserLoggedIn">
    <div *dxTemplate class="toolbar-item">
      <dx-drop-down-button
        keyExpr="id"
        displayExpr="name"
        [text]="displayText"
        [hint]="displayText"
        [icon]="iconUser"
        [splitButton]="false"
        [useSelectMode]="false"
        [showArrowIcon]="false"
        [dropDownOptions]="{ minWidth: '11rem' }"
        [dataSource]="userOptions"
        [elementAttr]="{ class: 'cv-profile-button' }"
        (onButtonClick)="onUserProfileClick()"
        (onItemClick)="onUserProfileItemClick($event)"
      ></dx-drop-down-button>
    </div>
  </dxi-item>
  <dxi-item location="after" [visible]="isParticipantLoggedIn">
    <div *dxTemplate class="toolbar-item">
      <dx-drop-down-button
        keyExpr="id"
        displayExpr="name"
        [text]="displayText"
        [hint]="displayText"
        [icon]="iconUser"
        [splitButton]="false"
        [useSelectMode]="false"
        [showArrowIcon]="false"
        [dropDownOptions]="{ minWidth: '11rem' }"
        [dataSource]="participantOptions"
        [elementAttr]="{ class: 'cv-profile-button' }"
        (onButtonClick)="onParticipantProfileClick()"
        (onItemClick)="onParticipantProfileItemClick($event)"
      ></dx-drop-down-button>
    </div>
  </dxi-item>
</dx-toolbar>

<app-preferences
  *ngIf="showPreferences"
  (onClose)="showPreferences = false"
></app-preferences>

<app-page-popup
  *ngIf="showLogs"
  width="90%"
  height="95%"
  title="Logs Viewer"
  [buttons]="['Close']"
  [visible]="true"
  (onHidden)="showLogs = false"
>
  <app-logs-viewer></app-logs-viewer>
</app-page-popup>
