export class RoutePathConstants {
  static ToRoutePath = (id: number) => `${id}`;
  static readonly EMPTY = '';
  static readonly UNKNOWN = '**';
  static readonly AUTH = 'auth';
  static readonly LOGIN = 'login';
  static readonly HELP = 'help';
  static readonly FEEDBACK = 'feedback';
  static readonly SUMMARY = 'summary';
  static readonly CREATE = 'create';
  static readonly UPDATE = 'update';
  static readonly INVALID_URL = 'invalid-url';
  static readonly PARTICIPANT = 'participant';
  static readonly PARTICIPANT_INVALID_URL = 'participant/invalid-url';
  static readonly ORGANIZATION_ID = ':organizationId';
  static readonly ENTITY_ID = ':entityId';
  static readonly EVENT_ID = ':eventId';
  static readonly PREVIEW = 'preview';
  static readonly PREVIEW_INVALID_URL = 'preview/invalid-url';
  static readonly ELECTION = 'election';
  static readonly ELECTION_DASHBOARD = 'dashboard';
  static readonly ELECTION_CHARTER = 'charter';
  static readonly READINESS = 'readiness';
  static readonly NOTICES = 'notices';
  static readonly CANDIDATES = 'candidates';
  static readonly BALLOT_SETUP = 'ballot-setup';
  static readonly PAPER_BALLOTS = 'paper-ballots';
  static readonly COMMUNITY_INFO = 'community-info';
  static readonly DOCUMENTATION = 'documentation';
  static readonly VOTING_PREFERENCES = 'voting-preferences';
  static readonly ELECTION_PREFERENCES = 'election-preferences';
  static readonly BOARD_PREFERENCES = 'board-preferences';
  static readonly NOTICE_PREFERENCES = 'notice-preferences';
  static readonly VOTERS_INFO = 'voters-info';
  static readonly MEETINGS = 'meetings';
  static readonly COMMUNICATIONS = 'communications';
  static readonly SCHEDULED_COMMUNICATIONS = 'scheduled-communications';
  static readonly ELECTION_REVIEW = 'election-review';
  static readonly VOTES_MANAGEMENT = 'votes-management';
  static readonly PRINT_PAPER_BALLOT = 'print-paper-ballot';
  static readonly PAPER_BALLOT_VOTE = 'paper-ballot-vote';
  static readonly ELECTION_REPORTS = 'election-reports';
  static readonly REPORT_ALL_HOMEOWNERS = 'all-homeowners';
  static readonly REPORT_ELIGIBLE_VOTERS = 'eligible-voters';
  static readonly REPORT_ABSTAINED_VOTERS = 'abstained-voters';
  static readonly REPORT_VOTED_PARTICIPANTS = 'voted-participants';
  static readonly REPORT_NOTVOTED_PARTICIPANTS = 'notvoted-participants';
  static readonly SURVEY_REPORTS = 'survey-reports';
  static readonly REPORT_ELIGIBLE_RESPONDENTS = 'eligible-respondents';
  static readonly REPORT_RESPONDED_PARTICIPANTS = 'responded-participants';
  static readonly REPORT_NOTRESPONDED_PARTICIPANTS =
    'notresponded-participants';
  static readonly VOTE = 'vote';
  static readonly SURVEY = 'survey';
  static readonly SURVEY_DASHBOARD = 'dashboard';
  static readonly SURVEY_SETUP = 'setup';
  static readonly SURVEY_QUESTIONS = 'questions';
  static readonly SURVEY_PARTICIPANTS = 'participants';
  static readonly SURVEY_NOTICES = 'notices';
  static readonly SURVEY_REVIEW = 'survey-review';
  static readonly ADMINISTRATION = 'adminstration';
  static readonly MANAGE_ORGANIZATIONS = 'manage-organizations';
  static readonly MANAGE_ROLES = 'manage-roles';
  static readonly MANAGE_USERS = 'manage-users';
  static readonly MANAGE_COMMUNITIES = 'manage-communities';
  static readonly MANAGE_COMMUNITY_USERS = 'manage-community-users';
  static readonly MANAGE_PROPERTIES = 'manage-properties';
  static readonly MANAGE_PARTICIPANTS = 'manage-participants';
  static readonly MANAGE_DOCUMENTS = 'manage-documents';
  static readonly AMENDMENT = 'amendment';
  static readonly AMENDMENT_DASHBOARD = 'dashboard';
  static readonly AMENDMENT_SETUP = 'event-setup';
  static readonly AMENDMENT_PREFERENCES = 'voting-preferences';
  static readonly AMENDMENT_BALLOT_SETUP = 'ballot-setup';
  static readonly AMENDMENT_DOCUMENT_SETUP = 'document-setup';
  static readonly AMENDMENT_VOTER_SETUP = 'voter-info';
  static readonly AMENDMENT_NOTICES = 'notices';
  static readonly AMENDMENT_REVIEW = 'review';
  static readonly AMENDMENT_REPORTS = 'reports';
  static readonly AMENDMENT_VOTES_MANAGEMENT = 'votes-management';
}
