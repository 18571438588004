// Angular imports
import { Component, Injector, Input, OnDestroy, OnInit } from '@angular/core';

// Component imports
import { BaseComponent } from '../../../_base/base.component';

// Util imports
import { RxjsUtils } from '../../../_utils/rxjs.utils';
import { Utils } from '../../../_utils/utils';

// Constant imports
import { ActionConstants } from '../../../_constants/action.constants';
import { IconConstants } from '../../../_constants/icon.constants';
import { ImageConstants } from '../../../_constants/image.constants';
import { RoutePathConstants } from '../../../_constants/route-path.constants';

// Type imports
import { DropDownOption } from '../../../_types/drop-down-option';
import { EventContextInfo } from '../../../_types/event-context-info';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent
  extends BaseComponent
  implements OnInit, OnDestroy
{
  @Input() isUserLoggedIn = false;
  @Input() isParticipantLoggedIn = false;

  iconBars = IconConstants.BARS;
  iconBack = IconConstants.BACK;
  iconUser = IconConstants.USER_CIRCLE;
  imgLogo = ImageConstants.CIRA_VOTE;

  isSidenavOpen = true;
  showLogs = false;
  showPreferences = false;

  defaultOptions: Array<DropDownOption<string>>;
  userOptions: Array<DropDownOption<string>>;
  participantOptions: Array<DropDownOption<string>>;

  displayText: string;
  eventInfo: EventContextInfo;

  constructor(protected override readonly injector: Injector) {
    super(injector);
  }

  ngOnInit(): void {
    this.defaultOptions = [
      {
        id: ActionConstants.PREFERENCES,
        name: ActionConstants.PREFERENCES,
        icon: IconConstants.USER_PREFERENCES
      },
      {
        id: ActionConstants.LOGOUT,
        name: ActionConstants.LOGOUT,
        icon: IconConstants.LOGOUT
      }
    ];
    this.userOptions = this.participantOptions = this.defaultOptions;
    RxjsUtils.subscribes('HeaderComponent', [
      this.helperService.onToggleSidenav(
        (flag: boolean) => (this.isSidenavOpen = flag)
      ),
      this.contextService.onEventInfoChanged(
        (eventInfo: EventContextInfo) => (this.eventInfo = eventInfo)
      )
    ]);
  }

  ngOnDestroy(): void {
    RxjsUtils.unsubscribe('HeaderComponent');
  }

  onLogoClick(): void {
    this.helperService.updateLogoClick();
  }

  onToggleCollapseClick(): void {
    if (Utils.isFalse(this.isSidenavOpen)) {
      this.helperService.showSidenav();
    } else {
      this.helperService.hideSidenav();
    }
  }

  onUserProfileClick(): void {
    // TODO:
  }

  onUserProfileItemClick(e: any): void {
    const opt = e.itemData as DropDownOption<string>;
    if (Utils.equalsIgnoreCase(opt.id, ActionConstants.VIEW_LOGS)) {
      this.showLogs = true;
    } else if (Utils.equalsIgnoreCase(opt.id, ActionConstants.PREFERENCES)) {
      this.showPreferences = true;
    } else if (Utils.equalsIgnoreCase(opt.id, ActionConstants.LOGOUT)) {
      this.router
        .navigate([RoutePathConstants.AUTH, RoutePathConstants.LOGIN])
        .then(() => {
          this.authService.doUserLogout();
          this.contextService.clearAllContext();
        });
    }
  }

  onParticipantProfileClick(): void {
    // TODO:
  }

  onParticipantProfileItemClick(e: any): void {
    const opt = e.itemData as DropDownOption<string>;
    if (Utils.equalsIgnoreCase(opt.id, ActionConstants.PREFERENCES)) {
      this.showPreferences = true;
    } else if (Utils.equalsIgnoreCase(opt.id, ActionConstants.LOGOUT)) {
      this.router
        .navigate([RoutePathConstants.PARTICIPANT, RoutePathConstants.LOGIN])
        .then(() => {
          this.authService.doParticipantLogout();
          this.contextService.clearAllContext();
        });
    }
  }

  protected override updateScreenSize(): void {
    super.updateScreenSize();
    if (Utils.isFalse(this.isXSmall)) {
      if (Utils.notNullAndDefined(this.userInfo)) {
        this.displayText = this.userInfo.displayName;
      } else if (Utils.notNullAndDefined(this.participantInfo)) {
        this.displayText = this.participantInfo.displayName;
      }
    } else {
      this.displayText = null;
    }
  }

  protected override updateUserInfo(): void {
    super.updateUserInfo();
    this.updateScreenSize();
    if (
      Utils.isTrue(this.isUserLoggedIn) &&
      Utils.notNullAndDefined(this.userInfo)
    ) {
      if (Utils.notNullOrEmpty(this.userInfo.profileImage)) {
        this.iconUser = this.userInfo.profileImage;
      }
      if (Utils.isTrue(this.userInfo.isSuperAdmin)) {
        this.userOptions = [
          {
            id: ActionConstants.VIEW_LOGS,
            name: ActionConstants.VIEW_LOGS,
            icon: IconConstants.LOGS
          },
          ...this.defaultOptions
        ];
      }
    }
  }
}
