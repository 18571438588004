export class DisplayTextConstants {
  static readonly LOGIN = 'Login';
  static readonly SUMMARY = 'Summary';
  static readonly VOTE = 'Vote';
  static readonly SURVEY = 'Survey';
  static readonly INVALID_URL = 'Invalid URL';
  static readonly CREATION_DATE = 'Created Date';
  static readonly PUBLISH_DATE = 'Publish Date';
  static readonly RESULT_DATE = 'Result Date';
  static readonly NAME = 'Name';
  static readonly EMAIL = 'Email';
  static readonly ADDRESS = 'Address';
  static readonly ELECTION_EVENT = 'Election Event';
  static readonly SURVEY_EVENT = 'Survey Event';
  static readonly OTHER_VOTING_EVENT = 'Other Voting Event';
  static readonly DOCUMENT_AMENDMENT = 'Document Amendment';
  static readonly CONTRACT_RATIFICATION = 'Contract Ratification';
  static readonly BYLAW_AMENDMENT = 'Bylaw Amendment';
  static readonly VOTING_BY_ACCLAMATION = 'Voting by Acclamation';
  static readonly BUDGET_APPROVALS = 'Budget Approvals';
  static readonly CONTRACTORS_APPROVAL = 'Approval of Third-Party Contractors';
  static readonly AWARD_VOTING = 'Award Voting';
  static readonly OPEN_EVENTS = 'Open Events';
  static readonly CLOSED_EVENTS = 'Closed Events';
  static readonly ELECTION_DASHBOARD = 'Election Dashboard';
  static readonly ELECTION_CHARTER = 'Election Charter';
  static readonly READINESS = 'Readiness';
  static readonly COMMUNITY_INFO = 'Community Information';
  static readonly DOCUMENTATION = 'Documentation';
  static readonly VOTING_PREFERENCES = 'Voting Preferences';
  static readonly ELECTION_PREFERENCES = 'Election Preferences';
  static readonly BOARD_PREFERENCES = 'Board Preferences';
  static readonly NOTICE_PREFERENCES = 'Notice Preferences';
  static readonly VOTERS_INFO = 'Voters Information';
  static readonly NOTICES = 'Notices';
  static readonly MEETINGS = 'Meetings';
  static readonly COMMUNICATIONS = 'Communications';
  static readonly SCHEDULED_COMMUNICATIONS = 'Scheduled Communications';
  static readonly CANDIDATES = 'Candidates';
  static readonly BALLOT_SETUP = 'Ballot Setup';
  static readonly PAPER_BALLOTS = 'Paper Ballots';
  static readonly ELECTION_REVIEW = 'Review & Publish';
  static readonly VOTES_MANAGEMENT = 'Votes Management';
  static readonly PRINT_PAPER_BALLOT = 'Print Paper Ballot';
  static readonly PAPER_BALLOT_VOTE = 'Manage Vote';
  static readonly ELECTION_REPORTS = 'Election Reports';
  static readonly REPORT_ALL_HOMEOWNERS = 'All Homeowners';
  static readonly REPORT_ELIGIBLE_VOTERS = 'Eligible Voters';
  static readonly REPORT_ABSTAINED_VOTERS = 'Abstained Voters';
  static readonly REPORT_VOTED_PARTICIPANTS = 'Voted Participants';
  static readonly REPORT_NOTVOTED_PARTICIPANTS = 'Not Voted Participants';
  static readonly REPORT_VOTED_LIST_BY_METHOD = 'Voted List By Method';
  static readonly REPORT_MEETING_SING_IN_SHEET = 'Meeting Sign In Sheet';
  static readonly EXCLUDE_INCLUDE_VOTES = 'Votes Eligibility Report';
  static readonly ELECTION_VOTING_TALLY = 'Election Voting Tally';
  static readonly SURVEY_REPORTS = 'Survey Reports';
  static readonly REPORT_ELIGIBLE_RESPONDENTS = 'Eligible Respondents';
  static readonly REPORT_RESPONDED_PARTICIPANTS = 'Responded Participants';
  static readonly REPORT_NOTRESPONDED_PARTICIPANTS =
    'Not Responded Participants';
  static readonly EXCLUDE_INCLUDE_RESPONSES = 'Responses Eligibility Report';
  static readonly SURVEY_RESPONSE_TALLY = 'Survey Response Tally';
  static readonly SURVEY_DASHBOARD = 'Survey Dashboard';
  static readonly SURVEY_SETUP = 'Survey Setup';
  static readonly SURVEY_QUESTIONS = 'Questions';
  static readonly SURVEY_PARTICIPANTS = 'Participants';
  static readonly SURVEY_NOTICES = 'Notices';
  static readonly SURVEY_REVIEW = 'Review & Publish';
  static readonly BROWSE = 'Browse';
  static readonly TOTAL = 'Total';
  static readonly COMPLETE = 'Complete';
  static readonly ELIGIBLE = 'Eligible';
  static readonly INELIGIBLE = 'Ineligible';
  static readonly INCLUDED = 'Included';
  static readonly EXCLUDED = 'Excluded';
  static readonly TILE_VIEW = 'Tile View';
  static readonly LIST_VIEW = 'List View';
  static readonly OPTIONS = 'Options';
  static readonly RELATED_QUESTIONS = 'Related Questions';
  static readonly RELATED_RULES = 'Related Rules';
  static readonly ADMINISTRATION = 'Adminstration';
  static readonly MANAGE_ORGANIZATIONS = 'Manage Organizations';
  static readonly MANAGE_ROLES = 'Manage Roles';
  static readonly MANAGE_USERS = 'Manage Users';
  static readonly MANAGE_DOCUMENTS = 'Manage Documents';
  static readonly MANAGE_COMMUNITIES = 'Manage Communities';
  static readonly MANAGE_COMMUNITY_USERS = 'Manage Community Users';
  static readonly MANAGE_PROPERTIES = 'Manage Properties';
  static readonly MANAGE_PARTICIPANTS = 'Manage Participants';
  static readonly APP_LOGS = 'App Logs';
  static readonly JOB_LOGS = 'Job Logs';
  static readonly SAVE_CONTINUE = 'Save & Continue';
  static readonly DISCARD_CONTINUE = 'Discard & Continue';
  static readonly AMENDMENT_DASHBOARD = 'Dashboard';
  static readonly AMENDMENT_SETUP = 'Event Setup';
  static readonly AMENDMENT_PREFERENCES = 'Voting Preferences';
  static readonly AMENDMENT_BALLOT_SETUP = 'Ballot Setup';
  static readonly AMENDMENT_DOCUMENT_SETUP = 'Document Setup';
  static readonly AMENDMENT_VOTER_SETUP = 'Voter Information';
  static readonly AMENDMENT_NOTICES = 'Notices';
  static readonly AMENDMENT_REVIEW = 'Review & Publish';
  static readonly AMENDMENT_REPORTS = 'Reports';
  static readonly AMENDMENT_VOTES_MANAGEMENT = 'Votes Management';
  static readonly ABSTAINED = 'Abstained';
  static readonly AMENDMENT_VOTING_TALLY = 'Amendment Voting Tally';
  static readonly COMPOSITE_REPORTS_PACKAGE = 'Composite Reports Package';
  static readonly COMPOSITE_BOARD_PACKAGE = 'Composite Board Package';
  static readonly PARTICIPANT_HELP = 'CiraVote Help';
  static readonly PARTICIPANT_FEEDBACK = 'CiraVote Feedback';
  static readonly PUBLISH_WITH_EMAIL = 'With Results Email';
  static readonly PUBLISH_WITHOUT_EMAIL = 'Without Results Email';
}
