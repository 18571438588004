// Angular imports
import { Component, Input } from '@angular/core';

// Constant imports
import { AppDefaultConstants } from '../../../_constants/app-default.constants';
import { ImageConstants } from '../../../_constants/image.constants';

@Component({
  selector: 'app-powered-logo-pane',
  templateUrl: './powered-logo-pane.component.html',
  styleUrls: ['./powered-logo-pane.component.scss']
})
export class PoweredLogoPaneComponent {
  @Input() isOpened = true;
  imgCiraCloud = ImageConstants.CIRA_LOGO;
  imgPoweredBy = ImageConstants.POWERED_BY;
  appVersion = AppDefaultConstants.VERSION;
  currentYear = new Date().getFullYear();
}
