// Angular imports
import { Injectable } from '@angular/core';

// Rxjs imports
import { BehaviorSubject, delay, Subscription, tap } from 'rxjs';

// Util imports
import { Utils } from '../_utils/utils';

// Constant imports
import { StorageKeyConstants } from '../_constants/storage-key.constants';

// Type imports
import { EventContextInfo } from '../_types/event-context-info';

// Model imports
import { CommunitySelectorResultDto } from '../_models/community/community-selector-result.dto';
import { OrganizationResultDto } from '../_models/organization/organization-result.dto';

@Injectable({ providedIn: 'root' })
export class ContextService {
  private readonly _subjectOrganizationChanged =
    new BehaviorSubject<OrganizationResultDto>(null);
  private readonly _subjectCommunityChanged =
    new BehaviorSubject<CommunitySelectorResultDto>(null);
  private readonly _subjectEventChanged = new BehaviorSubject<EventContextInfo>(
    null
  );

  onOrganizationChanged(
    handler: (rec: OrganizationResultDto) => void,
    delayMillis = 0
  ): Subscription {
    return this._subjectOrganizationChanged
      .pipe(delay(delayMillis), tap(handler))
      .subscribe();
  }

  onCommunityChanged(
    handler: (rec: CommunitySelectorResultDto) => void,
    delayMillis = 0
  ): Subscription {
    return this._subjectCommunityChanged
      .pipe(delay(delayMillis), tap(handler))
      .subscribe();
  }

  onEventInfoChanged(
    handler: (rec: EventContextInfo) => void,
    delayMillis = 0
  ): Subscription {
    return this._subjectEventChanged
      .pipe(delay(delayMillis), tap(handler))
      .subscribe();
  }

  setOrganization(rec: OrganizationResultDto): void {
    if (Utils.notNullAndDefined(rec)) {
      localStorage.setItem(
        StorageKeyConstants.USER_ORGANIZATION,
        String(rec.organizationId)
      );
    } else {
      localStorage.removeItem(StorageKeyConstants.USER_ORGANIZATION);
    }
    this._subjectOrganizationChanged.next(rec);
  }

  setCommunity(rec: CommunitySelectorResultDto): void {
    this._subjectCommunityChanged.next(rec);
  }

  setEventInfo(rec: EventContextInfo): void {
    this._subjectEventChanged.next(rec);
  }

  setEventTitle(eventTitle: string): void {
    const info = this.getEventInfo();
    if (
      Utils.notNullAndDefined(info) &&
      Utils.notEqualsIgnoreCase(info.eventTitle, eventTitle)
    ) {
      info.eventTitle = eventTitle;
      this._subjectEventChanged.next(info);
    }
  }

  getOrganization(): OrganizationResultDto {
    return this._subjectOrganizationChanged.getValue();
  }

  getOrganizationId(): number {
    return (
      this._subjectOrganizationChanged.getValue() || { organizationId: null }
    ).organizationId;
  }

  getCommunity(): CommunitySelectorResultDto {
    return this._subjectCommunityChanged.getValue();
  }

  getCommunityId(): number {
    return (this._subjectCommunityChanged.getValue() || { id: null }).id;
  }

  isEventClosed(): boolean {
    return (this._subjectEventChanged.getValue() || { isClosed: false })
      .isClosed;
  }

  isEventPublished(): boolean {
    return (this._subjectEventChanged.getValue() || { isPublished: false })
      .isPublished;
  }

  getEventId(): number {
    return (this._subjectEventChanged.getValue() || { eventId: null }).eventId;
  }

  getEventEntityId(): number {
    return (this._subjectEventChanged.getValue() || { entityId: null })
      .entityId;
  }

  getEventInfo(): EventContextInfo {
    return this._subjectEventChanged.getValue();
  }

  clearEventContext(): void {
    this._subjectEventChanged.next(null);
  }

  clearAllContext(): void {
    this.clearEventContext();
    this._subjectCommunityChanged.next(null);
    this._subjectOrganizationChanged.next(null);
  }
}
