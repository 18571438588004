// Angular imports
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

// Util imports
import { HtmlEditorUtils } from '../../../_utils/html-editor.utils';

// Service imports
import { ThemeService } from '../../../_services/theme.service';

// Model imports
import { MergeFieldResultDto } from '../../../_models/common/merge-field-result.dto';

@Component({
  selector: 'app-html-editor',
  templateUrl: './html-editor.component.html',
  styleUrls: ['./html-editor.component.scss']
})
export class HtmlEditorComponent implements OnInit {
  @Input() fields: Array<MergeFieldResultDto> = [];
  @Input() width = '100%';
  @Input() height = '100%';
  @Input() value = '';
  @Input() readOnly = false;
  @Output() valueChange = new EventEmitter<string>();
  @Output() onFullScreenChanged = new EventEmitter<boolean>();

  editorOptions = {};

  constructor(protected readonly themeService: ThemeService) {}

  ngOnInit(): void {
    const useDarkMode = this.themeService.isDarkTheme();
    this.editorOptions = HtmlEditorUtils.editorOptions(
      {
        width: this.width,
        height: this.height,
        readonly: this.readOnly,
        skin: useDarkMode ? 'oxide-dark' : 'oxide',
        content_css: useDarkMode ? 'dark' : 'default'
      },
      this.fields
    );
  }

  onInit(e: any): void {
    e.editor.on('FullscreenStateChanged', (ev: any) => {
      this.onFullScreenChanged.emit(ev.state);
    });
  }
}
