// Angular imports
import { Directive, HostListener, Input } from '@angular/core';

// Util imports
import { Utils } from '../../_utils/utils';

@Directive({
  selector: '[appDropDownButtonType]'
})
export class DropDownButtonTypeDirective {
  @Input() type: 'normal' | 'default' | 'success' | 'danger' = 'normal';
  @Input() stylingMode: 'contained' | 'outlined' | 'text' = 'contained';
  @Input() displayExpr = 'text';

  @HostListener('onContentReady', ['$event'])
  onContentReady(e: any): void {
    e.component.option({
      displayExpr: this.displayExpr,
      stylingMode: this.stylingMode
    });
    setTimeout(() => {
      const btnElm: HTMLDivElement = e.element.querySelector(
        '.dx-button.dx-button-normal'
      );
      if (Utils.notNullAndDefined(btnElm)) {
        if (Utils.equalsIgnoreCase(this.stylingMode, 'contained')) {
          btnElm.classList.add('dx-item-selected', 'cv-buttongroup-item');
        }
        btnElm.classList.remove('dx-button-normal');
        switch (this.type) {
          case 'success':
            btnElm.classList.add('dx-button-success');
            break;
          case 'danger':
            btnElm.classList.add('dx-button-danger');
            break;
          case 'default':
            btnElm.classList.add('dx-button-default');
            break;
          default:
            btnElm.classList.remove('dx-item-selected');
            btnElm.classList.add('dx-button-normal');
            break;
        }
      }
    }, 100);
  }
}
