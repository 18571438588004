export const environment = {
  name: 'Production',
  production: true,
  reCaptchaSiteKey: '6LdDnocgAAAAAOruOo4KNYI7GwwgwfmmMpJNH3Cg',
  ccVoteApiBaseUrl: 'https://api.ciravote.com/api',
  sentryDsnUrl: 'https://f03478122781926142dbbb0a82233e10@sentry.ciranet.com/6',
  tracesSampleRate: 0.1,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0
};
