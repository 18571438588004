// Angular imports
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

// Util imports
import { Utils } from '../../../_utils/utils';

// Type Imports
import {
  ActionMenuEvent,
  ActionMenuInfo
} from '../../../_types/action-menu-info';

// Constant imports
import { ActionConstants } from '../../../_constants/action.constants';
import { IconConstants } from '../../../_constants/icon.constants';

@Component({
  selector: 'app-actions-cell-template',
  templateUrl: './actions-cell-template.component.html',
  styleUrls: ['./actions-cell-template.component.scss']
})
export class ActionsCellTemplateComponent<T> implements OnInit {
  @Input() record: T;
  @Input() rowIndex: number;
  @Input() actionItems: Array<ActionMenuInfo>;
  @Input() isActionVisible: (e: ActionMenuEvent<T>) => boolean;
  @Output() onActionClick = new EventEmitter<ActionMenuEvent<T>>();

  dataSource: Array<ActionMenuInfo>;

  ngOnInit(): void {
    this.repaint();
  }

  onItemClick(e: any, record: T, rowIndex: number): void {
    const action = e.itemData as ActionMenuInfo;
    this.onActionClick.emit({
      action,
      record,
      rowIndex
    });
  }

  isItemVisible(action: ActionMenuInfo, record: T, rowIndex: number): boolean {
    if (Utils.notNullAndDefined(this.isActionVisible)) {
      return this.isActionVisible({ action, record, rowIndex });
    }
    return true;
  }

  repaint(): void {
    const visibleItems = this.actionItems.filter((action: ActionMenuInfo) =>
      this.isItemVisible(action, this.record, this.rowIndex)
    );
    if (Utils.notNullOrEmpty(visibleItems)) {
      this.dataSource = [
        {
          id: ActionConstants.ACTIONS,
          name: ActionConstants.ACTIONS,
          icon: IconConstants.ACTION,
          items: visibleItems
        }
      ];
    }
  }
}
