<app-page-popup
  *ngIf="historyDetails"
  width="90%"
  height="95%"
  title="{{ historyOf }} Change Details"
  [buttons]="['Close']"
  [visible]="true"
  (onHidden)="onClose.emit()"
>
  <dx-data-grid
    appDataGridFeatures
    width="100%"
    height="100%"
    [dataSource]="historyDetails"
  >
    <dxi-column
      dataField="fieldName"
      dataType="string"
      caption="Changed"
    ></dxi-column>
    <dxi-column
      dataField="oldValue"
      dataType="string"
      caption="From"
    ></dxi-column>
    <dxi-column
      dataField="newValue"
      dataType="string"
      caption="To"
    ></dxi-column>
  </dx-data-grid>
</app-page-popup>
