<dx-drop-down-button
  appDropDownButtonType
  appDataGridExport
  type="default"
  [dataGrid]="dataGrid"
  [options]="options"
  [onExportFn]="onExportFn"
></dx-drop-down-button>

<app-filename-prompt
  *ngIf="showFilename"
  [filename]="options?.filename"
  (onClose)="onPromptClose()"
  (onOk)="onOkClick($event)"
></app-filename-prompt>
