<dx-button
  stylingMode="text"
  [type]="type"
  [icon]="icon"
  [hint]="tooltipText"
  [elementAttr]="{ class: 'cv-cell-template-icon' }"
  [activeStateEnabled]="false"
  [hoverStateEnabled]="false"
  [focusStateEnabled]="false"
  (onClick)="onClick.emit()"
></dx-button>
