<div class="cv-sequence-action-buttons">
  <dx-button
    stylingMode="outlined"
    hint="Move Up"
    [type]="type"
    [icon]="iconUp"
    [activeStateEnabled]="false"
    [hoverStateEnabled]="false"
    [focusStateEnabled]="false"
    [disabled]="!allowUp"
    (onClick)="onUpClick()"
  ></dx-button>
  <dx-button
    stylingMode="outlined"
    hint="Move Down"
    [type]="type"
    [icon]="iconDown"
    [activeStateEnabled]="false"
    [hoverStateEnabled]="false"
    [focusStateEnabled]="false"
    [disabled]="!allowDown"
    (onClick)="onDownClick()"
  ></dx-button>
</div>
