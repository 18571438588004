import * as moment from 'moment-timezone';
import { Utils } from '../_utils/utils';

export class TimezoneConstants {
  static readonly tzOptions = [
    {
      key: '(GMT-11:00) Midway Island',
      displayText: 'Pacific/Midway'
    },
    {
      key: '(GMT-11:00) Samoa',
      displayText: 'US/Samoa'
    },
    {
      key: '(GMT-10:00) Hawaii',
      displayText: 'US/Hawaii'
    },
    {
      key: '(GMT-09:00) Alaska',
      displayText: 'US/Alaska'
    },
    {
      key: '(GMT-08:00) Pacific Time (US & Canada)',
      displayText: 'US/Pacific'
    },
    {
      key: '(GMT-08:00) Tijuana',
      displayText: 'America/Tijuana'
    },
    {
      key: '(GMT-07:00) Arizona',
      displayText: 'US/Arizona'
    },
    {
      key: '(GMT-07:00) Mountain Time (US & Canada)',
      displayText: 'US/Mountain'
    },
    {
      key: '(GMT-07:00) Chihuahua',
      displayText: 'America/Chihuahua'
    },
    {
      key: '(GMT-07:00) Mazatlan',
      displayText: 'America/Mazatlan'
    },
    {
      key: '(GMT-06:00) Mexico City',
      displayText: 'America/Mexico_City'
    },
    {
      key: '(GMT-06:00) Monterrey',
      displayText: 'America/Monterrey'
    },
    {
      key: '(GMT-06:00) Saskatchewan',
      displayText: 'Canada/Saskatchewan'
    },
    {
      key: '(GMT-06:00) Central Time (US & Canada)',
      displayText: 'US/Central'
    },
    {
      key: '(GMT-05:00) Eastern Time (US & Canada)',
      displayText: 'US/Eastern'
    },
    {
      key: '(GMT-05:00) Indiana (East)',
      displayText: 'US/East-Indiana'
    },
    {
      key: '(GMT-05:00) Bogota',
      displayText: 'America/Bogota'
    },
    {
      key: '(GMT-05:00) Lima',
      displayText: 'America/Lima'
    },
    {
      key: '(GMT-04:30) Caracas',
      displayText: 'America/Caracas'
    },
    {
      key: '(GMT-04:00) Atlantic Time (Canada)',
      displayText: 'Canada/Atlantic'
    },
    {
      key: '(GMT-04:00) La Paz',
      displayText: 'America/La_Paz'
    },
    {
      key: '(GMT-04:00) Santiago',
      displayText: 'America/Santiago'
    },
    {
      key: '(GMT-03:30) Newfoundland',
      displayText: 'Canada/Newfoundland'
    },
    {
      key: '(GMT-03:00) Buenos Aires',
      displayText: 'America/Buenos_Aires'
    },
    {
      key: '(GMT-03:00) Greenland',
      displayText: 'Greenland'
    },
    {
      key: '(GMT-02:00) Stanley',
      displayText: 'Atlantic/Stanley'
    },
    {
      key: '(GMT-01:00) Azores',
      displayText: 'Atlantic/Azores'
    },
    {
      key: '(GMT-01:00) Cape Verde Is.',
      displayText: 'Atlantic/Cape_Verde'
    },
    {
      key: '(GMT) Casablanca',
      displayText: 'Africa/Casablanca'
    },
    {
      key: '(GMT) Dublin',
      displayText: 'Europe/Dublin'
    },
    {
      key: '(GMT) Lisbon',
      displayText: 'Europe/Lisbon'
    },
    {
      key: '(GMT) London',
      displayText: 'Europe/London'
    },
    {
      key: '(GMT) Monrovia',
      displayText: 'Africa/Monrovia'
    },
    {
      key: '(GMT+01:00) Amsterdam',
      displayText: 'Europe/Amsterdam'
    },
    {
      key: '(GMT+01:00) Belgrade',
      displayText: 'Europe/Belgrade'
    },
    {
      key: '(GMT+01:00) Berlin',
      displayText: 'Europe/Berlin'
    },
    {
      key: '(GMT+01:00) Bratislava',
      displayText: 'Europe/Bratislava'
    },
    {
      key: '(GMT+01:00) Brussels',
      displayText: 'Europe/Brussels'
    },
    {
      key: '(GMT+01:00) Budapest',
      displayText: 'Europe/Budapest'
    },
    {
      key: '(GMT+01:00) Copenhagen',
      displayText: 'Europe/Copenhagen'
    },
    {
      key: '(GMT+01:00) Ljubljana',
      displayText: 'Europe/Ljubljana'
    },
    {
      key: '(GMT+01:00) Madrid',
      displayText: 'Europe/Madrid'
    },
    {
      key: '(GMT+01:00) Paris',
      displayText: 'Europe/Paris'
    },
    {
      key: '(GMT+01:00) Prague',
      displayText: 'Europe/Prague'
    },
    {
      key: '(GMT+01:00) Rome',
      displayText: 'Europe/Rome'
    },
    {
      key: '(GMT+01:00) Sarajevo',
      displayText: 'Europe/Sarajevo'
    },
    {
      key: '(GMT+01:00) Skopje',
      displayText: 'Europe/Skopje'
    },
    {
      key: '(GMT+01:00) Stockholm',
      displayText: 'Europe/Stockholm'
    },
    {
      key: '(GMT+01:00) Vienna',
      displayText: 'Europe/Vienna'
    },
    {
      key: '(GMT+01:00) Warsaw',
      displayText: 'Europe/Warsaw'
    },
    {
      key: '(GMT+01:00) Zagreb',
      displayText: 'Europe/Zagreb'
    },
    {
      key: '(GMT+02:00) Athens',
      displayText: 'Europe/Athens'
    },
    {
      key: '(GMT+02:00) Bucharest',
      displayText: 'Europe/Bucharest'
    },
    {
      key: '(GMT+02:00) Cairo',
      displayText: 'Africa/Cairo'
    },
    {
      key: '(GMT+02:00) Harare',
      displayText: 'Africa/Harare'
    },
    {
      key: '(GMT+02:00) Helsinki',
      displayText: 'Europe/Helsinki'
    },
    {
      key: '(GMT+02:00) Istanbul',
      displayText: 'Europe/Istanbul'
    },
    {
      key: '(GMT+02:00) Jerusalem',
      displayText: 'Asia/Jerusalem'
    },
    {
      key: '(GMT+02:00) Kyiv',
      displayText: 'Europe/Kiev'
    },
    {
      key: '(GMT+02:00) Minsk',
      displayText: 'Europe/Minsk'
    },
    {
      key: '(GMT+02:00) Riga',
      displayText: 'Europe/Riga'
    },
    {
      key: '(GMT+02:00) Sofia',
      displayText: 'Europe/Sofia'
    },
    {
      key: '(GMT+02:00) Tallinn',
      displayText: 'Europe/Tallinn'
    },
    {
      key: '(GMT+02:00) Vilnius',
      displayText: 'Europe/Vilnius'
    },
    {
      key: '(GMT+03:00) Baghdad',
      displayText: 'Asia/Baghdad'
    },
    {
      key: '(GMT+03:00) Kuwait',
      displayText: 'Asia/Kuwait'
    },
    {
      key: '(GMT+03:00) Nairobi',
      displayText: 'Africa/Nairobi'
    },
    {
      key: '(GMT+03:00) Riyadh',
      displayText: 'Asia/Riyadh'
    },
    {
      key: '(GMT+03:00) Moscow',
      displayText: 'Europe/Moscow'
    },
    {
      key: '(GMT+03:30) Tehran',
      displayText: 'Asia/Tehran'
    },
    {
      key: '(GMT+04:00) Baku',
      displayText: 'Asia/Baku'
    },
    {
      key: '(GMT+04:00) Volgograd',
      displayText: 'Europe/Volgograd'
    },
    {
      key: '(GMT+04:00) Muscat',
      displayText: 'Asia/Muscat'
    },
    {
      key: '(GMT+04:00) Tbilisi',
      displayText: 'Asia/Tbilisi'
    },
    {
      key: '(GMT+04:00) Yerevan',
      displayText: 'Asia/Yerevan'
    },
    {
      key: '(GMT+04:30) Kabul',
      displayText: 'Asia/Kabul'
    },
    {
      key: '(GMT+05:00) Karachi',
      displayText: 'Asia/Karachi'
    },
    {
      key: '(GMT+05:00) Tashkent',
      displayText: 'Asia/Tashkent'
    },
    {
      key: '(GMT+05:30) Kolkata',
      displayText: 'Asia/Kolkata'
    },
    {
      key: '(GMT+05:45) Kathmandu',
      displayText: 'Asia/Kathmandu'
    },
    {
      key: '(GMT+06:00) Ekaterinburg',
      displayText: 'Asia/Yekaterinburg'
    },
    {
      key: '(GMT+06:00) Almaty',
      displayText: 'Asia/Almaty'
    },
    {
      key: '(GMT+06:00) Dhaka',
      displayText: 'Asia/Dhaka'
    },
    {
      key: '(GMT+07:00) Novosibirsk',
      displayText: 'Asia/Novosibirsk'
    },
    {
      key: '(GMT+07:00) Bangkok',
      displayText: 'Asia/Bangkok'
    },
    {
      key: '(GMT+07:00) Jakarta',
      displayText: 'Asia/Jakarta'
    },
    {
      key: '(GMT+08:00) Krasnoyarsk',
      displayText: 'Asia/Krasnoyarsk'
    },
    {
      key: '(GMT+08:00) Chongqing',
      displayText: 'Asia/Chongqing'
    },
    {
      key: '(GMT+08:00) Hong Kong',
      displayText: 'Asia/Hong_Kong'
    },
    {
      key: '(GMT+08:00) Kuala Lumpur',
      displayText: 'Asia/Kuala_Lumpur'
    },
    {
      key: '(GMT+08:00) Perth',
      displayText: 'Australia/Perth'
    },
    {
      key: '(GMT+08:00) Singapore',
      displayText: 'Asia/Singapore'
    },
    {
      key: '(GMT+08:00) Taipei',
      displayText: 'Asia/Taipei'
    },
    {
      key: '(GMT+08:00) Ulaan Bataar',
      displayText: 'Asia/Ulaanbaatar'
    },
    {
      key: '(GMT+08:00) Urumqi',
      displayText: 'Asia/Urumqi'
    },
    {
      key: '(GMT+09:00) Irkutsk',
      displayText: 'Asia/Irkutsk'
    },
    {
      key: '(GMT+09:00) Seoul',
      displayText: 'Asia/Seoul'
    },
    {
      key: '(GMT+09:00) Tokyo',
      displayText: 'Asia/Tokyo'
    },
    {
      key: '(GMT+09:30) Adelaide',
      displayText: 'Australia/Adelaide'
    },
    {
      key: '(GMT+09:30) Darwin',
      displayText: 'Australia/Darwin'
    },
    {
      key: '(GMT+10:00) Yakutsk',
      displayText: 'Asia/Yakutsk'
    },
    {
      key: '(GMT+10:00) Brisbane',
      displayText: 'Australia/Brisbane'
    },
    {
      key: '(GMT+10:00) Canberra',
      displayText: 'Australia/Canberra'
    },
    {
      key: '(GMT+10:00) Guam',
      displayText: 'Pacific/Guam'
    },
    {
      key: '(GMT+10:00) Hobart',
      displayText: 'Australia/Hobart'
    },
    {
      key: '(GMT+10:00) Melbourne',
      displayText: 'Australia/Melbourne'
    },
    {
      key: '(GMT+10:00) Port Moresby',
      displayText: 'Pacific/Port_Moresby'
    },
    {
      key: '(GMT+10:00) Sydney',
      displayText: 'Australia/Sydney'
    },
    {
      key: '(GMT+11:00) Vladivostok',
      displayText: 'Asia/Vladivostok'
    },
    {
      key: '(GMT+12:00) Magadan',
      displayText: 'Asia/Magadan'
    },
    {
      key: '(GMT+12:00) Auckland',
      displayText: 'Pacific/Auckland'
    },
    {
      key: '(GMT+12:00) Fiji',
      displayText: 'Pacific/Fiji'
    }
  ];

  static tzCode = (tzText: string): string => {
    if (Utils.notNullOrEmpty(tzText)) {
      return (
        TimezoneConstants.tzOptions.find(
          (tz) =>
            Utils.equalsIgnoreCase(tz.key, tzText) ||
            Utils.equalsIgnoreCase(tz.displayText, tzText)
        ) || { displayText: moment.tz.guess() }
      ).displayText;
    }
    return moment.tz.guess();
  };
}
