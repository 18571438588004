<dx-popup
  [width]="showDetails ? '80%' : 370"
  [height]="showDetails ? '70%' : 300"
  [title]="title"
  [showCloseButton]="true"
  [dragEnabled]="true"
  [resizeEnabled]="true"
  [shading]="true"
  [visible]="visible"
  (onHidden)="onPopupHidden()"
>
  <div *dxTemplate="let data of 'title'" class="cv-message-title-content">
    <div class="cv-message-title-text">
      <i *ngIf="iconType" aria-hidden="true" [ngClass]="iconType"></i>
      <span class="cv-title-text" [innerHTML]="title | safeHtml"></span>
    </div>
    <div>
      <dx-button
        type="normal"
        stylingMode="text"
        hint="Close"
        [icon]="iconClose"
        (onClick)="onPopupHidden()"
      ></dx-button>
    </div>
  </div>
  <div *dxTemplate="let data of 'content'" class="cv-message-content">
    <dx-scroll-view>
      <div [innerHTML]="message | safeHtml"></div>
      <br *ngIf="showDetails" />
      <div *ngIf="showDetails" [innerHTML]="details | safeHtml"></div>
    </dx-scroll-view>
  </div>
  <dxi-toolbar-item
    toolbar="bottom"
    location="center"
    widget="dxButton"
    [options]="{
      type: 'normal',
      text: 'Details',
      hint: showDetails ? 'Hide Details' : 'Show Details',
      icon: showDetails ? iconHide : iconView,
      visible: !!details && !!details.length,
      onClick: onToggleDetails
    }"
  ></dxi-toolbar-item>
  <dxi-toolbar-item
    toolbar="bottom"
    location="center"
    widget="dxButton"
    [options]="{
      type: 'default',
      text: 'OK',
      hint: 'Dismiss',
      icon: iconOk,
      onClick: onPopupHidden
    }"
  ></dxi-toolbar-item>
</dx-popup>
