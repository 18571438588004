import { OrganizationRawDto } from './organization-raw.dto';

export class OrganizationResultDto extends OrganizationRawDto {
  stateName: string;
  countryName: string;
  organizationType: string;
  isDeleted: boolean;

  get addressText(): string {
    return `${this.cityName}, ${this.stateName}, ${this.countryName} - ${this.postalCode}`;
  }
}
