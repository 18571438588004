import { Utils } from '../../_utils/utils';

export class BaseUserInfoResultDto {
  token: string;
  email: string;
  lastName: string;
  firstName: string;
  environment: string;

  get displayName(): string {
    return Utils.notNullOrEmpty(this.firstName)
      ? `${this.firstName} ${this.lastName || ''}`.trim()
      : this.email;
  }
}
