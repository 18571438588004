// Angular imports
import { TitleCasePipe } from '@angular/common';
import { Component, Input } from '@angular/core';

// Constant imports
import { IconConstants } from '../../../_constants/icon.constants';

@Component({
  selector: 'app-check-cell-template',
  templateUrl: './check-cell-template.component.html',
  styleUrls: ['./check-cell-template.component.scss'],
  providers: [TitleCasePipe]
})
export class CheckCellTemplateComponent {
  @Input() value: boolean;
  @Input() tooltipText: string;
  @Input() showUncheck = true;

  iconCheck = IconConstants.CHECK;
  iconCross = IconConstants.CROSS;
}
