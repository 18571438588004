// Angular imports
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';

// Dx imports
import { DxPopupComponent } from 'devextreme-angular/ui/popup';

// Util imports
import { Utils } from '../../../_utils/utils';

// Constant imports
import { IconConstants } from '../../../_constants/icon.constants';

@Component({
  selector: 'app-message-panel',
  templateUrl: './message-panel.component.html',
  styleUrls: ['./message-panel.component.scss']
})
export class MessagePanelComponent implements OnInit {
  @ViewChild('messagePopup', { static: false }) messagePopup: DxPopupComponent;

  @Input() type: 'info' | 'warning' | 'error' | 'success' | 'none' = 'none';
  @Input() title: string;
  @Input() message: string;
  @Input() details: string;
  @Input() visible: boolean;
  @Output() onHidden = new EventEmitter<boolean>();

  iconOk = IconConstants.OK;
  iconClose = IconConstants.CLOSE;
  iconView = IconConstants.VIEW;
  iconHide = IconConstants.HIDE;

  showDetails = false;
  iconType: string;

  constructor() {
    this.onPopupHidden = this.onPopupHidden.bind(this);
    this.onToggleDetails = this.onToggleDetails.bind(this);
  }

  ngOnInit(): void {
    switch (this.type) {
      case 'info':
        this.iconType = `${IconConstants.INFO} dx-theme-accent-as-text-color`;
        break;
      case 'warning':
        this.iconType = `${IconConstants.WARN} cc-theme-warn-color`;
        break;
      case 'error':
        this.iconType = `${IconConstants.ERROR} cc-theme-error-color`;
        break;
      case 'success':
        this.iconType = `${IconConstants.SUCCESS} cc-theme-success-color`;
        break;
      default:
        this.iconType = IconConstants.NONE;
        break;
    }
  }

  onPopupHidden(): void {
    this.visible = false;
    this.onHidden.emit(false);
  }

  onToggleDetails(): void {
    this.showDetails = Utils.isFalse(this.showDetails);
  }
}
