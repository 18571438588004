// Angular imports
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

// Util imports
import { Utils } from '../../../_utils/utils';

@Component({
  selector: 'app-colored-label',
  templateUrl: './colored-label.component.html'
})
export class ColoredLabelComponent implements OnInit {
  @Input() icon: string;
  @Input() text: string;
  @Input() type: 'default' | 'normal' | 'success' | 'danger' = 'normal';
  @Input() size: number;
  @Input() weight: number;
  @Input() iconPlacement: 'left' | 'right' = 'left';
  @Output() onIconClick = new EventEmitter<Event>();

  iconStyle = {};
  labelStyle = {};

  ngOnInit(): void {
    this.iconStyle = {
      fontSize: `${this.size}rem`,
      paddingRight: `0.5rem`
    };
    if (Utils.equalsIgnoreCase(this.iconPlacement, 'right')) {
      this.iconStyle = {
        fontSize: `${this.size}rem`,
        paddingLeft: `0.5rem`
      };
    }
    this.labelStyle = {
      fontSize: `${this.size}rem`,
      fontWeight: `${this.weight}`
    };
  }
}
