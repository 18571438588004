// Angular imports
import { Directive, HostListener } from '@angular/core';

// Util imports
import { DateUtils } from '../../_utils/date.utils';

// Constant imports
import { FormatterConstants } from '../../_constants/formatter.constants';

@Directive({
  selector: '[appDateBoxDateOnly]'
})
export class DateBoxDateOnlyDirective {
  @HostListener('onInitialized', ['$event'])
  onInitialized(e: any): void {
    e.component.option({
      type: 'date',
      displayFormat: {
        parser: (value: string) => {
          const parts = value.split('/').filter((d) => !!d);
          if (3 === parts.length) {
            const currentYear = new Date()
              .getFullYear()
              .toString()
              .substring(0, 2);
            return new Date(
              parts[2].length < 3
                ? Number(`${currentYear}${parts[2]}`)
                : Number(parts[2]),
              Number(parts[0]) - 1,
              Number(parts[1])
            );
          } else if (2 === parts.length) {
            const currentYear = new Date().getFullYear();
            return new Date(
              currentYear,
              Number(parts[0]) - 1,
              Number(parts[1])
            );
          }
          return null;
        },
        formatter: (value: Date) => {
          return DateUtils.dateToString(value, FormatterConstants.MOMENT_DATE);
        }
      }
    });
  }
}
