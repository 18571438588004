// Angular imports
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-html-viewer',
  templateUrl: './html-viewer.component.html',
  styleUrls: ['./html-viewer.component.scss']
})
export class HtmlViewerComponent {
  @Input() title: string;
  @Input() htmlText: string;
  @Output() onClose = new EventEmitter<void>();
}
