<app-page-popup
  *ngIf="!!fileDataUrl"
  [title]="title"
  [visible]="true"
  [buttons]="['Close']"
  (onHidden)="onClose.emit()"
>
  <div
    class="cv-video-viewer cv-fullsize-content cv-round-border dx-theme-border-color cc-theme-secondary-background"
  >
    <video width="100%" height="100%" controls preload="metadata">
      <source [src]="fileDataUrl | safeResource" [type]="fileType" />
    </video>
  </div>
</app-page-popup>
