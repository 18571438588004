<dx-text-box
  #textbox
  appTextBoxFileInput
  [isReadOnly]="readOnly"
  [placeholder]="placeholder"
  [value]="fixedFilename || fileInfo?.filename"
  [fileInfo]="fileInfo"
  [deleteEnabled]="deleteEnabled"
  [downloadEnabled]="downloadEnabled"
  (onViewClick)="onView.emit(fileInfo)"
  (onDownloadClick)="onDownloadClick()"
  (onClearClick)="onClearClick()"
  (onBrowseClick)="onBrowseClick()"
>
  <dx-validator *ngIf="isRequired" [validationGroup]="validationGroup">
    <dxi-validation-rule
      type="required"
      message="{{ placeholder }} is Required"
    ></dxi-validation-rule>
  </dx-validator>
</dx-text-box>

<dx-file-uploader
  #uploader
  uploadMode="useForm"
  labelText=""
  selectButtonText=""
  [maxFileSize]="maxFileSize"
  [accept]="acceptedMineTypes"
  [allowedFileExtensions]="allowedExtensions"
  [visible]="false"
  [multiple]="true"
  [showFileList]="false"
  [allowCanceling]="true"
  [elementAttr]="{ class: 'cv-hidden-box' }"
  (onValueChanged)="onFileChanged($event)"
></dx-file-uploader>
