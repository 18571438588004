// Angular imports
import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';

// Dx imports
import { DxValidatorComponent } from 'devextreme-angular/ui/validator';

// Util imports
import { Utils } from '../../../_utils/utils';

// Constant imports
import { ActionConstants } from '../../../_constants/action.constants';

@Component({
  selector: 'app-filename-prompt',
  templateUrl: './filename-prompt.component.html',
  styleUrls: ['./filename-prompt.component.scss']
})
export class FilenamePromptComponent {
  @ViewChild('validator', { static: false }) validator: DxValidatorComponent;

  @Input() filename: string;
  @Output() onOk = new EventEmitter<string>();
  @Output() onClose = new EventEmitter<void>();

  onButtonClick(name: string): void {
    if (Utils.equalsIgnoreCase(name, ActionConstants.OK)) {
      this.validator.instance.validate();
      if (Utils.notNullOrEmpty(this.filename)) {
        this.onOk.emit(this.filename);
        this.onClose.emit();
      }
    } else {
      this.onClose.emit();
    }
  }
}
