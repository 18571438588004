export const AssetImage = (name: string) => `assets/images/${name}`;

export const LoaderImageSvg = (name: string) =>
  `assets/images/loaders/${name}.svg`;

export class ImageConstants {
  static readonly CIRA_NET = AssetImage('ciranet.svg');
  static readonly CIRA_CONNECT = AssetImage('ciraconnect.png');
  static readonly CIRA_LOGO = AssetImage('ciralogo.png');
  static readonly CIRA_VOTE = AssetImage('ciravote.png');
  static readonly PROFILE_USER = AssetImage('user.png');
  static readonly WRITE_IN_USER = AssetImage('user-edit.png');
  static readonly ABSTAINED = AssetImage('abstained.png');
  static readonly AUTH_BG = AssetImage('user-auth-bg.png');
  static readonly PARTICIPANT_AUTH_BG = AssetImage('participant-auth-bg.png');
  static readonly VOTE = AssetImage('vote.png');
  static readonly SURVEY = AssetImage('survey.png');
  static readonly POWERED_BY = AssetImage('poweredby.png');
}
