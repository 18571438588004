// Angular imports
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

// 3rd party imports
import { JwtHelperService } from '@auth0/angular-jwt';
import { instanceToPlain, plainToInstance } from 'class-transformer';

// Rxjs imports
import {
  BehaviorSubject,
  catchError,
  delay,
  map,
  Observable,
  of,
  Subscription,
  tap
} from 'rxjs';

// Util imports
import { Utils } from '../_utils/utils';

// Constant imports
import { AppDefaultConstants } from '../_constants/app-default.constants';
import { StorageKeyConstants } from '../_constants/storage-key.constants';
import { UrlConstants } from '../_constants/url.constants';

// Model imports
import { ParticipantInfoResultDto } from '../_models/participant/participant-info-result.dto';
import { ParticipantLoginParameterDto } from '../_models/participant/participant-login-parameter.dto';
import { UserInfoResultDto } from '../_models/user/user-info-result.dto';
import { UserLoginParameterDto } from '../_models/user/user-login-parameter.dto';
import { ForgotPasswordTokenParameterDto } from '../auth-module/_models/forgot-password-token-parameter.dto';

@Injectable({ providedIn: 'root' })
export class AuthService {
  private readonly _subjectLogin = new BehaviorSubject<boolean>(false);
  private readonly _subjectParticipantLogin = new BehaviorSubject<boolean>(
    false
  );

  constructor(
    private readonly httpClient: HttpClient,
    private readonly jwtHelper: JwtHelperService
  ) {}

  onUserLogin(handler: (flag: boolean) => void, delayMillis = 0): Subscription {
    return this._subjectLogin
      .pipe(
        delay(delayMillis),
        tap((flag: boolean) => handler(flag))
      )
      .subscribe();
  }

  onParticipantLogin(
    handler: (flag: boolean) => void,
    delayMillis = 0
  ): Subscription {
    return this._subjectParticipantLogin
      .pipe(
        delay(delayMillis),
        tap((flag: boolean) => handler(flag))
      )
      .subscribe();
  }

  doUserLogin(params: UserLoginParameterDto): Observable<boolean> {
    const url = `${UrlConstants.AUTH_BASE_URL}/login`;
    return this.httpClient.post(url, instanceToPlain(params)).pipe(
      tap((plain: any) => {
        const userInfo = plainToInstance(UserInfoResultDto, plain);
        const decoded = this.jwtHelper.decodeToken(userInfo.token);
        if (Utils.notNullOrEmpty(decoded.role)) {
          userInfo.userRoles = decoded.role.toUpperCase().split(',');
        }
        localStorage.setItem(StorageKeyConstants.USER_TOKEN, userInfo.token);
        localStorage.setItem(
          StorageKeyConstants.USER_INFO,
          JSON.stringify(userInfo)
        );
      }),
      map((plain: any) => {
        const flag = Utils.notNullOrEmpty(plain);
        this._subjectLogin.next(flag);
        return flag;
      }),
      catchError(() => of(false))
    );
  }

  doUserLogout(): void {
    localStorage.removeItem(StorageKeyConstants.USER_INFO);
    localStorage.removeItem(StorageKeyConstants.USER_TOKEN);
    localStorage.removeItem(StorageKeyConstants.USER_ORGANIZATION);
    this._subjectLogin.next(false);
  }

  doParticipantLogin(
    url: string,
    params: ParticipantLoginParameterDto
  ): Observable<boolean> {
    return this.httpClient.post(url, instanceToPlain(params)).pipe(
      tap((plain: any) => {
        const userInfo = plainToInstance(ParticipantInfoResultDto, plain);
        localStorage.setItem(
          StorageKeyConstants.PARTCIPANT_TOKEN,
          userInfo.token
        );
        localStorage.setItem(
          StorageKeyConstants.PARTCIPANT_INFO,
          JSON.stringify(userInfo)
        );
      }),
      map((plain: any) => {
        const flag = Utils.notNullOrEmpty(plain);
        this._subjectParticipantLogin.next(flag);
        return flag;
      }),
      catchError((response: HttpErrorResponse) => {
        const needRecaptcha =
          Utils.notNullAndDefined(response.error) &&
          Utils.containsIgnoreCase(response.error.message, 'recaptcha');
        if (Utils.isTrue(needRecaptcha)) {
          const storageKey = Utils.getLoginAttemptStorageKey(
            params.eventId,
            params.email
          );
          localStorage.setItem(
            storageKey,
            AppDefaultConstants.ALLOWED_INVALID_LOGIN_ATTEMPT.toString()
          );
        }
        return of(false);
      })
    );
  }

  doParticipantLogout(): void {
    localStorage.removeItem(StorageKeyConstants.PARTCIPANT_INFO);
    localStorage.removeItem(StorageKeyConstants.PARTCIPANT_TOKEN);
    this._subjectParticipantLogin.next(false);
  }

  hasUserLoggedIn(delayMillis = 0): void {
    const plain = localStorage.getItem(StorageKeyConstants.USER_INFO);
    if (Utils.notNullOrEmpty(plain)) {
      setTimeout(() => {
        const userInfo = plainToInstance(UserInfoResultDto, JSON.parse(plain));
        if (this.jwtHelper.isTokenExpired(userInfo.token)) {
          this.doUserLogout();
        } else {
          this._subjectLogin.next(true);
        }
      }, delayMillis);
    }
  }

  hasParticipantLoggedIn(delayMillis = 0): void {
    const plain = localStorage.getItem(StorageKeyConstants.PARTCIPANT_INFO);
    if (Utils.notNullOrEmpty(plain)) {
      setTimeout(() => {
        const userInfo = plainToInstance(
          ParticipantInfoResultDto,
          JSON.parse(plain)
        );
        if (this.jwtHelper.isTokenExpired(userInfo.token)) {
          this.doParticipantLogout();
        } else {
          this._subjectParticipantLogin.next(true);
        }
      }, delayMillis);
    }
  }

  forgotPassword(params: ForgotPasswordTokenParameterDto): Observable<void> {
    const url = `${UrlConstants.AUTH_BASE_URL}/forgotpassword`;
    return this.httpClient.post<void>(url, instanceToPlain(params));
  }

  isUserLoggedIn(): boolean {
    return this._subjectLogin.getValue();
  }

  isParticipantLoggedIn(): boolean {
    return this._subjectParticipantLogin.getValue();
  }
}
