// Angular imports
import { Component, EventEmitter, Input, Output } from '@angular/core';

// Constant imports
import { IconConstants } from '../../../_constants/icon.constants';

@Component({
  selector: 'app-page-title',
  templateUrl: './page-title.component.html',
  styleUrls: ['./page-title.component.scss']
})
export class PageTitleComponent {
  @Input() title: string;
  @Input() helpKey: string;
  @Output() onHelpClick = new EventEmitter<string>();

  iconHelp = IconConstants.HELP;
}
