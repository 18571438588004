// Angular imports
import { Component, EventEmitter, Input, Output } from '@angular/core';

// Constant imports
import { IconConstants } from '../../../_constants/icon.constants';

@Component({
  selector: 'app-notes-cell-template',
  templateUrl: './notes-cell-template.component.html',
  styleUrls: ['./notes-cell-template.component.scss']
})
export class NotesCellTemplateComponent {
  @Input() hasNotes: boolean;
  @Output() onClick = new EventEmitter<void>();

  iconNotes = IconConstants.NOTES;
  iconEmptyNotes = IconConstants.NOTES_EMPTY;
}
