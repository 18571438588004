<dx-pie-chart
  *ngIf="value !== 100"
  #pieChart
  type="doughnut"
  [startAngle]="90"
  [innerRadius]="0.6"
  [sizeGroup]="sizeGroup"
  [size]="{ width: size, height: size }"
  [customizePoint]="customizePoint"
  [dataSource]="dataSource"
  [elementAttr]="{ title: tooltip }"
>
  <dxi-series
    selectionMode="none"
    argumentField="label"
    valueField="value"
  ></dxi-series>
  <dxo-legend [visible]="false"></dxo-legend>
  <dxo-tooltip [enabled]="false"></dxo-tooltip>
</dx-pie-chart>
<i
  *ngIf="value === 100"
  aria-hidden="true"
  class="cc-theme-success-color cv-hyperlink"
  [title]="tooltip"
  [ngClass]="iconComplete"
></i>
<!-- Hack to grab color for chart from CSS class -->
<div id="theme-element" class="cc-theme-sidenav-selected"></div>
