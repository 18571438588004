// 3rd party imports
import { TransformFnParams } from 'class-transformer';
import * as _moment_ from 'moment-timezone';

// Util imports
import { Utils } from './utils';

// Constant imports
import { FormatterConstants } from '../_constants/formatter.constants';
import { TimezoneConstants } from '../_constants/timzones.contants';

// Declared to use in static methods
const moment = _moment_;

export class DateUtils {
  static addMinutes(date: Date | string, numMins: number): Date {
    return moment(date).add(numMins, 'minutes').toDate();
  }

  static addDays(date: Date | string, numDays: number): Date {
    return moment(date).add(numDays, 'days').toDate();
  }

  static addMonths(date: Date | string, numMonths: number): Date {
    return moment(date).add(numMonths, 'months').toDate();
  }

  static addYears(date: Date | string, numYears: number): Date {
    return moment(date).add(numYears, 'years').toDate();
  }

  static removeDays(date: Date | string, numDays: number): Date {
    return moment(date)
      .add(-1 * numDays, 'days')
      .toDate();
  }

  static removeMonths(date: Date | string, numMonths: number): Date {
    return moment(date)
      .add(-1 * numMonths, 'months')
      .toDate();
  }

  static removeYears(date: Date | string, numYears: number): Date {
    return moment(date)
      .add(-1 * numYears, 'years')
      .toDate();
  }

  static startOfDay(date: Date | string): Date {
    return moment(date).startOf('day').toDate();
  }

  static endOfDay(date: Date | string): Date {
    return moment(date).endOf('day').toDate();
  }

  static firstDayOfMonth(date: Date | string): Date {
    return moment(date).startOf('month').toDate();
  }

  static lastDayOfMonth(date: Date | string): Date {
    return moment(date).endOf('month').toDate();
  }

  static firstDayOfYear(date: Date | string): Date {
    return moment(date).startOf('year').toDate();
  }

  static lastDayOfYear(date: Date | string): Date {
    return moment(date).endOf('year').toDate();
  }

  static dateToString(date: Date | string, format: string): string {
    return moment(date).format(format);
  }

  static stringToDate(value: Date | string, format: string): Date {
    return moment(value, format).toDate();
  }

  static differenceInDays(date1: Date | string, date2: Date | string): number {
    return moment(date2).diff(moment(date1), 'days');
  }

  static differenceIn(
    date1: Date | string,
    date2: Date | string,
    diffIn: moment.unitOfTime.Diff
  ): number {
    return moment(date2).diff(moment(date1), diffIn);
  }

  static isAfter(date1: Date | string, date2: Date | string): boolean {
    return moment(date1).isAfter(date2);
  }

  static isSameOrAfter(date1: Date | string, date2: Date | string): boolean {
    return moment(date1).isSameOrAfter(date2);
  }

  static isBefore(date1: Date | string, date2: Date | string): boolean {
    return moment(date1).isBefore(date2);
  }

  static isSameOrBefore(date1: Date | string, date2: Date | string): boolean {
    return moment(date1).isSameOrBefore(date2);
  }

  static isBetween(
    date: Date | string,
    date1: Date | string,
    date2: Date | string
  ): boolean {
    return (
      DateUtils.isSameOrAfter(date, date1) &&
      DateUtils.isSameOrBefore(date, date2)
    );
  }

  static toJsonDateTime(params: TransformFnParams): string {
    return Utils.notNullAndDefined(params.value)
      ? DateUtils.dateToString(
          params.value,
          FormatterConstants.MOMENT_DATETIME_ISO
        )
      : params.value;
  }

  static toJsonDateOnly(params: TransformFnParams): string {
    return Utils.notNullAndDefined(params.value)
      ? DateUtils.dateToString(
          DateUtils.endOfDay(params.value),
          FormatterConstants.MOMENT_DATE_ISO
        )
      : params.value;
  }

  static toJsonTzDateTime(params: TransformFnParams): string {
    const tzCode = TimezoneConstants.tzCode(params.obj.eventTimeZone);
    return Utils.notNullAndDefined(params.value)
      ? DateUtils.dateToString(
          DateUtils.toTzDateTime(params.value, tzCode),
          FormatterConstants.MOMENT_DATETIME_ISO
        )
      : params.value;
  }

  static toJsonLocalDateTime(params: TransformFnParams): Date {
    const tzCode = TimezoneConstants.tzCode(params.obj.eventTimeZone);
    return Utils.notNullAndDefined(params.value)
      ? DateUtils.toLocalDateTime(params.value, tzCode)
      : params.value;
  }

  static toJsonTzDateOnly(params: TransformFnParams): string {
    const tzCode = TimezoneConstants.tzCode(params.obj.eventTimeZone);
    return Utils.notNullAndDefined(params.value)
      ? DateUtils.dateToString(
          DateUtils.toTzDateTime(DateUtils.endOfDay(params.value), tzCode),
          FormatterConstants.MOMENT_DATE_ISO
        )
      : params.value;
  }

  static toJsonLocalDateOnly(params: TransformFnParams): Date {
    const tzCode = TimezoneConstants.tzCode(params.obj.eventTimeZone);
    return Utils.notNullAndDefined(params.value)
      ? DateUtils.toLocalDateOnly(params.value, tzCode)
      : params.value;
  }

  static toTzDateTime(date: Date | string, tzone: string): Date {
    return Utils.notNullAndDefined(date)
      ? moment
          .tz(
            DateUtils.dateToString(
              date,
              FormatterConstants.MOMENT_DATETIME_ISO
            ),
            tzone
          )
          .toDate()
      : null;
  }

  static toTzDateOnly(date: Date | string, tzone: string): Date {
    return Utils.notNullAndDefined(date)
      ? moment
          .tz(
            DateUtils.dateToString(date, FormatterConstants.MOMENT_DATE_ISO),
            tzone
          )
          .toDate()
      : null;
  }

  static toLocalDateTime(utcIso: Date | string, tzone: string): Date {
    const tzLocal = DateUtils.getLocalTimezone();
    return Utils.notNullAndDefined(utcIso)
      ? moment
          .tz(
            moment
              .tz(utcIso, tzone)
              .format(FormatterConstants.MOMENT_DATETIME_ISO),
            tzLocal
          )
          .toDate()
      : null;
  }

  static toLocalDateOnly(date: Date | string, tzone: string): Date {
    const tzLocal = DateUtils.getLocalTimezone();
    return DateUtils.toTzDateOnly(DateUtils.toTzDateOnly(date, tzone), tzLocal);
  }

  static getLocalTimezone(): string {
    return moment.tz.guess();
  }

  static getLocalTimezoneName(): string {
    return moment.tz(moment.tz.guess()).zoneName();
  }
}
