<dx-box width="100%" height="100%" direction="col">
  <dxi-item baseSize="auto">
    <div *dxTemplate>
      <dx-tabs
        [width]="220"
        [items]="tabItems"
        [(selectedIndex)]="tabIndex"
        (onSelectionChanged)="onTabChanged()"
      ></dx-tabs>
    </div>
  </dxi-item>
  <dxi-item [ratio]="1">
    <div *dxTemplate class="cv-grid-container dx-theme-border-color">
      <dx-data-grid
        appDataGridFeatures
        width="100%"
        height="100%"
        [dataSource]="tabIndex === 0 ? appLogResults : jobLogResults"
      >
        <dxo-toolbar>
          <dxi-item location="before">
            <div *dxTemplate>
              <dx-select-box
                [width]="220"
                [visible]="tabIndex === 0"
                [dataSource]="appLogFiles"
                [(value)]="appLogFile"
                (onValueChanged)="onLogFileChanged()"
              ></dx-select-box>
              <dx-select-box
                [width]="220"
                [visible]="tabIndex === 1"
                [dataSource]="jobLogFiles"
                [(value)]="jobLogFile"
                (onValueChanged)="onLogFileChanged()"
              ></dx-select-box>
            </div>
          </dxi-item>
          <dxi-item location="after">
            <div *dxTemplate>
              <dx-button
                appButtonRefresh
                (onClick)="onRefreshClick()"
              ></dx-button>
            </div>
          </dxi-item>
        </dxo-toolbar>
        <dxi-column
          dataField="timestamp"
          dataType="date"
          sortOrder="desc"
          [sortIndex]="0"
          [format]="dateTimeFormat"
        ></dxi-column>
        <dxi-column dataField="level" dataType="string"></dxi-column>
        <dxi-column dataField="source" dataType="string"></dxi-column>
        <dxi-column
          alignment="center"
          caption="Information"
          cellTemplate="iconCellTemplate"
        >
          <div *dxTemplate="let cell of 'iconCellTemplate'">
            <app-icon-cell-template
              type="default"
              [icon]="iconInfo"
              [tooltipText]="cell.column.caption"
              (onClick)="onIconCellClick(cell.data)"
            ></app-icon-cell-template>
          </div>
        </dxi-column>
      </dx-data-grid>
    </div>
  </dxi-item>
</dx-box>

<app-page-popup
  *ngIf="showInfo"
  width="90%"
  height="95%"
  title="Log Information"
  [buttons]="['Close']"
  [visible]="true"
  (onHidden)="showInfo = false"
>
  <dx-scroll-view>
    <div class="cv-fullsize-content cv-center-content">
      <strong *ngIf="!!selectedRecord?.message" class="cv-1-1rx">
        Message
      </strong>
      <div *ngIf="!!selectedRecord?.message" class="cv-fullsize-content">
        {{ selectedRecord?.message }}
      </div>
      <div
        *ngIf="!!selectedRecord?.message"
        class="cv-vbox-item cv-hruler dx-theme-border-color"
      ></div>
      <strong class="cv-1-1rx">Details</strong>
      <div class="cv-fullsize-content">{{ selectedRecord?.details }}</div>
    </div>
  </dx-scroll-view>
</app-page-popup>
