// Angular imports
import { Component, OnDestroy, OnInit } from '@angular/core';

// Util imports
import { RxjsUtils } from '../../../_utils/rxjs.utils';

// Service imports
import { AuthService } from '../../../_services/auth.service';
import { HelperService } from '../../../_services/helper.service';

// Type imports
import { NavigationEntry } from '../../../_types/navigation-entry';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {
  isUserLoggedIn: boolean;
  showOrgSelector: boolean;
  dataSource: Array<NavigationEntry>;
  selectedItem: NavigationEntry;

  constructor(
    private readonly authService: AuthService,
    private readonly helperService: HelperService
  ) {}

  ngOnInit(): void {
    RxjsUtils.subscribes('HomeComponent', [
      this.authService.onUserLogin(this.updateUserLoggedIn.bind(this)),
      this.authService.onParticipantLogin(this.updateUserLoggedIn.bind(this))
    ]);
  }

  ngOnDestroy(): void {
    RxjsUtils.unsubscribe('HomeComponent');
  }

  onRouterActivate(component: any): void {
    this.helperService.routeActivated(component);
  }

  private updateUserLoggedIn(): void {
    this.isUserLoggedIn =
      this.authService.isUserLoggedIn() ||
      this.authService.isParticipantLoggedIn();
  }
}
