// Angular imports
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';

// Dx imports
import { DxPopupComponent } from 'devextreme-angular/ui/popup';
import { ResizeEndEvent } from 'devextreme/ui/popup';

// Util imports
import { DxWidgetUtils } from '../../../_utils/dx-widget.utils';
import { Utils } from '../../../_utils/utils';

// Type imports
import { dxToolbarItem } from '../../../_types/dx-toolbar-item';

@Component({
  selector: 'app-page-popup',
  templateUrl: './page-popup.component.html',
  styleUrls: ['./page-popup.component.scss']
})
export class PagePopupComponent implements OnInit {
  @ViewChild(DxPopupComponent, { static: false }) component: DxPopupComponent;

  @Input() closeOnly = false;
  @Input() container = 'body';
  @Input() width: string | number = 'auto';
  @Input() height: string | number = 'auto';
  @Input() minWidth: string | number = '350px';
  @Input() minHeight: string | number = '200px';
  @Input() maxWidth: string | number = '95%';
  @Input() maxHeight: string | number = '95%';
  // Possible values: 'Close', 'Cancel', 'Save', 'Submit', 'OK', 'Send', 'Generate'
  @Input() buttons: Array<string> = [];
  @Input() customButtons: Array<dxToolbarItem> = [];
  @Input() fullScreen: boolean;
  @Input() title: string;
  @Input() visible = false;
  @Input() isXSmall = false;
  @Output() onHidden = new EventEmitter<void>();
  @Output() onResize = new EventEmitter<ResizeEndEvent>();
  @Output() onButtonClick = new EventEmitter<string>();

  ngOnInit(): void {
    if (Utils.isTrue(this.closeOnly)) {
      this.buttons = ['Close'];
      this.customButtons = [];
    }
  }

  isButtonVisible(name: string): boolean {
    return Utils.isInList(
      name.toLowerCase(),
      this.buttons.map((b) => b.toLowerCase())
    );
  }

  onShown(e: any): void {
    e.component.repaint();
  }

  onPopupHidden(): void {
    this.visible = false;
    this.onHidden.emit();
  }

  repaint(): void {
    DxWidgetUtils.instantRepaint(this.component);
  }
}
