// Angular imports
import { Directive, HostListener, Input } from '@angular/core';

// Constant imports
import { ActionConstants } from '../../_constants/action.constants';
import { IconConstants } from '../../_constants/icon.constants';

@Directive({
  selector: '[appButtonRefresh]'
})
export class ButtonRefreshDirective {
  @Input() text = ActionConstants.REFRESH;
  @Input() hint = 'Refresh / Reload';

  @HostListener('onInitialized', ['$event'])
  onInitialized(e: any): void {
    e.component.option({
      type: 'default',
      text: this.text,
      hint: this.hint,
      icon: IconConstants.REFRESH
    });
  }
}
