// Angular imports
import { Directive, HostListener, Input } from '@angular/core';

// Constant imports
import { ActionConstants } from '../../_constants/action.constants';
import { IconConstants } from '../../_constants/icon.constants';

@Directive({
  selector: '[appButtonGenerate]'
})
export class ButtonGenerateDirective {
  @Input() text = ActionConstants.GENERATE;
  @Input() hint = 'Save & Generate PDF';

  @HostListener('onInitialized', ['$event'])
  onInitialized(e: any): void {
    e.component.option({
      type: 'success',
      text: this.text,
      hint: this.hint,
      icon: IconConstants.SAVE
    });
  }
}
