<div
  *ngIf="!!organizationInfo"
  id="cv-organization-menu"
  class="cv-organization-menu cc-theme-sidenav-template dx-theme-border-color"
  [ngClass]="{ 'cv-hidden-box': !isOpened }"
>
  <dx-select-box
    width="100%"
    height="100%"
    fieldTemplate="field"
    valueExpr="organizationId"
    displayExpr="referredName"
    placeholder="Select an Organization"
    [wrapItemText]="true"
    [value]="organizationId"
    [dataSource]="organizations"
    (onOpened)="onSelectBoxOpened()"
    (onValueChanged)="onValueChanged($event)"
    (onSelectionChanged)="onSelectionChanged($event)"
  >
    <div *dxTemplate="let data of 'dropDownButton'">
      <div
        class="cv-fullsize-content cv-center-content cc-theme-sidenav-selected"
      >
        <i
          aria-hidden="true"
          class="cv-dropdown-icon"
          [ngClass]="iconArrowDown"
        ></i>
      </div>
    </div>
    <div
      *dxTemplate="let data of 'field'"
      class="cv-organization-menu-field cc-theme-sidenav-background-color"
    >
      <dx-text-area
        [readOnly]="true"
        [visible]="false"
        [inputAttr]="{ rows: 3 }"
      ></dx-text-area>
      <div class="cc-theme-sidenav-template">
        <div class="cv-organization-menu-text-1 cc-theme-sidenav-selected">
          {{ data?.referredName }}
          <sup>
            <small>
              <i
                *ngIf="userInfo.organizationId === data?.organizationId"
                aria-hidden="true"
                title="My Organization"
                [ngClass]="iconMyHome"
              ></i>
            </small>
          </sup>
        </div>
        <div class="cv-organization-menu-text-2">
          {{ data?.addressText }}
        </div>
      </div>
    </div>
    <div
      *dxTemplate="let data of 'item'"
      class="cv-organization-menu-item cc-theme-sidenav-background-color dx-theme-border-color"
    >
      <div class="cc-theme-sidenav-template">
        <div class="cv-organization-menu-text-1 cc-theme-sidenav-selected">
          {{ data?.referredName }}
          <sup>
            <small>
              <i
                *ngIf="userInfo.organizationId === data?.organizationId"
                aria-hidden="true"
                title="My Organization"
                [ngClass]="iconMyHome"
              ></i>
            </small>
          </sup>
        </div>
        <div class="cv-organization-menu-text-2">
          {{ data?.addressText }}
        </div>
      </div>
    </div>
  </dx-select-box>
</div>

<app-page-popup
  *ngIf="showSelector"
  width="30%"
  height="90%"
  title="Choose Default Organization"
  [visible]="true"
  (onHidden)="onSelectOrganization({ itemData: organizationInfo })"
>
  <dx-list
    width="100%"
    height="100%"
    displayExpr="name"
    searchExpr="name"
    noDataText="No matching organizations"
    selectionMode="single"
    [searchEnabled]="true"
    [dataSource]="organizations"
    (onItemClick)="onSelectOrganization($event)"
  >
    <div
      *dxTemplate="let data of 'item'"
      class="cv-organization-menu-item dx-theme-border-color"
    >
      <div class="cv-organization-menu-text-1">
        {{ data?.referredName }}
        <sup>
          <small>
            <i
              *ngIf="userInfo.organizationId === data?.organizationId"
              aria-hidden="true"
              title="My Organization"
              [ngClass]="iconMyHome"
            ></i>
          </small>
        </sup>
      </div>
      <div class="cv-organization-menu-text-2">
        {{ data?.addressText }}
      </div>
    </div>
  </dx-list>
</app-page-popup>
