<dx-drawer
  position="left"
  template="template"
  [minSize]="isSmall ? 0 : 60"
  [maxSize]="320"
  [(opened)]="isOpened"
  [revealMode]="revealMode"
  [openedStateMode]="openedStateMode"
  [closeOnOutsideClick]="isOpenTemporary"
  (openedChange)="onOpenedChange($event)"
>
  <div
    *dxTemplate="let tpl of 'template'"
    class="cv-drawer-template cc-theme-sidenav-background-color"
  >
    <ng-container
      [ngTemplateOutlet]="navigation"
      [ngTemplateOutletContext]="{
        isOpened: isOpened,
        onItemClick: onItemClick,
        onItemExpand: onItemExpand
      }"
    ></ng-container>
    <ng-content #navigation></ng-content>
  </div>
  <dx-scroll-view>
    <div
      id="drawer-content"
      class="cv-drawer-content"
      [style.height]="contentHeight"
    >
      <ng-container
        [ngTemplateOutlet]="content"
        [ngTemplateOutletContext]="{
          isOpened: isOpened,
          selectedItem: selectedItem,
          expandedItem: expandedItem
        }"
      ></ng-container>
      <ng-content #content></ng-content>
    </div>
  </dx-scroll-view>
</dx-drawer>
