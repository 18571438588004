<app-page-popup
  *ngIf="!!fileDataUrl"
  [title]="title"
  [visible]="true"
  [buttons]="['Close']"
  (onHidden)="onClose.emit()"
>
  <div
    class="cv-image-content cv-fullsize-content cv-center-content cv-round-border dx-theme-border-color cc-theme-secondary-background"
  >
    <img
      width="350px"
      height="350px"
      [alt]="title"
      [src]="fileDataUrl | safeResource"
    />
  </div>
</app-page-popup>
