// Angular imports
import { HttpHeaders } from '@angular/common/http';

// Dx imports
import { alert } from 'devextreme/ui/dialog';

// 3rd party imports
import * as CryptoJS from 'crypto-js';

// Constant imports
import { StorageKeyConstants } from '../_constants/storage-key.constants';

export class Utils {
  static emptyText(): string {
    return '';
  }

  static emptyList<T>(): Array<T> {
    return [];
  }

  static equals(value1: any, value2: any): boolean {
    value1 = Utils.notNullAndDefined(value1) ? value1 : '';
    value2 = Utils.notNullAndDefined(value2) ? value2 : '';
    if (value1 instanceof Date) {
      value1 = value1.getTime();
    }
    if (value2 instanceof Date) {
      value2 = value2.getTime();
    }
    return value1 === value2;
  }

  static notEquals(value1: any, value2: any): boolean {
    return Utils.isFalse(Utils.equals(value1, value2));
  }

  static equalsIgnoreCase(str1: string, str2: string): boolean {
    str1 = Utils.notNullAndDefined(str1)
      ? str1.toString().toLowerCase().trim()
      : '';
    str2 = Utils.notNullAndDefined(str2)
      ? str2.toString().toLowerCase().trim()
      : '';
    return str1 === str2;
  }

  static notEqualsIgnoreCase(value1: any, value2: any): boolean {
    return Utils.isFalse(Utils.equalsIgnoreCase(value1, value2));
  }

  static containsIgnoreCase(mainText: string, subText: string): boolean {
    mainText = Utils.notNullAndDefined(mainText)
      ? mainText.toLowerCase().trim()
      : '';
    subText = Utils.notNullAndDefined(subText)
      ? subText.toLowerCase().trim()
      : '';
    return mainText.search(subText) !== -1;
  }

  static startsWithIgnoreCase(mainText: string, subText: string): boolean {
    mainText = Utils.notNullAndDefined(mainText)
      ? mainText.toLowerCase().trim()
      : '';
    subText = Utils.notNullAndDefined(subText)
      ? subText.toLowerCase().trim()
      : '';
    return mainText.startsWith(subText);
  }

  static endsWithIgnoreCase(mainText: string, subText: string): boolean {
    mainText = Utils.notNullAndDefined(mainText)
      ? mainText.toLowerCase().trim()
      : '';
    subText = Utils.notNullAndDefined(subText)
      ? subText.toLowerCase().trim()
      : '';
    return mainText.endsWith(subText);
  }

  static isNullOrUndefined(value: any): boolean {
    return (
      value === null ||
      value === 'null' ||
      value === undefined ||
      value === 'undefined' ||
      typeof value === 'undefined'
    );
  }

  static notNullAndDefined(value: any): boolean {
    return Utils.isFalse(Utils.isNullOrUndefined(value)) && !!value;
  }

  static isNullOrEmpty(value: any): boolean {
    let isValid = Utils.isNullOrUndefined(value);
    if (Utils.isFalse(isValid)) {
      if (typeof value === 'string') {
        isValid = value.trim().length === 0;
      } else if (typeof value === 'number' || typeof value === 'bigint') {
        isValid = value === 0;
      } else if (value instanceof Date) {
        isValid = !!value;
      } else if (typeof value === 'object') {
        isValid = Object.keys(value).length === 0;
      }
    }
    return isValid;
  }

  static notNullOrEmpty(value: any): boolean {
    return Utils.isFalse(Utils.isNullOrEmpty(value));
  }

  static isValidNumber(num: number): boolean {
    return (
      Utils.isFalse(Utils.isNullOrUndefined(num)) && Utils.isFalse(isNaN(num))
    );
  }

  static isTrue(flag: boolean): boolean {
    return flag === true;
  }

  static isFalse(flag: boolean): boolean {
    return flag === false;
  }

  static isNull(input: any): boolean {
    return input === null;
  }

  static isUndefined(input: any): boolean {
    return typeof input === 'undefined';
  }

  static isDefined(input: any): boolean {
    return Utils.isFalse(this.isUndefined(input));
  }

  static isNotNull(input: any): boolean {
    return Utils.isFalse(this.isNull(input));
  }

  static isInList(item: any, items: Array<any>, fieldName = null): boolean {
    let itemIndex = -1;
    if (Utils.notNullOrEmpty(fieldName)) {
      itemIndex = items.findIndex((r) =>
        Utils.equalsIgnoreCase(String(r[fieldName]), String(item[fieldName]))
      );
    } else {
      itemIndex = items.findIndex((rec) =>
        Utils.equalsIgnoreCase(String(rec), String(item))
      );
    }
    return Utils.notEquals(itemIndex, -1);
  }

  static notInList(item: any, items: Array<any>, fieldName = null): boolean {
    let itemIndex = -1;
    if (Utils.notNullOrEmpty(fieldName)) {
      itemIndex = items.findIndex((r) =>
        Utils.equalsIgnoreCase(String(r[fieldName]), String(item[fieldName]))
      );
    } else {
      itemIndex = items.findIndex((rec) =>
        Utils.equalsIgnoreCase(String(rec), String(item))
      );
    }
    return Utils.equals(itemIndex, -1);
  }

  static uniqueList(items: Array<any>, fieldName = null): Array<any> {
    const filterFn = (item: any, index: number, items: Array<any>) => {
      return (
        (fieldName
          ? items.findIndex((r) =>
              Utils.equalsIgnoreCase(
                String(r[fieldName]),
                String(item[fieldName])
              )
            )
          : items.findIndex((rec) =>
              Utils.equalsIgnoreCase(String(rec), String(item))
            )) === index
      );
    };
    return items.filter(filterFn);
  }

  static hasDifferences(left: Array<any>, right: Array<any>): boolean {
    return (
      Utils.notEquals(left.length, right.length) ||
      Utils.notNullOrEmpty(
        left.filter((r) => Utils.isFalse(right.includes(r)))
      ) ||
      Utils.notNullOrEmpty(right.filter((r) => Utils.isFalse(left.includes(r))))
    );
  }

  static getUUID() {
    let dt = new Date().getTime();
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      const r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
    });
  }

  static getTitleCase(text: string): string {
    const strList = text
      .toLowerCase()
      .split(' ')
      .map((d) => {
        return `${d.charAt(0).toUpperCase()}${d.slice(1)}`;
      });

    return strList.join(' ');
  }

  static isValidRNumber(customerId: string): boolean {
    return (
      Utils.notNullAndDefined(customerId) &&
      /^R[0-9]{7}[U,L]{1}[0-9]{7}$/.test(customerId.toUpperCase())
    );
  }

  static isString(input: any): boolean {
    return typeof input === 'string';
  }

  static sortByMultipleFields(
    items: Array<any>,
    opts: { field: string; asc: 1 | -1 }[]
  ): Array<any> {
    const sortFunction = (a: any, b: any) => {
      let i = 0;
      let result = 0;
      while (i < opts.length && result === 0) {
        let multiplier = 0;
        if (a[opts[i].field] < b[opts[i].field]) {
          multiplier = -1;
        } else if (a[opts[i].field] > b[opts[i].field]) {
          multiplier = 1;
        }
        result = opts[i].asc * multiplier;
        i++;
      }
      return result;
    };
    return items.sort(sortFunction);
  }

  static sortByField(
    items: Array<any>,
    field: string,
    asc: 1 | -1 = 1
  ): Array<any> {
    return Utils.sortByMultipleFields(items, [{ field, asc }]);
  }

  static getSkipLoaderHeader(skipLoader: boolean): HttpHeaders {
    return new HttpHeaders({ 'ui-skip-loader': skipLoader ? 'Yes' : 'No' });
  }

  static hasSkipLoader(headers: HttpHeaders): boolean {
    const skipLoader = headers.get('ui-skip-loader');
    return Utils.equalsIgnoreCase(skipLoader, 'Yes');
  }

  static hasDuplicates(
    value: any,
    values: Array<any>,
    fieldName = null
  ): boolean {
    const fieldValues = fieldName ? values.map((v) => v[fieldName]) : values;
    return (
      fieldValues.filter((v) => Utils.equalsIgnoreCase(v, value)).length > 1
    );
  }

  static hasDuplicateItems(values: Array<any>, fieldName: string): boolean {
    return (
      values.map((v) => v[fieldName]).filter((v, i, l) => l.indexOf(v) === i)
        .length !== values.length
    );
  }

  static getDeleteApiOptions(params: Record<string, any>) {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body: params
    };
  }

  static getValidFilename(filename: string): string {
    // Replace special chars with empty string
    // Replace space with underscore
    return filename.replace(/[^a-zA-Z0-9_ ]+/g, '').replace(/ /g, '_');
  }

  static showPopupBlockedError(): void {
    alert(
      "Pop-up blocker is enabled! Please allow this site to your browser's exception list",
      'Pop-up Blocked'
    );
  }

  static getLoginAttemptStorageKey(eventId: number, email: string): string {
    return `${StorageKeyConstants.INVALID_LOGIN_ATTEMPTS}_${CryptoJS.MD5(
      `${eventId}_${email}`
    )}`.toLowerCase();
  }
}
