<ng-container *ngIf="!!value; else unchecked">
  <dx-button
    type="success"
    stylingMode="text"
    hint="{{ tooltipText | titlecase }}"
    [icon]="iconCheck"
    [elementAttr]="{ class: 'cv-cell-template-icon' }"
    [activeStateEnabled]="false"
    [hoverStateEnabled]="false"
    [focusStateEnabled]="false"
  ></dx-button>
</ng-container>
<ng-template #unchecked>
  <dx-button
    type="danger"
    stylingMode="text"
    hint="{{ tooltipText | titlecase }}"
    [icon]="iconCross"
    [visible]="showUncheck"
    [elementAttr]="{ class: 'cv-cell-template-icon' }"
    [activeStateEnabled]="false"
    [hoverStateEnabled]="false"
    [focusStateEnabled]="false"
  ></dx-button>
</ng-template>
