// Angular imports
import {
  Component,
  EventEmitter,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { NavigationEnd } from '@angular/router';

// Component imports
import { BaseComponent } from '../../../_base/base.component';

// Util imports
import { NavigationUtils } from '../../../_utils/navigation.utils';
import { RxjsUtils } from '../../../_utils/rxjs.utils';
import { Utils } from '../../../_utils/utils';

// Constant imports
import { RoutePathConstants } from '../../../_constants/route-path.constants';

// Type imports
import { NavigationEntry } from '../../../_types/navigation-entry';
import { NavigationEvent } from '../../../_types/navigation-event';

// Model imports
import { OrganizationResultDto } from '../../../_models/organization/organization-result.dto';

@Component({
  selector: 'app-side-navigation',
  templateUrl: './side-navigation.component.html',
  styleUrls: ['./side-navigation.component.scss']
})
export class SideNavigationComponent
  extends BaseComponent
  implements OnInit, OnDestroy
{
  @Input() isOpened: boolean;
  @Input() selectedItem: NavigationEntry;
  @Input() showOrgSelector: boolean;
  @Input() dataSource: Array<NavigationEntry>;
  @Output() selectedItemChange = new EventEmitter<NavigationEntry>();
  @Output() showOrgSelectorChange = new EventEmitter<boolean>();
  @Output() dataSourceChange = new EventEmitter<Array<NavigationEntry>>();
  @Output() onItemClick = new EventEmitter<NavigationEntry>();

  organizationId: number;
  eventId: number;

  constructor(protected override readonly injector: Injector) {
    super(injector);
  }

  ngOnInit(): void {
    RxjsUtils.subscribes('SideNavigationComponent', [
      this.router.events.subscribe((re) => {
        if (
          re instanceof NavigationEnd &&
          Utils.notNullOrEmpty(this.dataSource)
        ) {
          const routePath = NavigationUtils.getNormalizedPath(
            this.getNavigationPath(re.urlAfterRedirects)
          );
          this.selectedItem = NavigationUtils.selectItemByPath(
            this.dataSource,
            routePath
          );
          this.selectedItemChange.emit(this.selectedItem);
        }
      }),
      this.helperService.onSidenavSelection((routePaths: Array<string>) => {
        this.handleNavigationChange(routePaths.join('/'));
      }),
      this.helperService.onSidenavItemsUpdated((event: NavigationEvent) => {
        const treeItems = event.navItems;
        if (Utils.notNullOrEmpty(treeItems)) {
          this.dataSource = treeItems;
          this.dataSourceChange.emit(this.dataSource);
          this.showOrgSelector = Utils.isTrue(event.showOrgSelector);
          this.showOrgSelectorChange.emit(this.showOrgSelector);
          this.handleNavigationChange(event.urlPath);
        }
      }, 250),
      this.contextService.onOrganizationChanged(
        (result: OrganizationResultDto) => {
          this.organizationId = Utils.notNullAndDefined(result)
            ? result.organizationId
            : null;
        }
      ),
      this.contextService.onEventInfoChanged(() => {
        this.eventId = this.contextService.getEventId();
      })
    ]);
  }

  ngOnDestroy(): void {
    RxjsUtils.unsubscribe('SideNavigationComponent');
  }

  onItemExpand(entry: NavigationEntry): void {
    if (
      Utils.notNullAndDefined(entry) &&
      Utils.notNullOrEmpty(entry.parentId)
    ) {
      NavigationUtils.expandItemById(this.dataSource, entry.parentId);
    }
  }

  onNavItemClick(entry: NavigationEntry): void {
    this.onItemClick.emit(entry);
    if (Utils.notNullOrEmpty(entry.paths)) {
      this.selectedItem = entry;
      this.selectedItemChange.emit(this.selectedItem);
      const navPaths = entry.paths.map((path: string) =>
        this.getContextPath(path)
      );
      this.router.navigate(navPaths);
    }
  }

  private handleNavigationChange(urlPath: string): void {
    const routePath = NavigationUtils.getNormalizedPath(
      this.getNavigationPath(urlPath)
    );
    this.selectedItem = NavigationUtils.selectItemByPath(
      this.dataSource,
      routePath
    );
    this.selectedItemChange.emit(this.selectedItem);
  }

  private getContextPath(path: string): string {
    if (
      Utils.notNullAndDefined(this.organizationId) &&
      Utils.containsIgnoreCase(path, RoutePathConstants.ORGANIZATION_ID)
    ) {
      path = path.replace(
        RoutePathConstants.ORGANIZATION_ID,
        RoutePathConstants.ToRoutePath(this.organizationId)
      );
    }
    if (
      Utils.notNullAndDefined(this.eventId) &&
      Utils.containsIgnoreCase(path, RoutePathConstants.EVENT_ID)
    ) {
      path = path.replace(
        RoutePathConstants.EVENT_ID,
        RoutePathConstants.ToRoutePath(this.eventId)
      );
    }
    return path;
  }

  private getNavigationPath(path: string): string {
    if (
      Utils.notNullAndDefined(this.organizationId) &&
      Utils.containsIgnoreCase(
        path,
        RoutePathConstants.ToRoutePath(this.organizationId)
      )
    ) {
      path = path.replace(
        RoutePathConstants.ToRoutePath(this.organizationId),
        RoutePathConstants.ORGANIZATION_ID
      );
    }
    if (
      Utils.notNullAndDefined(this.eventId) &&
      Utils.containsIgnoreCase(
        path,
        RoutePathConstants.ToRoutePath(this.eventId)
      )
    ) {
      path = path.replace(
        RoutePathConstants.ToRoutePath(this.eventId),
        RoutePathConstants.EVENT_ID
      );
    }
    return path.split('?')[0]; // Remove Query Parameters
  }
}
