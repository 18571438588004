// Angular imports
import { Injectable } from '@angular/core';

// Dx imports
import { Properties as dxButtonOptions } from 'devextreme/ui/button';
import { custom } from 'devextreme/ui/dialog';

// Rxjs imports
import { delay, Subject, Subscription, tap } from 'rxjs';

// Constant imports
import { ActionConstants } from '../_constants/action.constants';
import { IconConstants } from '../_constants/icon.constants';

// Model imports
import { MessageEntry } from '../_types/message-entry';

@Injectable({ providedIn: 'root' })
export class MessageService {
  private readonly _subjectMessage = new Subject<MessageEntry>();

  onMessage(
    handler: (me: MessageEntry) => void,
    delayMillis = 0
  ): Subscription {
    return this._subjectMessage
      .pipe(delay(delayMillis), tap(handler))
      .subscribe();
  }

  showErrorMessage(title: string, message: string, details?: string): void {
    this._subjectMessage.next({ type: 'error', title, message, details });
  }

  showWarningMessage(title: string, message: string, details?: string): void {
    this._subjectMessage.next({ type: 'warning', title, message, details });
  }

  showInformationMessage(
    title: string,
    message: string,
    okType: 'default' | 'success' | 'danger'
  ): Promise<void> {
    return this.showCustomMessage(title, message, [
      {
        type: okType,
        text: ActionConstants.OK,
        hint: ActionConstants.OK,
        icon: IconConstants.OK,
        onClick: () => true
      }
    ]);
  }

  showConfirmMessage(
    title: string,
    message: string,
    yesType: 'default' | 'success' | 'danger'
  ): Promise<boolean> {
    return this.showCustomMessage<boolean>(title, message, [
      {
        type: 'default',
        text: ActionConstants.NO,
        hint: ActionConstants.NO,
        icon: IconConstants.NO,
        onClick: () => false
      },
      {
        type: yesType,
        text: ActionConstants.YES,
        hint: ActionConstants.YES,
        icon: IconConstants.YES,
        onClick: () => true
      }
    ]);
  }

  showCustomMessage<T>(
    title: string,
    message: string,
    buttons: Array<dxButtonOptions>
  ): Promise<T> {
    return custom({
      title,
      messageHtml: `<div class="cv-custom-message-content">${message}</div>`,
      buttons: buttons,
      showTitle: true,
      dragEnabled: true
    }).show();
  }
}
