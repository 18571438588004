// Angular imports
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

// Service imports
import { HelperService } from '../../../_services/helper.service';

// Model imports
import { FileInfoParameterDto } from '../../../_models/common/file-info-parameter.dto';

@Component({
  selector: 'app-image-viewer',
  templateUrl: './image-viewer.component.html',
  styleUrls: ['./image-viewer.component.scss']
})
export class ImageViewerComponent implements OnInit {
  @Input() title: string;
  @Input() fileInfo: FileInfoParameterDto;
  @Output() onClose = new EventEmitter<void>();

  fileDataUrl: string;

  constructor(private readonly helperService: HelperService) {}

  ngOnInit(): void {
    this.helperService.getFileDataUrl(this.fileInfo).subscribe({
      next: (fileDataUrl: string) => (this.fileDataUrl = fileDataUrl),
      error: () => this.onClose.emit()
    });
  }
}
