// Angular imports
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

// Constant imports
import { IconConstants } from '../../../_constants/icon.constants';

// Type imports
import { SequenceItemInfo } from '../../../_types/sequence-item-info';
import { Utils } from 'src/app/_utils/utils';

@Component({
  selector: 'app-sequence-cell-template',
  templateUrl: './sequence-cell-template.component.html',
  styleUrls: ['./sequence-cell-template.component.scss']
})
export class SequenceCellTemplateComponent implements OnInit {
  @Input() type: 'default' | 'success' | 'normal' = 'success';
  @Input() record: SequenceItemInfo;
  @Input() rowIndex: number;
  @Input() dataSource: Array<SequenceItemInfo>;
  @Output() onUpdated = new EventEmitter<Array<SequenceItemInfo>>();

  iconUp = IconConstants.UP;
  iconDown = IconConstants.DOWN;

  allowUp = false;
  allowDown = false;

  ngOnInit(): void {
    this.allowUp = this.rowIndex > 0;
    this.allowDown = this.rowIndex < this.dataSource.length - 1;
    this.dataSource = Utils.sortByField(this.dataSource, 'sequence');
  }

  onUpClick(): void {
    const currentRecord = this.dataSource.at(this.rowIndex);
    currentRecord.sequence = currentRecord.sequence - 1;
    const previousRecord = this.dataSource.at(this.rowIndex - 1);
    previousRecord.sequence = previousRecord.sequence + 1;
    this.onUpdated.emit(this.dataSource);
  }

  onDownClick(): void {
    const currentRecord = this.dataSource.at(this.rowIndex);
    currentRecord.sequence = currentRecord.sequence + 1;
    const nextRecord = this.dataSource.at(this.rowIndex + 1);
    nextRecord.sequence = nextRecord.sequence - 1;
    this.onUpdated.emit(this.dataSource);
  }
}
