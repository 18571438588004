// Angular imports
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-wraptext-cell-template',
  templateUrl: './wraptext-cell-template.component.html',
  styleUrls: ['./wraptext-cell-template.component.scss']
})
export class WraptextCellTemplateComponent {
  @Input() text: string;
}
