export class FormatterConstants {
  static readonly TIME_SHORT = 'hh:mm a';
  static readonly TIME_LONG = 'hh:mm:ss a';
  static readonly DATE = 'MM/dd/yyyy';
  static readonly DATETIME = 'MM/dd/yyyy hh:mm a'; // 12 hour clock
  static readonly MOMENT_DATE = 'MM/DD/YYYY';
  static readonly MOMENT_DATETIME = 'MM/DD/YYYY hh:mm A'; // 12 hour clock
  static readonly MOMENT_DATE_ISO = 'YYYY-MM-DD';
  static readonly MOMENT_DATETIME_ISO = 'YYYY-MM-DDTHH:mm:ss';
  static readonly PARTICIPANT_DATETIME = 'MMM DD, YYYY | hh:mm A'; // 12 hour clock
  static readonly CURRENCY = '$#,##0.00;($#,##0.00)';
  static readonly PERCENT = '#0.00%;(#0.00%)';
  static readonly NUMBER = '#,##0.00;(#,##0.00)';
  static readonly DX_PHONE_MASK = '(X00) 000-0000';
}
