export class ActionConstants {
  static readonly LOGIN = 'Login';
  static readonly LOGOUT = 'Logout';
  static readonly PREFERENCES = 'Preferences';
  static readonly ACTIONS = 'Actions';
  static readonly VIEW = 'View';
  static readonly ADD = 'Add';
  static readonly EDIT = 'Edit';
  static readonly DELETE = 'Delete';
  static readonly VERIFY = 'Verify';
  static readonly DISCARD = 'Discard';
  static readonly CREATE = 'Create';
  static readonly IMPORT = 'Import';
  static readonly MARK_VERIFIED = 'Mark Verified';
  static readonly APPROVE = 'Approve';
  static readonly MARK_APPROVED = 'Mark Approved';
  static readonly REJECT = 'Reject';
  static readonly MARK_REJECTED = 'Mark Rejected';
  static readonly BROWSE = 'Browse';
  static readonly OPEN = 'Open';
  static readonly INPROGRESS = 'In Progress';
  static readonly COMPLETED = 'Completed';
  static readonly RESEND = 'Resend';
  static readonly GENERATE = 'Generate';
  static readonly REGENERATE = 'Regenerate';
  static readonly DOWNLOAD = 'Download';
  static readonly SEND = 'Send';
  static readonly SELECT = 'Select';
  static readonly CUSTOM = 'Custom';
  static readonly REFRESH = 'Refresh';
  static readonly SAVE = 'Save';
  static readonly SUBMIT = 'Submit';
  static readonly ABSTAIN = 'Abstain';
  static readonly PUBLISH = 'Publish';
  static readonly OK = 'OK';
  static readonly CANCEL = 'Cancel';
  static readonly CLOSE = 'Close';
  static readonly NEXT = 'Next';
  static readonly BACK = 'Back';
  static readonly YES = 'Yes';
  static readonly NO = 'No';
  static readonly EXPORT = 'Export To';
  static readonly EXPORT_PDF = 'PDF File';
  static readonly EXPORT_CSV = 'CSV File';
  static readonly EXPORT_XLS = 'Excel File';
  static readonly ACTIVATE = 'Activate';
  static readonly DEACTIVATE = 'Deactivate';
  static readonly LINK = 'Link';
  static readonly UNLINK = 'Unlink';
  static readonly SYNC = 'Sync';
  static readonly FILE = 'File';
  static readonly SCHEDULED = 'Scheduled';
  static readonly ERRORED = 'Errored';
  static readonly EXCLUDE = 'Exclude';
  static readonly INCLUDE = 'Include';
  static readonly VIEW_LOGS = 'View Logs';
  static readonly VIEW_HISTORY = 'View History';
  static readonly VIEW_ERRORS = 'View Errors';
  static readonly PRINT = 'Print';
}
