// Angular imports
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-summary-pane',
  templateUrl: './summary-pane.component.html',
  styleUrls: ['./summary-pane.component.scss']
})
export class SummaryPaneComponent {
  @Input() count: number | string;
  @Input() text: string;
  @Input() rounded = true;
}
