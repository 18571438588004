// Angular imports
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-link-cell-template',
  templateUrl: './link-cell-template.component.html',
  styleUrls: ['./link-cell-template.component.scss']
})
export class LinkCellTemplateComponent {
  @Input() displayText: string;
  @Input() tooltipText: string;
  @Input() linkEnabled = true;
  @Output() onClick = new EventEmitter<void>();
}
