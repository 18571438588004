// Angular imports
import { Directive, HostListener, Input } from '@angular/core';

// Dx imports
import { Column } from 'devextreme/ui/tree_list';

// Util imports
import { Utils } from '../../_utils/utils';

// Model imports
import { TreeListFeatures } from '../../_types/tree-list-feature';

@Directive({
  selector: '[appTreeListFeatures]'
})
export class TreeListFeaturesDirective {
  @Input() applyFeatures = true;
  @Input() enablePaging = false;
  @Input() gridFeatures: TreeListFeatures = {};

  defaultOptions: TreeListFeatures = {
    autoExpandAll: true,
    showBorders: true,
    showRowLines: true,
    showColumnLines: true,
    columnAutoWidth: true,
    columnHidingEnabled: false,
    columnMinWidth: 50,
    allowColumnReordering: false,
    allowColumnResizing: false,
    columnResizingMode: 'widget',
    wordWrapEnabled: false,
    showColumnHeaders: true,
    sorting: { mode: 'single' },
    scrolling: { mode: 'standard' },
    headerFilter: { visible: true },
    filterRow: { visible: true, allowSearch: true },
    filterPanel: { visible: true },
    loadPanel: { enabled: false },
    paging: { enabled: false, pageSize: 25 },
    pager: {
      visible: false,
      allowedPageSizes: [5, 10, 25, 50, 75, 100, 'all'],
      displayMode: 'compact',
      showInfo: true,
      showPageSizeSelector: true,
      showNavigationButtons: true
    }
  };

  @HostListener('onContentReady', ['$event'])
  onContentReady(e: any): void {
    if (Utils.isTrue(this.applyFeatures)) {
      this.applyFeatures = false;
      if (Utils.isTrue(this.enablePaging)) {
        this.defaultOptions.scrolling.mode = 'standard';
        this.defaultOptions.paging.enabled = true;
        this.defaultOptions.pager.visible = true;
      }
      const instance = e.component;
      const columns = instance.option('columns') as Column[];
      columns.forEach((col: Column) => {
        if (Utils.equalsIgnoreCase(col.type, 'actions')) {
          col.alignment = 'center';
          col.width = col.width || 150;
          col.allowEditing = false;
          col.allowFixing = false;
          col.allowFiltering = false;
          col.allowHiding = false;
          col.allowResizing = false;
          col.allowReordering = false;
          col.allowSearch = false;
          col.allowSorting = false;
        }
        if (Utils.isNullOrEmpty(col.dataType)) {
          col.allowFiltering = false;
          col.allowHeaderFiltering = false;
        } else if (Utils.equalsIgnoreCase(col.dataType, 'boolean')) {
          col.allowFiltering = true;
          col.allowHeaderFiltering = col.allowHeaderFiltering || false;
          col.trueText = col.trueText || 'Yes';
          col.falseText = col.falseText || 'No';
        } else if (
          Utils.equalsIgnoreCase(col.dataType, 'number') ||
          Utils.equalsIgnoreCase(col.dataType, 'date') ||
          Utils.equalsIgnoreCase(col.dataType, 'datetime')
        ) {
          col.filterOperations = [
            'contains',
            '=',
            '<>',
            '<',
            '>',
            '<=',
            '>=',
            'between'
          ];
        }
      });

      // Merge default and input optiions
      const options = Object.assign(
        { columns },
        this.defaultOptions,
        this.gridFeatures
      );

      // Update options
      instance.option(options);
    }
  }
}
