<dx-tree-view
  *ngIf="!!dataSource"
  width="100%"
  height="82%"
  keyExpr="id"
  displayExpr="text"
  expandEvent="click"
  selectionMode="single"
  itemTemplate="sidenav-template"
  [items]="dataSource"
  [rootValue]="null"
  [activeStateEnabled]="false"
  [focusStateEnabled]="false"
  [hoverStateEnabled]="false"
  [elementAttr]="{ class: 'cv-sidenav-tree' }"
>
  <div
    *dxTemplate="let navItem of 'sidenav-template'"
    class="cv-sidenav-template cc-theme-sidenav-template cv-sidenav-template-border dx-theme-border-color"
    [ngClass]="{
      'cc-theme-sidenav-selected cc-theme-sidenav-selected-background-color':
        navItem | isSelected: selectedItem,
      'cv-sidenav-closed': !isOpened
    }"
    (click)="onNavItemClick(navItem)"
  >
    <div class="cv-sidenav-icon cv-center-content" *ngIf="navItem.icon">
      <i aria-hidden="true" [ngClass]="navItem.icon"></i>
    </div>
    <div
      class="cv-sidenav-text cv-center-content"
      [ngClass]="{ 'cv-hidden-box': !isOpened }"
    >
      {{ navItem.text }}
    </div>
    <div
      class="cv-sidenav-arrow cv-center-content"
      [ngClass]="{ 'cv-hidden-box': !isOpened }"
      *ngIf="navItem?.items?.length > 0"
    >
      <i
        *ngIf="navItem.expanded"
        aria-hidden="true"
        [ngClass]="iconArrowDown"
      ></i>
      <i
        *ngIf="!navItem.expanded"
        aria-hidden="true"
        [ngClass]="iconArrowRight"
      ></i>
    </div>
    <div
      class="cv-sidenav-percentage cv-center-content"
      [ngClass]="{ 'cv-hidden-box': !isOpened }"
      *ngIf="navItem.percentage >= 0"
    >
      <app-percentage-pie
        *ngIf="!!navItem.icon || !!navItem.percentage"
        sizeGroup="sidenav"
        [size]="25"
        [value]="navItem.percentage"
      ></app-percentage-pie>
    </div>
  </div>
</dx-tree-view>
