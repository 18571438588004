// Angular imports
import { Component, Input, OnChanges, OnInit } from '@angular/core';

// Constant imports
import { FormatterConstants } from '../../../_constants/formatter.constants';
import { TimerConstants } from '../../../_constants/timer.constants';

// Util imports
import { DateUtils } from '../../../_utils/date.utils';
import { Utils } from '../../../_utils/utils';

@Component({
  selector: 'app-countdown-pane',
  templateUrl: './countdown-pane.component.html',
  styleUrls: ['./countdown-pane.component.scss']
})
export class CountdownPaneComponent implements OnInit, OnChanges {
  @Input() targetName: string;
  @Input() targetDate: Date;
  @Input() showBorder = true;
  @Input() labelText = `Time remaining for closure`;
  @Input() compact = false;

  daysCount = 0;
  hoursCount = 0;
  minsCount = 0;

  isLastDay = false;
  isPastDate = false;

  ngOnInit(): void {
    this.populateCounts();
    setInterval(this.populateCounts.bind(this), TimerConstants.inMinutes(1));
  }

  ngOnChanges(): void {
    this.populateCounts();
  }

  private populateCounts(): void {
    if (Utils.notNullAndDefined(this.targetDate)) {
      const currentDate = new Date();
      this.isPastDate = DateUtils.isBefore(this.targetDate, currentDate);
      this.isLastDay = Utils.equalsIgnoreCase(
        DateUtils.dateToString(this.targetDate, FormatterConstants.MOMENT_DATE),
        DateUtils.dateToString(currentDate, FormatterConstants.MOMENT_DATE)
      );
      let timeInSecs = DateUtils.differenceIn(
        currentDate,
        this.targetDate,
        'milliseconds'
      );
      this.daysCount = Math.floor(timeInSecs / TimerConstants.inDays(1));
      timeInSecs = timeInSecs % TimerConstants.inDays(1);
      this.hoursCount = Math.floor(timeInSecs / TimerConstants.inHours(1));
      timeInSecs = timeInSecs % TimerConstants.inHours(1);
      this.minsCount = Math.floor(timeInSecs / TimerConstants.inMinutes(1));
    }
  }
}
