// Util imports
import { Utils } from './utils';

// Type imports
import { NavigationEntry } from '../_types/navigation-entry';

export class NavigationUtils {
  static getPlainItems = (
    treeItems: Array<NavigationEntry>,
    plainItems: Array<NavigationEntry> = []
  ): Array<NavigationEntry> => {
    treeItems.forEach((entry: NavigationEntry) => {
      plainItems.push(entry);
      NavigationUtils.getPlainItems(entry.items, plainItems);
    });
    return plainItems;
  };

  static getItemById = (
    treeItems: Array<NavigationEntry>,
    id: string
  ): NavigationEntry => {
    return NavigationUtils.getPlainItems(treeItems).find(
      (entry: NavigationEntry) => Utils.equalsIgnoreCase(entry.id, id)
    );
  };

  static expandItemById = (
    treeItems: Array<NavigationEntry>,
    id: string
  ): NavigationEntry => {
    const entry = NavigationUtils.getItemById(treeItems, id);
    if (Utils.notNullAndDefined(entry)) {
      entry.expanded = true;
      if (Utils.notNullOrEmpty(entry.parentId)) {
        NavigationUtils.expandItemById(treeItems, entry.parentId);
      }
    }
    return entry;
  };

  static getItemByPath = (
    treeItems: Array<NavigationEntry>,
    path: string
  ): NavigationEntry => {
    return NavigationUtils.getPlainItems(treeItems)
      .filter((entry: NavigationEntry) => Utils.notNullOrEmpty(entry.paths))
      .find((entry: NavigationEntry) =>
        Utils.equalsIgnoreCase(entry.paths.join('/'), path)
      );
  };

  static selectItemByPath = (
    treeItems: Array<NavigationEntry>,
    path: string
  ): NavigationEntry => {
    const entry = NavigationUtils.getItemByPath(treeItems, path);
    if (Utils.notNullAndDefined(entry)) {
      entry.selected = true;
      if (Utils.notNullOrEmpty(entry.parentId)) {
        NavigationUtils.expandItemById(treeItems, entry.parentId);
      }
    }
    return entry;
  };

  static getNormalizedPath(path: string): string {
    return path.startsWith('/') ? path.slice(1) : path;
  }
}
