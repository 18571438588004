import { StorageKeyConstants } from '../../_constants/storage-key.constants';
import { Utils } from '../../_utils/utils';
import { BaseUserInfoResultDto } from './base-user-info-result.dto';

export class UserInfoResultDto extends BaseUserInfoResultDto {
  organizationId: number;
  userId: number;
  organizationName: string;
  profileImage: string;
  isOrganizationAdmin: boolean;
  isSuperAdmin: boolean;
  userRoles: Array<string> = [];

  get isITUser(): boolean {
    return Utils.isInList('IT', this.userRoles);
  }

  get isInternalUser(): boolean {
    return Utils.isInList('INTERNAL', this.userRoles);
  }

  get isSelectedOrganizationAdmin(): boolean {
    const selectedOrganizationId = localStorage.getItem(
      StorageKeyConstants.USER_ORGANIZATION
    );
    return (
      Utils.isTrue(this.isOrganizationAdmin) &&
      Utils.equalsIgnoreCase(
        this.organizationId.toString(),
        selectedOrganizationId
      )
    );
  }
}
