// Angular imports
import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild
} from '@angular/core';

// Dx imports
import { DxPieChartComponent } from 'devextreme-angular/ui/pie-chart';

// Util imports
import { DxWidgetUtils } from '../../../_utils/dx-widget.utils';
import { Utils } from '../../../_utils/utils';

// Constant imports
import { DisplayTextConstants } from '../../../_constants/display-text.constants';
import { IconConstants } from '../../../_constants/icon.constants';

@Component({
  selector: 'app-percentage-pie',
  templateUrl: './percentage-pie.component.html',
  styleUrls: ['./percentage-pie.component.scss']
})
export class PercentagePieComponent implements OnInit, OnChanges {
  @ViewChild('pieChart', { static: false }) pieChart: DxPieChartComponent;

  @Input() value: number;
  @Input() size: number;
  @Input() sizeGroup: string;

  tooltip: string;
  dataSource: Array<{ label: string; value: number }>;

  iconComplete = IconConstants.COMPLETED;

  constructor() {
    this.customizePoint = this.customizePoint.bind(this);
  }

  ngOnInit(): void {
    this.tooltip = `${this.value}% ${DisplayTextConstants.COMPLETE}`;
    this.value = Math.min(this.value, 100);
    this.dataSource = [
      { label: DisplayTextConstants.COMPLETE, value: this.value },
      { label: DisplayTextConstants.TOTAL, value: 100 - (this.value || 0) }
    ];
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (Utils.notNullOrEmpty(this.dataSource)) {
      if (Utils.notNullAndDefined(changes['value'])) {
        this.dataSource = [
          { label: DisplayTextConstants.COMPLETE, value: this.value },
          { label: DisplayTextConstants.TOTAL, value: 100 - (this.value || 0) }
        ];
        DxWidgetUtils.deferredRepaint(this.pieChart, 250);
      }
    }
  }

  customizePoint(point: { argument: string }) {
    const elm: any = document.getElementById('theme-element');
    const themeColor = elm.computedStyleMap().get('color').toString();
    const pointStyle = {
      color: Utils.equalsIgnoreCase(point.argument, DisplayTextConstants.TOTAL)
        ? 'transparent'
        : themeColor,
      border: {
        color: themeColor,
        dashStyle: 'solid',
        visible: true,
        width: 0.75
      }
    };
    return {
      ...pointStyle,
      hoverStyle: { ...pointStyle }
    };
  }
}
