// Angular imports
import { Component, Input } from '@angular/core';

// Rxjs imports
import { Observable } from 'rxjs';

// Constant imports
import { IconConstants } from '../../../_constants/icon.constants';

// Model imports
import { HistoryContentResultDto } from '../../../_models/common/history-content-result.dto';
import { HistoryDetailsResultDto } from '../../../_models/common/history-details-result.dto';

@Component({
  selector: 'app-history-cell-template',
  templateUrl: './history-cell-template.component.html',
  styleUrls: ['./history-cell-template.component.scss']
})
export class HistoryCellTemplateComponent<T> {
  @Input() record: T;
  @Input() historyOf: string;
  @Input() historySummaryFn: (
    record: T
  ) => Observable<Array<HistoryContentResultDto>>;
  @Input() historyDetailsFn: (
    record: T,
    summary: HistoryContentResultDto
  ) => Observable<Array<HistoryDetailsResultDto>>;

  iconHistory = IconConstants.HISTORY;
  showHistory = false;

  historySummary: Array<HistoryContentResultDto>;

  onHistoryClick(): void {
    this.historySummaryFn(this.record).subscribe(
      (results: Array<HistoryContentResultDto>) => {
        this.historySummary = results;
        this.showHistory = true;
      }
    );
  }
}
