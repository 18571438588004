<app-page-popup
  *ngIf="historySummary"
  width="50%"
  height="80%"
  title="{{ historyOf }} History Information"
  [buttons]="['Close']"
  [visible]="true"
  (onHidden)="onClose.emit()"
>
  <dx-data-grid
    appDataGridFeatures
    width="100%"
    height="100%"
    [dataSource]="historySummary"
  >
    <dxi-column
      width="64px"
      caption=""
      alignment="center"
      cellTemplate="iconCellTemplate"
    >
      <div *dxTemplate="let cell of 'iconCellTemplate'">
        <app-icon-cell-template
          *ngIf="cell.data.hasDetails"
          type="default"
          tooltipText="View Change Details"
          [icon]="iconInfo"
          (onClick)="onIconCellClick(cell.data)"
        ></app-icon-cell-template>
      </div>
    </dxi-column>
    <dxi-column
      dataField="logId"
      dataType="number"
      caption="Log Id"
    ></dxi-column>
    <dxi-column
      width="96px"
      dataField="type"
      dataType="string"
      caption="Action"
    ></dxi-column>
    <dxi-column
      dataField="who.name"
      dataType="string"
      caption="Who"
    ></dxi-column>
    <dxi-column dataField="what" dataType="string" caption="What"></dxi-column>
    <dxi-column
      dataField="when"
      dataType="date"
      caption="When"
      sortOrder="desc"
      [sortIndex]="0"
      [format]="dateTimeFormat"
    ></dxi-column>
  </dx-data-grid>
</app-page-popup>

<app-history-details-viewer
  *ngIf="showDetails"
  [historyOf]="historyOf"
  [historySummary]="selectedSummary"
  [historyDetails]="historyDetails"
  (onClose)="showDetails = false"
></app-history-details-viewer>
