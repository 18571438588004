// Angular imports
import { Directive, HostListener, Input } from '@angular/core';

// Constant imports
import { ActionConstants } from '../../_constants/action.constants';
import { IconConstants } from '../../_constants/icon.constants';

@Directive({
  selector: '[appButtonSelect]'
})
export class ButtonSelectDirective {
  @Input() text = ActionConstants.SELECT;
  @Input() hint = ActionConstants.SELECT;
  @Input() icon = IconConstants.SELECT;

  @HostListener('onInitialized', ['$event'])
  onInitialized(e: any): void {
    e.component.option({
      type: 'default',
      stylingMode: 'outlined',
      height: 32,
      text: this.text,
      hint: this.hint,
      icon: this.icon
    });
  }
}
