import { Type } from 'class-transformer';
import { UserSettingCategory } from '../../_enums/user-setting-category.enum';

export class UserSettingRawDto {
  key: string;
  userId: string;
  portalType: string;
  value: string;
  category: UserSettingCategory;
  @Type(() => Date)
  lastUpdatedDate: Date;
  lastUpdatedBy: string;
}
