// Angular imports
import { Component, Input, OnInit } from '@angular/core';

// Util imports
import { Utils } from '../../../_utils/utils';

@Component({
  selector: 'app-status-cell-template',
  templateUrl: './status-cell-template.component.html',
  styleUrls: ['./status-cell-template.component.scss']
})
export class StatusCellTemplateComponent implements OnInit {
  @Input() status: string;
  @Input() types: Array<{
    text: string;
    type: 'default' | 'normal' | 'success' | 'danger';
  }>;

  valueType: 'default' | 'normal' | 'success' | 'danger' = 'default';

  ngOnInit(): void {
    if (Utils.isNullOrEmpty(this.types)) {
      this.types = [
        { text: 'open', type: 'danger' },
        { text: 'completed', type: 'success' },
        { text: 'not scheduled', type: 'normal' },
        { text: 'scheduled', type: 'default' },
        { text: 'errored', type: 'danger' }
      ];
    }
    this.valueType = (
      this.types.find((r) => Utils.equalsIgnoreCase(this.status, r.text)) || {
        type: 'default'
      }
    ).type;
  }
}
