// Model imports
import { MergeFieldResultDto } from '../_models/common/merge-field-result.dto';

// Declared to use in static methods
declare const tinymce;

export class HtmlEditorUtils {
  static editorOptions(
    pageOptions: any,
    mergeTags: Array<MergeFieldResultDto> = []
  ): any {
    return Object.assign(this.editorDefaultOptions(mergeTags), pageOptions);
  }

  // with predefined object (preferred)
  static escape(s: string) {
    const escaped = {
      '&': '&amp;',
      '<': '&lt;',
      '>': '&gt;',
      "'": '&#39;',
      '"': '&quot;'
    };
    return s.replace(/[&<>'"]/g, (m) => {
      return escaped[m];
    });
  }

  // with "any char" compatible HTML escaping
  static escapeAny(s: string): string {
    return s.replace(/[&<>'"]/g, (m) => {
      return '&#' + m.charCodeAt(0) + ';';
    });
  }

  // with predefined object specific
  // for HTML entities only
  static unescape(s: string) {
    const re = /&(?:amp|#38|lt|#60|gt|#62|apos|#39|quot|#34);/g;
    const unescaped = {
      '&amp;': '&',
      '&#38;': '&',
      '&lt;': '<',
      '&#60;': '<',
      '&gt;': '>',
      '&#62;': '>',
      '&apos;': "'",
      '&#39;': "'",
      '&quot;': '"',
      '&#34;': '"'
    };
    return s.replace(re, (m) => {
      return unescaped[m];
    });
  }

  // gets the length of text content by excluding html element
  static textLength(s: string): number {
    const doc = new DOMParser().parseFromString(s, 'text/html');
    return doc.documentElement.innerText.length;
  }

  private static editorDefaultOptions(mergeTags: Array<MergeFieldResultDto>) {
    return {
      base_url: '/assets/tinymce',
      suffix: '.min',
      license_key: 'gpl',
      min_height: 300,
      branding: false,
      resize: true,
      elementpath: false,
      indent: false,
      menubar: false,
      fullscreen_native: true,
      toolbar_sticky: true,
      toolbar_mode: 'wrap',
      image_advtab: true,
      importcss_append: true,
      image_caption: true,
      quickbars_insert_toolbar: false,
      relative_urls: false,
      remove_script_host: false,
      readonly: false,
      paste_tab_spaces: 2,
      paste_remove_styles_if_webkit: false,
      paste_webkit_styles: 'all',
      doctype: '<!DOCTYPE html>',
      element_format: 'html',
      valid_elements: '*[*]',
      invalid_elements: 'script',
      noneditable_class: 'mceNonEditable',
      plugins: this.getEditorDefaultPlugins(),
      toolbar: `undo redo | accordion accordionremove | 
        blocks fontfamily fontsize | bold italic underline strikethrough | 
        align numlist bullist | link image | table media | 
        lineheight outdent indent | forecolor backcolor removeformat | 
        charmap emoticons | code fullscreen preview | save print | 
        pagebreak anchor codesample | mergetags`,
      quickbars_selection_toolbar: `bold italic | 
          quicklink h1 h2 h3 blockquote quickimage quicktable | 
          mergetags`,
      contextmenu: 'link image table',
      line_height_formats: '1 1.1 1.2 1.3 1.4 1.5 1.6 2',
      font_size_formats: '8pt 10pt 12pt 14pt 16pt 18pt 24pt 36pt 48pt',
      indentation: '20pt',
      indent_use_margin: true,
      default_link_target: '_blank',
      file_picker_types: 'image',
      file_picker_callback(callback, value, meta) {
        /* Provide image and alt text for the image dialog */
        if (meta.filetype === 'image') {
          const input = document.getElementById('imageInput');
          input.onchange = (e: any) => {
            if (e.target.files.length > 0) {
              const file = e.target.files[0];
              const reader = new FileReader();
              reader.onload = function () {
                const id = `blobid-${new Date().getTime()}`;
                const base64 = reader.result.toString().split(',')[1];
                const blobCache = tinymce.activeEditor.editorUpload.blobCache;
                const blobInfo = blobCache.create(id, file, base64);
                blobCache.add(blobInfo);
                callback(blobInfo.blobUri(), {
                  title: file.name,
                  alt: file.name
                });
              };
              reader.readAsDataURL(file);
            }
          };
          const click = document.createEvent('MouseEvents');
          click.initEvent('click', true, false);
          input.dispatchEvent(click);
        }
      },
      spellchecker_languages: 'English=en',
      browser_spellcheck: true,
      setup: (editor: any) => {
        if (mergeTags.length > 0) {
          editor.ui.registry.addIcon(
            'cc-merge-tags-icon',
            '<i class="fas fa-code-branch cv-html-editor-fa-icons"></i>'
          );
          editor.ui.registry.addMenuButton('mergetags', {
            icon: 'cc-merge-tags-icon',
            tooltip: 'Merge Tags',
            fetch: (callback) => {
              const items = mergeTags.map((mt) => {
                return {
                  type: 'menuitem',
                  text: mt.displayText,
                  code: mt.code,
                  onAction: () => {
                    editor.insertContent(` ${this.escape(mt.value)}`);
                  }
                };
              });
              callback(items);
            }
          });
        }
      },
      content_style:
        'body { font-family: "Helvetica Neue","Segoe UI",helvetica,verdana,sans-serif; font-size: 10pt }'
    };
  }

  private static getEditorDefaultPlugins(): Array<string> {
    return [
      'preview',
      'importcss',
      'searchreplace',
      'autolink',
      'directionality',
      'code',
      'visualblocks',
      'visualchars',
      'fullscreen',
      'image',
      'link',
      'media',
      'codesample',
      'table',
      'charmap',
      'pagebreak',
      'nonbreaking',
      'anchor',
      'insertdatetime',
      'advlist',
      'lists',
      'wordcount',
      'charmap',
      'quickbars',
      'emoticons',
      'accordion',
      'fullpage'
    ];
  }
}
