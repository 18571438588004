<app-page-popup
  width="auto"
  height="auto"
  title="Preferences"
  [buttons]="['Save', 'Cancel']"
  [visible]="true"
  (onHidden)="onClose.emit()"
  (onButtonClick)="onButtonClick($event)"
>
  <dx-form #editorForm width="100%" height="100%" [formData]="userPreferences">
    <dxi-item
      dataField="themeColor"
      editorType="dxSelectBox"
      [editorOptions]="{
        displayExpr: 'displayText',
        valueExpr: 'key',
        dataSource: themeColors
      }"
    >
      <dxo-label text="Theme"></dxo-label>
    </dxi-item>
  </dx-form>
</app-page-popup>
