// Angular imports
import { Directive, HostListener } from '@angular/core';

// Constant imports
import { FormatterConstants } from '../../_constants/formatter.constants';

@Directive({
  selector: '[appDateBoxDateTime]'
})
export class DateBoxDateTimeDirective {
  @HostListener('onInitialized', ['$event'])
  onInitialized(e: any): void {
    e.component.option({
      type: 'datetime',
      placeholder: FormatterConstants.DATETIME.toUpperCase(),
      displayFormat: FormatterConstants.DATETIME
    });
  }
}
