// Angular imports
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';

// Rxjs imports
import { delay, Observable, of, share, tap } from 'rxjs';

// Service imports
import { CachingService } from '../_services/caching.service';

// Util imports
import { Utils } from '../_utils/utils';

@Injectable()
export class CachingInterceptor implements HttpInterceptor {
  constructor(private readonly cachingService: CachingService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // If not get method, serialize body with custom logic for date ISO format
    if (Utils.notEqualsIgnoreCase(req.method, 'GET')) {
      let payload = req.serializeBody();
      if (typeof payload === 'string') {
        payload = payload.replace(/T00:00:00.000Z/gi, 'T00:00:00.000');
        payload = JSON.parse(payload);
      }
      req = req.clone({
        body: payload
      });
    }

    // Don't cache if
    // 1. It's not a GET request
    // 2. If URL is not supposed to be cached
    const cacheUrl = this.cachingService.getCacheUrl(req);
    if (
      Utils.notEqualsIgnoreCase(req.method, 'GET') ||
      Utils.isFalse(this.cachingService.registered(cacheUrl))
    ) {
      return next.handle(req);
    }

    // Resetting already cached data for a URL
    if (req.headers.get('reset-cache')) {
      this.cachingService.reset(cacheUrl);
    }

    return new Observable<HttpEvent<any>>((observer) => {
      let waitTime = 0;
      // Checked if there is cached data for this URI
      const cacheEntry = this.cachingService.get(cacheUrl);
      if (Utils.notNullAndDefined(cacheEntry.response)) {
        // If the request is already cached
        // then let send cached response with minimal delay for UI to repaint correctly
        of(cacheEntry.response.clone()).pipe(delay(100)).subscribe(observer);
      } else if (Utils.isTrue(cacheEntry.loading)) {
        // If the same request is going through for multiple times
        // then let wait for 30 sec to get the response from first request before proceed and cache the response
        cacheEntry.timerId = setInterval(() => {
          waitTime += 1000;
          if (
            Utils.isFalse(cacheEntry.loading) &&
            Utils.notNullAndDefined(cacheEntry.response)
          ) {
            clearInterval(cacheEntry.timerId);
            of(cacheEntry.response.clone()).subscribe(observer);
          } else if (waitTime > 30000) {
            clearInterval(cacheEntry.timerId);
            cacheEntry.loading = true;
            this.getHttpEvent(req, next).subscribe(observer);
          }
        }, 1000);
      } else {
        // If the request of going through for first time
        // then set isLoading to true and proceed with request
        cacheEntry.loading = true;
        this.getHttpEvent(req, next).subscribe(observer);
      }
    });
  }

  private getHttpEvent(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap((res) => {
        if (res instanceof HttpResponse && res.ok) {
          this.cachingService.update(req, res);
        }
      }),
      share()
    );
  }
}
