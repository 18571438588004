<div
  *ngIf="!!targetDate && !compact && !isPastDate"
  class="cv-countdown-pane dx-theme-accent-as-background-color"
  [ngClass]="{
    'cv-round-border dx-theme-accent-as-border-color': showBorder
  }"
>
  <span class="cv-countdown-title dx-theme-background-color-as-text-color">
    {{ targetName }} Countdown
  </span>
  <div class="cv-countdown-content dx-theme-background-color-as-text-color">
    <div class="cv-countdown-item cv-center-content">
      <span class="cv-countdown-value">{{ daysCount }}</span>
      <span class="cv-countdown-label">Days</span>
    </div>
    <div class="cv-countdown-item cv-center-content">
      <span class="cv-countdown-value">{{ hoursCount }}</span>
      <span class="cv-countdown-label">Hours</span>
    </div>
    <div class="cv-countdown-item cv-center-content">
      <span class="cv-countdown-value">{{ minsCount }}</span>
      <span class="cv-countdown-label">Mins</span>
    </div>
  </div>
</div>

<div
  *ngIf="!!targetDate && compact && !isPastDate && !isLastDay"
  class="cv-countdown-pane-compact dx-theme-accent-as-text-color"
  [ngClass]="{
    'cv-round-border dx-theme-accent-as-border-color': showBorder
  }"
>
  <span class="cv-1rx">{{ labelText }}</span>
  <strong class="cv-1-1rx">
    <span *ngIf="daysCount">{{ daysCount }} Days,&nbsp;</span>
    <span *ngIf="hoursCount">{{ hoursCount }} Hours &&nbsp;</span>
    <span>{{ minsCount }} Mins.&nbsp;</span>
    <span>({{ targetDate | date: 'h:mm a, EEE, MMM d, y' }})</span>
  </strong>
</div>

<div
  *ngIf="!!targetDate && compact && !isPastDate && isLastDay"
  class="cv-countdown-pane-compact-single dx-theme-accent-as-text-color"
  [ngClass]="{
    'cv-round-border dx-theme-accent-as-border-color': showBorder
  }"
>
  <span class="cv-1rx">{{ labelText }}</span>
  <strong class="cv-1-1rx">
    <span *ngIf="hoursCount">{{ hoursCount }} Hours &&nbsp;</span>
    <span>{{ minsCount }} Mins.&nbsp;</span>
    <span>({{ targetDate | date: 'h:mm a' }})</span>
  </strong>
</div>

<div
  *ngIf="!!targetDate && compact && isPastDate"
  class="cv-countdown-pane-compact-past dx-theme-accent-as-text-color"
  [ngClass]="{
    'cv-round-border dx-theme-accent-as-border-color': showBorder
  }"
>
  <span class="cv-1rx">Closed On:</span>
  <strong class="cv-1-1rx">
    <span>{{ targetDate | date: 'h:mm a, EEE, MMM d, y' }}</span>
  </strong>
</div>
