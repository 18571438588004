// Angular imports
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

// Dx imports
import { DxButtonModule } from 'devextreme-angular/ui/button';
import { DxDataGridModule } from 'devextreme-angular/ui/data-grid';
import { DxDrawerModule } from 'devextreme-angular/ui/drawer';
import { DxDropDownBoxModule } from 'devextreme-angular/ui/drop-down-box';
import { DxDropDownButtonModule } from 'devextreme-angular/ui/drop-down-button';
import { DxFileUploaderModule } from 'devextreme-angular/ui/file-uploader';
import { DxMenuModule } from 'devextreme-angular/ui/menu';
import { DxPieChartModule } from 'devextreme-angular/ui/pie-chart';
import { DxPopupModule } from 'devextreme-angular/ui/popup';
import { DxScrollViewModule } from 'devextreme-angular/ui/scroll-view';
import { DxSelectBoxModule } from 'devextreme-angular/ui/select-box';
import { DxSwitchModule } from 'devextreme-angular/ui/switch';
import { DxTextBoxModule } from 'devextreme-angular/ui/text-box';
import { DxToolbarModule } from 'devextreme-angular/ui/toolbar';
import { DxTreeViewModule } from 'devextreme-angular/ui/tree-view';
import { DxValidatorModule } from 'devextreme-angular/ui/validator';

// 3rd party imports
import { EditorModule, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';

// Pipe imports
import { FileIconPipe } from '../_pipes/file-icon.pipe';
import { FilterDeletedPipe } from '../_pipes/filter-deleted.pipe';
import { IsSelectedPipe } from '../_pipes/is-selected.pipe';
import { SafeHtmlPipe } from '../_pipes/safe-html.pipe';
import { SafeResourcePipe } from '../_pipes/safe-resource.pipe';
import { SortByPipe } from '../_pipes/sort-by.pipe';

// Directive imports
import { ButtonAbstainDirective } from './directives/button-abstain.directive';
import { ButtonCancelDirective } from './directives/button-cancel.directive';
import { ButtonCloseDirective } from './directives/button-close.directive';
import { ButtonGenerateDirective } from './directives/button-generate.directive';
import { ButtonOkDirective } from './directives/button-ok.directive';
import { ButtonPublishDirective } from './directives/button-publish.directive';
import { ButtonRefreshDirective } from './directives/button-refresh.directive';
import { ButtonSaveDirective } from './directives/button-save.directive';
import { ButtonSelectDirective } from './directives/button-select.directive';
import { ButtonSendDirective } from './directives/button-send.directive';
import { ButtonSubmitDirective } from './directives/button-submit.directive';
import { DataGridExportDirective } from './directives/data-grid-export.directive';
import { DataGridFeaturesDirective } from './directives/data-grid-features.directive';
import { DateBoxDateOnlyDirective } from './directives/datebox-dateonly.directive';
import { DateBoxDateTimeDirective } from './directives/datebox-datetime.directive';
import { DropDownButtonTypeDirective } from './directives/drop-down-button-type.directive';
import { NumberPercentageDirective } from './directives/number-percentage.directive';
import { SwitchYesNoDirective } from './directives/switch-yesno.directive';
import { TextBoxFileInputDirective } from './directives/text-box-file-input.directive';
import { TreeListFeaturesDirective } from './directives/tree-list-features.directive';

// Component imports
import { ActionsCellTemplateComponent } from './components/actions-cell-template/actions-cell-template.component';
import { AnchorCellTemplateComponent } from './components/anchor-cell-template/anchor-cell-template.component';
import { ButtonCellTemplateComponent } from './components/button-cell-template/button-cell-template.component';
import { CheckCellTemplateComponent } from './components/check-cell-template/check-cell-template.component';
import { ColoredLabelComponent } from './components/colored-label/colored-label.component';
import { CountdownPaneComponent } from './components/countdown-pane/countdown-pane.component';
import { ExportGridButtonComponent } from './components/export-grid-button/export-grid-button.component';
import { FileInputComponent } from './components/file-input/file-input.component';
import { FilenamePromptComponent } from './components/filename-prompt/filename-prompt.component';
import { HistoryCellTemplateComponent } from './components/history-cell-template/history-cell-template.component';
import { HistoryDetailsViewerComponent } from './components/history-details-viewer/history-details-viewer.component';
import { HistorySummaryViewerComponent } from './components/history-summary-viewer/history-summary-viewer.component';
import { HtmlEditorComponent } from './components/html-editor/html-editor.component';
import { HtmlViewerComponent } from './components/html-viewer/html-viewer.component';
import { IconCellTemplateComponent } from './components/icon-cell-template/icon-cell-template.component';
import { ImageViewerComponent } from './components/image-viewer/image-viewer.component';
import { LabelValueTemplateComponent } from './components/label-value-template/label-value-template.component';
import { LinkCellTemplateComponent } from './components/link-cell-template/link-cell-template.component';
import { MessagePanelComponent } from './components/message-panel/message-panel.component';
import { NavigationPaneComponent } from './components/navigation-pane/navigation-pane.component';
import { NavigationTreeComponent } from './components/navigation-tree/navigation-tree.component';
import { NotesCellTemplateComponent } from './components/notes-cell-template/notes-cell-template.component';
import { PagePopupComponent } from './components/page-popup/page-popup.component';
import { PageTitleComponent } from './components/page-title/page-title.component';
import { PercentagePieComponent } from './components/percentage-pie/percentage-pie.component';
import { PoweredLogoPaneComponent } from './components/powered-logo-pane/powered-logo-pane.component';
import { SelectCellTemplateComponent } from './components/select-cell-template/select-cell-template.component';
import { SelectionCardComponent } from './components/selection-card/selection-card.component';
import { SelectionGridComponent } from './components/selection-grid/selection-grid.component';
import { SequenceCellTemplateComponent } from './components/sequence-cell-template/sequence-cell-template.component';
import { SequenceEditorGridComponent } from './components/sequence-editor-grid/sequence-editor-grid.component';
import { StarRatingInputComponent } from './components/star-rating-input/star-rating-input.component';
import { StatusCellTemplateComponent } from './components/status-cell-template/status-cell-template.component';
import { SummaryPaneComponent } from './components/summary-pane/summary-pane.component';
import { SwitchCellTemplateComponent } from './components/switch-cell-template/switch-cell-template.component';
import { VideoViewerComponent } from './components/video-viewer/video-viewer.component';
import { WraptextCellTemplateComponent } from './components/wraptext-cell-template/wraptext-cell-template.component';

const pipes = [
  FileIconPipe,
  FilterDeletedPipe,
  IsSelectedPipe,
  SafeHtmlPipe,
  SafeResourcePipe,
  SortByPipe
];

const directives = [
  ButtonAbstainDirective,
  ButtonCancelDirective,
  ButtonCloseDirective,
  ButtonGenerateDirective,
  ButtonOkDirective,
  ButtonPublishDirective,
  ButtonSaveDirective,
  ButtonSelectDirective,
  ButtonSendDirective,
  ButtonSubmitDirective,
  ButtonRefreshDirective,
  DataGridExportDirective,
  DataGridFeaturesDirective,
  DateBoxDateOnlyDirective,
  DateBoxDateTimeDirective,
  DropDownButtonTypeDirective,
  SwitchYesNoDirective,
  NumberPercentageDirective,
  TextBoxFileInputDirective,
  TreeListFeaturesDirective
];

const components = [
  ActionsCellTemplateComponent,
  AnchorCellTemplateComponent,
  ButtonCellTemplateComponent,
  CheckCellTemplateComponent,
  ColoredLabelComponent,
  CountdownPaneComponent,
  ExportGridButtonComponent,
  FileInputComponent,
  FilenamePromptComponent,
  HistoryCellTemplateComponent,
  HistoryDetailsViewerComponent,
  HistorySummaryViewerComponent,
  HtmlEditorComponent,
  HtmlViewerComponent,
  IconCellTemplateComponent,
  ImageViewerComponent,
  LabelValueTemplateComponent,
  LinkCellTemplateComponent,
  MessagePanelComponent,
  NavigationPaneComponent,
  NavigationTreeComponent,
  NotesCellTemplateComponent,
  PagePopupComponent,
  PageTitleComponent,
  PercentagePieComponent,
  PoweredLogoPaneComponent,
  SelectCellTemplateComponent,
  SelectionCardComponent,
  SelectionGridComponent,
  SequenceCellTemplateComponent,
  SequenceEditorGridComponent,
  StarRatingInputComponent,
  StatusCellTemplateComponent,
  SummaryPaneComponent,
  SwitchCellTemplateComponent,
  VideoViewerComponent,
  WraptextCellTemplateComponent
];

const imports = [
  CommonModule,
  FormsModule,
  DxButtonModule,
  DxDataGridModule,
  DxDrawerModule,
  DxDropDownBoxModule,
  DxDropDownButtonModule,
  DxFileUploaderModule,
  DxMenuModule,
  DxPieChartModule,
  DxPopupModule,
  DxScrollViewModule,
  DxSelectBoxModule,
  DxSwitchModule,
  DxTextBoxModule,
  DxToolbarModule,
  DxTreeViewModule,
  DxValidatorModule,
  EditorModule
];

const declarations = [...pipes, ...directives, ...components];

@NgModule({
  declarations: declarations,
  imports: imports,
  exports: declarations,
  providers: [
    {
      provide: TINYMCE_SCRIPT_SRC,
      useValue: 'assets/tinymce/tinymce.min.js'
    }
  ]
})
export class WidgetsModule {}
