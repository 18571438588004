// Environment imports
import { environment } from '../../environments/environment';

export class UrlConstants {
  static readonly HELP_BASE_URL = `assets/help`;
  static readonly AUTH_BASE_URL = `${environment.ccVoteApiBaseUrl}/auth`;
  static readonly LOGS_BASE_URL = `${environment.ccVoteApiBaseUrl}/log`;
  static readonly GEO_LOCATION_BASE_URL = `${environment.ccVoteApiBaseUrl}/geolocation`;
  static readonly ORGANIZATION_BASE_URL = `${environment.ccVoteApiBaseUrl}/organization`;
  static readonly COMMUNITY_BASE_URL = `${environment.ccVoteApiBaseUrl}/community`;
  static readonly DATASYNC_BASE_URL = `${environment.ccVoteApiBaseUrl}/data-sync`;
  static readonly DOCUMENT_BASE_URL = `${environment.ccVoteApiBaseUrl}/community/document`;
  static readonly AUTH_USER_BASE_URL = `${environment.ccVoteApiBaseUrl}/manage/user`;
  static readonly ENTITY_USER_BASE_URL = `${environment.ccVoteApiBaseUrl}/entity/user`;
  static readonly USER_BASE_URL = `${environment.ccVoteApiBaseUrl}/user`;
  static readonly ROLE_BASE_URL = `${environment.ccVoteApiBaseUrl}/user/role`;
  static readonly EVENTS_BASE_URL = `${environment.ccVoteApiBaseUrl}/user/events`;
  static readonly PARTICIPANT_BASE_URL = `${environment.ccVoteApiBaseUrl}/event/survey/participant`;
  static readonly VOTER_BASE_URL = `${environment.ccVoteApiBaseUrl}/event/election/voter`;
  static readonly AMENDMENT_VOTER_BASE_URL = `${environment.ccVoteApiBaseUrl}/event/document-amendment/voter`;
  static readonly ELECTION_BASE_URL = `${environment.ccVoteApiBaseUrl}/event/election`;
  static readonly ELECTION_PAPER_BALLOT_BASE_URL = `${environment.ccVoteApiBaseUrl}/event/election/paper-ballot`;
  static readonly ELECTION_DASHBOARD_BASE_URL = `${environment.ccVoteApiBaseUrl}/event/election/voting/result`;
  static readonly ELECTION_READINESS_BASE_URL = `${environment.ccVoteApiBaseUrl}/event/election/readiness`;
  static readonly ELECTION_NOTICES_BASE_URL = `${environment.ccVoteApiBaseUrl}/event/election/notices`;
  static readonly ELECTION_NOTICE_MEETING_BASE_URL = `${environment.ccVoteApiBaseUrl}/event/election/notices/meeting`;
  static readonly ELECTION_NOTICE_COMMUNICATION_BASE_URL = `${environment.ccVoteApiBaseUrl}/event/election/notices/communication`;
  static readonly ELECTION_PAPER_COMMUNICATION_BASE_URL = `${environment.ccVoteApiBaseUrl}/event/election/notices/paper-communication`;
  static readonly ELECTION_SCHEDULED_COMMUNICATION_BASE_URL = `${environment.ccVoteApiBaseUrl}/event/election/notices/scheduled-communication`;
  static readonly VOTES_MANAGEMENT_BASE_URL = `${environment.ccVoteApiBaseUrl}/event/election/votes-management`;
  static readonly ELECTION_REPORTS_BASE_URL = `${environment.ccVoteApiBaseUrl}/event/election/reports`;
  static readonly SURVEY_BASE_URL = `${environment.ccVoteApiBaseUrl}/event/survey`;
  static readonly SURVEY_QUESTION_BASE_URL = `${environment.ccVoteApiBaseUrl}/event/survey/question`;
  static readonly SURVEY_NOTICE_COMMUNICATION_BASE_URL = `${environment.ccVoteApiBaseUrl}/event/survey/notices/communication`;
  static readonly SURVEY_PAPER_COMMUNICATION_BASE_URL = `${environment.ccVoteApiBaseUrl}/event/survey/notices/paper-communication`;
  static readonly SURVEY_SCHEDULED_COMMUNICATION_BASE_URL = `${environment.ccVoteApiBaseUrl}/event/survey/notices/scheduled-communication`;
  static readonly SURVEY_DASHBOARD_BASE_URL = `${environment.ccVoteApiBaseUrl}/event/survey/result`;
  static readonly SURVEY_REPORTS_BASE_URL = `${environment.ccVoteApiBaseUrl}/event/survey/reports`;
  static readonly AMENDMENT_BASE_URL = `${environment.ccVoteApiBaseUrl}/event/document-amendment`;
  static readonly AMENDMENT_NOTICE_COMMUNICATION_BASE_URL = `${environment.ccVoteApiBaseUrl}/event/document-amendment/notices/communication`;
  static readonly AMENDMENT_PAPER_COMMUNICATION_BASE_URL = `${environment.ccVoteApiBaseUrl}/event/document-amendment/notices/paper-communication`;
  static readonly AMENDMENT_SCHEDULED_COMMUNICATION_BASE_URL = `${environment.ccVoteApiBaseUrl}/event/document-amendment/notices/scheduled-communication`;
  static readonly AMENDMENT_REPORTS_BASE_URL = `${environment.ccVoteApiBaseUrl}/event/document-amendment/reports`;
  static readonly AMENDMENT_PAPER_BALLOT_BASE_URL = `${environment.ccVoteApiBaseUrl}/event/document-amendment/paper-ballot`;
  static readonly AMENDMENT_DASHBOARD_BASE_URL = `${environment.ccVoteApiBaseUrl}/event/document-amendment/voting/result`;
  static readonly AMENDMENT_VOTES_MANAGEMENT_BASE_URL = `${environment.ccVoteApiBaseUrl}/event/document-amendment/votes-management`;
  static readonly PARTICIPANT_FEEDBACK_BASE_URL = `${environment.ccVoteApiBaseUrl}/participant/feedback`;
}
