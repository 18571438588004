<dx-button
  stylingMode="text"
  template="button-template"
  [type]="type"
  [icon]="icon"
  [activeStateEnabled]="false"
  [hoverStateEnabled]="false"
  [focusStateEnabled]="false"
>
  <div *dxTemplate="let t of 'button-template'" class="cv-zerobox-content">
    <i
      *ngIf="icon && iconPlacement === 'left'"
      aria-hidden="true"
      class="cv-hyperlink"
      [ngStyle]="iconStyle"
      [ngClass]="icon"
      (click)="onIconClick.emit($event)"
    ></i>
    <span class="cv-user-select dx-user-select" [ngStyle]="labelStyle">
      {{ text }}
    </span>
    <i
      *ngIf="icon && iconPlacement === 'right'"
      aria-hidden="true"
      class="cv-hyperlink"
      [ngStyle]="iconStyle"
      [ngClass]="icon"
      (click)="onIconClick.emit($event)"
    ></i>
  </div>
</dx-button>
