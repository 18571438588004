// Model imports
import { ThemeColor } from '../_types/theme-color';

export class ThemeConstants {
  static readonly ThemeColors: Array<ThemeColor> = [
    {
      name: 'light-blue',
      displayText: 'Blue Light Background',
      themeName: 'dx.light',
      customName: 'cc.light'
    },
    {
      name: 'dark-blue',
      displayText: 'Blue Dark Background',
      themeName: 'dx.dark',
      customName: 'cc.dark'
    },
    {
      name: 'light-orange',
      displayText: 'Orange Light Background',
      themeName: 'dx.orange',
      customName: 'cc.orange'
    },
    {
      name: 'dark-orange',
      displayText: 'Orange Dark Background',
      themeName: 'dx.darkorange',
      customName: 'cc.darkorange'
    },
    {
      name: 'light-teal',
      displayText: 'Teal Light Background',
      themeName: 'dx.teal',
      customName: 'cc.teal'
    },
    {
      name: 'dark-teal',
      displayText: 'Teal Dark Background',
      themeName: 'dx.darkteal',
      customName: 'cc.darkteal'
    }
  ];
  static readonly DefaultPaletteName = 'Material';
  static readonly DefaultPaletteMode = 'Blend';
  static readonly ChartPaletteNames = [
    'Material',
    'Soft Pastel',
    'Harmony Light',
    'Pastel',
    'Bright',
    'Soft',
    'Ocean',
    'Office',
    'Vintage',
    'Violet',
    'Carmine',
    'Dark Moon',
    'Soft Blue',
    'Dark Violet',
    'Green Mist'
  ];
  static readonly ChartPaletteExtensionModes = [
    'Alternate',
    'Blend',
    'Extrapolate'
  ];
  static readonly ColorCodes = [
    '#73B2FF',
    '#8C97FF',
    '#AE80FF',
    '#61FF89',
    '#ABEFFF',
    '#87FEB7',
    '#BFFF6D',
    '#FEC2BE',
    '#FFCBA4',
    '#FFABE6',
    '#FFBAD4',
    '#DCCDFD',
    '#C4D2FD'
  ];
}
