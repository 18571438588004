// Angular imports
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable } from '@angular/core';

// 3rd party imports
import { captureException as captureSentryException } from '@sentry/angular-ivy';
import { setUser as setSentryUser } from '@sentry/angular-ivy';

// Util imports
import { Utils } from '../_utils/utils';

// Service imports
import { ErrorsService } from '../_services/errors.service';
import { UserService } from '../_services/user.service';

// Model imports
import { BaseUserInfoResultDto } from '../_models/user/base-user-info-result.dto';

@Injectable()
export class ErrorsHandler implements ErrorHandler {
  constructor(
    private readonly errorService: ErrorsService,
    private readonly userService: UserService
  ) {}

  handleError(error: any): void {
    // Set user context in Sentry or use it for additional logging
    const userInfo: BaseUserInfoResultDto =
      this.userService.getLoggedInUser() ||
      this.userService.getLoggedInParticipant();
    if (
      Utils.notNullAndDefined(userInfo) &&
      Utils.notNullOrEmpty(userInfo.email)
    ) {
      setSentryUser({
        username: userInfo.email,
        email: userInfo.email
      });
    } else {
      setSentryUser(null);
    }

    if (error instanceof HttpErrorResponse) {
      // Handle HTTP errors
      if (error.status >= 500) {
        // Log only 5xx server errors to Sentry
        captureSentryException(error.message);
      }
      this.errorService.showApiError(error);
    } else {
      // Handle non-HTTP errors
      captureSentryException(error);
      this.errorService.showUiError(error);
    }
  }
}
