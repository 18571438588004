<app-page-popup
  *ngIf="!!htmlText"
  [title]="title"
  [visible]="true"
  [buttons]="['Close']"
  (onHidden)="onClose.emit()"
>
  <div
    class="cv-html-viewer cv-fullsize-content cv-border-content dx-theme-border-color"
  >
    <div [innerHTML]="htmlText | safeHtml"></div>
  </div>
</app-page-popup>
